import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { BrowserBarcodeReader } from "@zxing/library";
import {
  HiOutlineChevronLeft,
  HiOutlineDotsVertical,
  HiOutlinePhotograph,
  HiOutlineRefresh,
  HiOutlinePlus,
  HiOutlineShare,
  HiOutlineCalendar,
  HiOutlineBookmark,
} from "react-icons/hi";
import { PiLightningSlashBold, PiLightningBold } from "react-icons/pi";
import { ButtonPrimary } from "./Buttons";
import { Modal, Modal2, ModalBottom } from "../genericComponents/Modals";
import { useTranslation } from "react-i18next";
import { Accordion } from "../genericComponents/Accordion";
import { RiFileList3Line } from "react-icons/ri";
import "./BarcodeScanner.scss";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const BarcodeScanner = ({
  onProductDataChange,
  onScannedProductsHistoryChange,
  onErrorChange,
}) => {
  const webcamRef = useRef(null);
  const codeReaderRef = useRef(null);
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState(null);
  const [scannedCode, setScannedCode] = useState("");
  const [scannedProductsHistory, setScannedProductsHistory] = useState([]);

  const [torchOn, setTorchOn] = useState(false);

  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [videoStream, setVideoStream] = useState(null);

  const videoConstraints = {
    facingMode: facingMode,
  };

  useEffect(() => {
    startBarcodeScanning();
  }, []);

  const getProductData = async (barcode) => {
    setError(null);
    try {
      const response = await axios.get(
        `https://world.openfoodfacts.org/api/v0/product/${barcode}.json`
      );
      setScannedCode(barcode);

      const date = new Date();

      const {
        product_name: productName,
        image_url: imageUrl,
        nutriments: {
          energy,
          fat,
          calories,
          saturatedFat,
          fiber,
          proteins,
          carbohydrates,
          sugars,
          salt,
          cholesterol,
          calcium,
          alcohol,
          magnesium,
        },
        ingredients_text: ingredients,
        additives_tags,
        nutriscore_grade,
        nutriscore_score,
        product_data_categories,
        brands,
      } = response.data.product;

      const productData = {
        scannedCode: barcode,
        scannedDate: date,
        productName,
        imageUrl,
        energy,
        calories,
        fat,
        saturatedFat,
        fiber,
        proteins,
        carbohydrates,
        sugars,
        salt,
        cholesterol,
        calcium,
        alcohol,
        magnesium,
        ingredients,
        additives: additives_tags,
        nutriscoreGrade: nutriscore_grade,
        nutriscoreScore: nutriscore_score,
        productDataCategories: product_data_categories,
        brandName: brands,
      };
      setProductData(productData);
      setScannedProductsHistory((prevHistory) => [productData, ...prevHistory]);
      onProductDataChange(productData);
      onScannedProductsHistoryChange((prevHistory) => [
        productData,
        ...prevHistory,
      ]);
    } catch (error) {
      onErrorChange("Product not found");
    }
  };

  const startBarcodeScanning = async () => {
    if (webcamRef.current && !codeReaderRef.current) {
      codeReaderRef.current = new BrowserBarcodeReader();

      try {
        const videoElement = webcamRef.current.video;

        if (!videoElement.playing) {
          videoElement.playing = true;

          await new Promise((resolve) => {
            const handlePlay = () => {
              resolve();
              videoElement.removeEventListener("play", handlePlay);
            };

            videoElement.addEventListener("play", handlePlay);
            videoElement.play().catch((error) => {
              console.error("Error starting barcode scanning:", error);
              resolve();
            });
          });
          setVideoStream(webcamRef.current.stream);
          scanBarcode();
        }
      } catch (error) {
        console.error("Error starting barcode scanning:", error);
      }
    }
  };

  const scanBarcode = async () => {
    if (codeReaderRef.current && webcamRef.current && webcamRef.current.video) {
      try {
        const result = await codeReaderRef.current.decodeFromVideoElement(
          webcamRef.current.video
        );

        if (result) {
          setScannedCode(result.getText());
          getProductData(result.getText());
        }
        scanBarcode();
      } catch (error) {
        console.error("Barcode scanning error:", error);
      }
    }
  };

  //TOGGLE TORCH
  const toggleTorch = async () => {
    if (webcamRef.current) {
      const videoTrack = webcamRef.current.stream.getVideoTracks()[0];

      if (videoTrack && "applyConstraints" in videoTrack) {
        try {
          await videoTrack.applyConstraints({
            advanced: [{ torch: !torchOn }],
          });
          setTorchOn(!torchOn);
        } catch (error) {
          console.error("Error toggling torch:", error);
        }
      } else {
        console.warn("Torch feature not supported on this device.");
      }
    }
  };

  //TOGGLE VIEW
  const toggleFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  };

  //FOCUS ON CENTER
  const focusCenterRectangle = async () => {
    if (webcamRef.current && webcamRef.current.stream) {
      const videoTrack = webcamRef.current.stream.getVideoTracks()[0];

      if (videoTrack && "applyConstraints" in videoTrack) {
        const videoElement = webcamRef.current.video;
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;

        const focusArea = {
          x: (videoWidth - videoWidth * 0.5) / 2 / videoWidth,
          y: (videoHeight - videoHeight * 0.5) / 2 / videoHeight,
          width: 0.5,
          height: 0.5,
        };

        try {
          await videoTrack.applyConstraints({
            advanced: [
              {
                focusMode: "manual",
                pointsOfInterest: [focusArea],
                zoom: 2,
              },
            ],
          });
          console.log(
            "Focused on the center rectangle with zoom 1:",
            focusArea
          );
        } catch (error) {
          console.error("Error applying focus and zoom:", error);
        }
      }
    }
  };

  useEffect(() => {
    focusCenterRectangle();
  }, [facingMode]);

  return (
    <div>
      <div className="videoContainer" onClick={focusCenterRectangle}>
        <span className="scannerLine" />
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          width={200}
          height={200}
        />
        <button
          aria-label="scannerFlashBtn"
          className="buttonScanner torch"
          onClick={toggleTorch}
        >
          {torchOn ? <PiLightningSlashBold /> : <PiLightningBold />}
        </button>
        <button
          aria-label="scannerToggleBtn"
          className="buttonScanner"
          onClick={toggleFacingMode}
        >
          <HiOutlineRefresh />
        </button>
      </div>
    </div>
  );
};

const ProductCard = ({ product }) => {
  const { t } = useTranslation();
  //MODAL
  const [hideModal, setHideModal] = useState(true);
  const toggleModal = () => setHideModal(!hideModal);
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const configModal = {
    hideModal,
    toggleModal,
    hideModalBottom,
    toggleModalBottom,
  };

  return (
    <div key={product.scannedCode}>
      <div className="flexInput">
        {product.imageUrl ? (
          <img
            src={product.imageUrl}
            alt="List"
            onClick={() => toggleModal(product.scannedCode)}
          />
        ) : (
          <img
            src="/assets/placeholders/ingredientPlaceholder.svg"
            alt="List"
            onClick={() => toggleModal(product.scannedCode)}
          />
        )}
        <div
          className="ingredientNameBrand"
          onClick={() => toggleModal(product.scannedCode)}
        >
          <p className="ingredientAdded">
            {product.productName ? (
              <>{product.productName}</>
            ) : (
              <>{t("Unknown product name")}</>
            )}
          </p>
          <span className="ingredientBrand">
            {product.brandName ? (
              <>{product.brandName}</>
            ) : (
              <>{"Unknown product brand"}</>
            )}
            <br />
            {product.scannedCode ? <>{product.scannedCode}</> : null}
            <br />
            {product.scannedDate ? (
              <>{product.scannedDate.toLocaleString()}</>
            ) : (
              <>Unknown date</>
            )}
          </span>
        </div>

        <HiOutlineDotsVertical />
      </div>
      <Modal extenderClass="noPadding" {...configModal}>
        <div className="recipeImageFixedSpacer" />
        <div className="recipeImageFixed">
          {product.imageUrl ? (
            <img
              src={product.imageUrl}
              alt="Listeat"
              className="pageRecipeImage"
            />
          ) : (
            <span className="pageRecipeImage">
              <HiOutlinePhotograph />
              <br />
              <p>{t("No available image")}</p>
            </span>
          )}
        </div>
        <button
          aria-label="closeModalBtn"
          className="closeModalBtn"
          onClick={() => toggleModal()}
        >
          <HiOutlineChevronLeft />
        </button>
        <div className="ctaButtons">
          <button aria-label="scannerShareBtn" className="buttonShare">
            <HiOutlineShare />
          </button>
          <button
            aria-label="scannerFavoriteBtn"
            className="buttonFav"
            onClick={() => toggleModalBottom()}
          >
            <HiOutlinePlus />
          </button>
        </div>
        <div className="pageRecipeText">
          {product.brandName ? (
            <span className="category">{product.brandName}</span>
          ) : (
            <span className="category">{t("Missing brand name")}</span>
          )}
          <h2>
            {product.productName ? (
              <>{product.productName}</>
            ) : (
              <>{t("Unknown product name")}</>
            )}
            {product.nutriscoreGrade ? (
              <>
                {product.nutriscoreGrade === "a" && (
                  <img
                    src="./assets/nutrition/nutriScore/A.svg"
                    alt="Listeat"
                  />
                )}
                {product.nutriscoreGrade === "b" && (
                  <img
                    src="./assets/nutrition/nutriScore/B.svg"
                    alt="Listeat"
                  />
                )}
                {product.nutriscoreGrade === "c" && (
                  <img
                    src="./assets/nutrition/nutriScore/C.svg"
                    alt="Listeat"
                  />
                )}
                {product.nutriscoreGrade === "d" && (
                  <img
                    src="./assets/nutrition/nutriScore/D.svg"
                    alt="Listeat"
                  />
                )}
                {product.nutriscoreGrade === "e" && (
                  <img
                    src="./assets/nutrition/nutriScore/E.svg"
                    alt="Listeat"
                  />
                )}
                {product.nutriscoreGrade === "" && (
                  <img
                    src="./assets/nutrition/nutriScore/None.svg"
                    alt="Listeat"
                  />
                )}
              </>
            ) : (
              <img src="./assets/nutrition/nutriScore/None.svg" alt="Listeat" />
            )}
          </h2>
          <small className="scannerIngredients">
            {product.ingredients ? (
              <p>{product.ingredients}</p>
            ) : (
              <p>{t("Not found")}</p>
            )}
          </small>
          <h3 className="flexHeader">{t("Nutrition")}</h3>
          <div className="flexNutrients">
            <div className="flexRecipeSecondary">
              <span>
                <label>{t("Energy")}</label>
                <br />
                {product.energy ? <>{product.energy}kj</> : <>-</>}
              </span>
              {product.proteins ? (
                <span>
                  <label>{t("Proteins")}</label>
                  <br />
                  <small>{product.proteins}g</small>
                </span>
              ) : null}
              <span>
                <label>{t("Fiber")}</label>
                <br />
                {product.fiber ? <>{product.fiber}g</> : null}
              </span>
              {product.saturated_fat ? (
                <span>
                  <label>{t("Saturated fat")}</label>
                  <br />
                  <>{product.saturated_fat}g</>
                </span>
              ) : null}
              {product.fat ? (
                <span>
                  <label>{t("Fat")}</label>
                  <br />
                  <>{product.fat}g</>
                </span>
              ) : null}
              {product.carbohydrates ? (
                <span>
                  <label>{t("Carbohydrates")}</label>
                  <br />
                  <>{product.carbohydrates}g</>
                </span>
              ) : null}
              {product.sugars ? (
                <span>
                  <label>{t("Sugars")}</label>
                  <br />
                  <>{product.sugars}g</>
                </span>
              ) : null}
              {product.salt ? (
                <span>
                  <label>{t("Salt")}</label>
                  <br />
                  <>{product.salt}g</>
                </span>
              ) : null}
              {product.cholesterol ? (
                <span>
                  <label>{t("Cholesterol")}</label>
                  <br />
                  <>{product.cholesterol}g</>
                </span>
              ) : null}
              {product.calcium ? (
                <span>
                  <label>{t("Calcium")}</label>
                  <br />
                  <>{product.calcium}g</>
                </span>
              ) : null}
              {product.magnesium ? (
                <span>
                  <label>{t("Magnesium")}</label>
                  <br />
                  <>{product.magnesium}g</>
                </span>
              ) : null}
              {product.alcohol ? (
                <span>
                  <label>{t("Alcohol")}</label>
                  <br />
                  {product.alcohol}%
                </span>
              ) : null}
            </div>
          </div>
          <h3 className="flexHeader">{t("Prices")}</h3>
          <div className="flexNutrients">
            <div className="flexRecipeSecondary">
              <span>
                <label>{t("Kaufland")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Lidl")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Auchan")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Carrefour")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Metro")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Penny")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Profi")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Selgros")}</label>
                <br />
                <small>0 LEI</small>
              </span>
              <span>
                <label>{t("Cora")}</label>
                <br />
                <small>0 LEI</small>
              </span>
            </div>
          </div>
          <Accordion
            title={t("Additives")}
            content={
              <div>
                {product.additives ? (
                  <p>{product.additives.join(", ")}</p>
                ) : (
                  <p>{t("Not found")}</p>
                )}
              </div>
            }
          />
          {product.nutriscoreScore ? (
            <p>
              <strong>{t("Nutrition score")}:</strong> {product.nutriscoreScore}
            </p>
          ) : (
            <p>
              <strong>{t("Nutrition score")}:</strong> {t("Not found")}
            </p>
          )}
          <ButtonPrimary
            title={t("Back")}
            onClick={() => {
              toggleModal();
            }}
          />
          <br />
          <br />
        </div>
      </Modal>
      {product ? (
        <ModalBottom {...configModal}>
          <div className="modalCenter">
            <div className="fixedHeader">
              <h3>
                {t("Add")} {product.productName}
              </h3>
            </div>
            <div className="flexShare">
              <button
                aria-label="Add scanned product to saved"
                name="Add scanned product to saved"
                className="flexShareBtn"
                disabled
              >
                <span>
                  <HiOutlineBookmark />
                </span>
                <small>{t("Saved")}</small>
              </button>
              <button
                aria-label="Add scanned product to calendar"
                name="Add scanned product to calendar"
                className="flexShareBtn"
                disabled
              >
                <span>
                  <HiOutlineCalendar />
                </span>
                <small>{t("Calendar")}</small>
              </button>
              <button
                aria-label="Add scanned product to list"
                name="Add scanned product to list"
                className="flexShareBtn"
                disabled
              >
                <span>
                  <RiFileList3Line />
                </span>
                <small>{t("List")}</small>
              </button>
              {/* <FavoriteButton recipe={product} /> */}
            </div>
          </div>
        </ModalBottom>
      ) : null}
    </div>
  );
};

export const BarcodeScannerContainer = () => {
  const [productData, setProductData] = useState(null);
  const [scannedProductsHistory, setScannedProductsHistory] = useState([]);
  const { t } = useTranslation();
  const [scanError, setScanError] = useState(null);

  //MODAL
  const [hideModal, setHideModal] = useState(true);
  const toggleModal = () => setHideModal(!hideModal);
  const [hideModal2, setHideModal2] = useState(true);
  const toggleModal2 = () => setHideModal2(!hideModal2);
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const configModal = {
    hideModal,
    toggleModal,
    hideModal2,
    toggleModal2,
    hideModalBottom,
    toggleModalBottom,
  };

  const handleProductDataChange = (data) => {
    setProductData(data);
    toggleModal2();
  };
  const handleScannedProductsHistoryChange = (history) => {
    localStorage.setItem("scannedProductsHistory", JSON.stringify(history));
    setScannedProductsHistory(history);
  };

  useEffect(() => {
    const savedHistory = localStorage.getItem("scannedProductsHistory");
    if (savedHistory) {
      setScannedProductsHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "scannedProductsHistory",
      JSON.stringify(scannedProductsHistory)
    );
  }, [scannedProductsHistory]);

  const clearScannedProductsHistory = () => {
    localStorage.removeItem("scannedProductsHistory");
    setScannedProductsHistory([]);
  };

  useEffect(() => {
    setScanError(scanError);
  }, [scanError]);

  const handleScanErrorChange = (error) => {
    setScanError(error);
    toggleModal2();
  };

  //SHARE
  const share = () => {
    if (navigator.share) {
      // fetch(imageUrl)
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const file = new File([blob], "image.jpg", { type: "image/jpeg" });
      //     navigator
      //       .share({
      //         files: [file],
      //         // title: `${recipeName} | Listeat`,
      //         // text: `${recipeName} ${"by"} ${
      //         //   user?.displayName
      //         // } ${"on"} Listeat`,
      //         // url: `https://listeat.ro/recipes/${documentID}`,
      //       })
      //       .then(() => console.log("Share successful"))
      //       .catch((error) => console.log("Share error", error));
      //   });
    }
  };

  return (
    <div className="page">
      <div className="productScanner">
        {!productData && !scanError ? (
          <>
            <BarcodeScanner
              onProductDataChange={handleProductDataChange}
              onScannedProductsHistoryChange={
                handleScannedProductsHistoryChange
              }
              scanError={scanError}
              onErrorChange={handleScanErrorChange}
            />

            <div>
              <h3 className="flexHeader">
                {t("Scanned products")}
                {scannedProductsHistory.length > 0 ? (
                  <button
                    aria-label="scannerClearBtn"
                    onClick={clearScannedProductsHistory}
                  >
                    {t("Clear")}
                  </button>
                ) : null}
              </h3>
              <div className="scannedProductsList">
                {scannedProductsHistory.length > 0 ? (
                  <>
                    {scannedProductsHistory.map((product) => (
                      <ProductCard
                        key={product.scannedCode}
                        product={product}
                      />
                    ))}
                  </>
                ) : (
                  <p>{t("No scanned products")}</p>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <Modal2 extenderClass="noPadding" {...configModal}>
              {scanError ? (
                <>
                  <button
                    aria-label="scannerCloseModalBtn"
                    className="closeModalBtn"
                    onClick={() => {
                      toggleModal2();
                      setScanError(null);
                    }}
                  >
                    <HiOutlineChevronLeft />
                  </button>
                  <div className="recipeImageFixedSpacer" />
                  <div className="recipeImageFixed">
                    <span className="pageRecipeImage" />
                  </div>
                  <div className="pageRecipeText">
                    <p>{scanError}</p>
                    <ButtonPrimary
                      title={t("Scan other product")}
                      onClick={() => {
                        toggleModal2();
                        setScanError(null);
                      }}
                    />
                    <br />
                    <br />
                  </div>
                </>
              ) : (
                <>
                  <div className="recipeImageFixedSpacer" />
                  <div className="recipeImageFixed">
                    {productData.imageUrl ? (
                      <img
                        src={productData.imageUrl}
                        alt="Listeat"
                        className="pageRecipeImage"
                      />
                    ) : (
                      <span className="pageRecipeImage">
                        <HiOutlinePhotograph />
                        <br />
                        <p>{t("No available image")}</p>
                      </span>
                    )}
                  </div>
                  <button
                    aria-label="scannerBackBtn"
                    className="closeModalBtn"
                    onClick={() => {
                      toggleModal2();
                      setProductData(null);
                    }}
                  >
                    <HiOutlineChevronLeft />
                  </button>
                  <div className="ctaButtons">
                    <button
                      aria-label="scannerShareBtn"
                      className="buttonShare"
                      onClick={share}
                    >
                      <HiOutlineShare />
                    </button>
                    <>
                      <button
                        aria-label="scannerAddToBtn"
                        className="buttonFav"
                        onClick={() => toggleModalBottom()}
                      >
                        <HiOutlinePlus />
                      </button>
                    </>
                  </div>
                  <div className="pageRecipeText">
                    {productData.brandName ? (
                      <span className="category">{productData.brandName}</span>
                    ) : (
                      <span className="category">
                        {t("Missing brand name")}
                      </span>
                    )}
                    <h2>
                      {productData.productName ? (
                        <>{productData.productName}</>
                      ) : (
                        <>{t("Unknown product name")}</>
                      )}
                      {productData.nutriscoreGrade ? (
                        <>
                          {productData.nutriscoreGrade === "a" && (
                            <img
                              src="./assets/nutrition/nutriScore/A.svg"
                              alt="Listeat"
                            />
                          )}
                          {productData.nutriscoreGrade === "b" && (
                            <img
                              src="./assets/nutrition/nutriScore/B.svg"
                              alt="Listeat"
                            />
                          )}
                          {productData.nutriscoreGrade === "c" && (
                            <img
                              src="./assets/nutrition/nutriScore/C.svg"
                              alt="Listeat"
                            />
                          )}
                          {productData.nutriscoreGrade === "d" && (
                            <img
                              src="./assets/nutrition/nutriScore/D.svg"
                              alt="Listeat"
                            />
                          )}
                          {productData.nutriscoreGrade === "e" && (
                            <img
                              src="./assets/nutrition/nutriScore/E.svg"
                              alt="Listeat"
                            />
                          )}
                          {productData.nutriscoreGrade === "" && (
                            <img
                              src="./assets/nutrition/nutriScore/None.svg"
                              alt="Listeat"
                            />
                          )}
                        </>
                      ) : (
                        <img
                          src="./assets/nutrition/nutriScore/None.svg"
                          alt="Listeat"
                        />
                      )}
                    </h2>
                    <small className="scannerIngredients">
                      {productData.ingredients ? (
                        <p>{productData.ingredients}</p>
                      ) : (
                        <p>{t("Not found")}</p>
                      )}
                    </small>
                    <h3 className="flexHeader">{t("Nutrition")}</h3>
                    <div className="flexNutrients">
                      <div className="flexRecipeSecondary">
                        {productData.energy ? (
                          <span>
                            <label>{t("Energy")}</label>
                            <br />
                            {productData.energy}kj
                          </span>
                        ) : null}
                        {productData.proteins ? (
                          <span>
                            <label>{t("Proteins")}</label>
                            <br />
                            <>{productData.proteins}g</>
                          </span>
                        ) : null}
                        {productData.calories ? (
                          <span>
                            <label>{t("Calories")}</label>
                            <br />

                            <>{productData.calories}g</>
                          </span>
                        ) : null}
                        {productData.fiber ? (
                          <span>
                            <label>{t("Fiber")}</label>
                            <br />
                            <>{productData.fiber}g</>
                          </span>
                        ) : null}
                        {productData.saturated_fat ? (
                          <span>
                            <label>{t("Saturated fat")}</label>
                            <br />

                            <>{productData.saturated_fat}g</>
                          </span>
                        ) : null}
                        {productData.fat ? (
                          <span>
                            <label>{t("Fat")}</label>
                            <br />
                            <>{productData.fat}g</>
                          </span>
                        ) : null}
                        {productData.carbohydrates ? (
                          <span>
                            <label>{t("Carbohydrates")}</label>
                            <br />
                            <>{productData.carbohydrates}g</>
                          </span>
                        ) : null}
                        {productData.sugars ? (
                          <span>
                            <label>{t("Sugars")}</label>
                            <br />
                            <>{productData.sugars}g</>
                          </span>
                        ) : null}
                        {productData.salt ? (
                          <span>
                            <label>{t("Salt")}</label>
                            <br />
                            <>{productData.salt}g</>
                          </span>
                        ) : null}
                        {productData.cholesterol ? (
                          <span>
                            <label>{t("Cholesterol")}</label>
                            <br />
                            <>{productData.cholesterol}g</>
                          </span>
                        ) : null}
                        {productData.calcium ? (
                          <span>
                            <label>{t("Calcium")}</label>
                            <br />
                            <>{productData.calcium}g</>
                          </span>
                        ) : null}
                        {productData.magnesium ? (
                          <span>
                            <label>{t("Magnesium")}</label>
                            <br />
                            <>{productData.magnesium}g</>
                          </span>
                        ) : null}
                        {productData.alcohol ? (
                          <span>
                            <label>{t("Alcohol")}</label>
                            <br />
                            {productData.alcohol}%
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <h3 className="flexHeader">{t("Prices")}</h3>
                    <div className="flexNutrients">
                      <div className="flexRecipeSecondary">
                        <span>
                          <label>{t("Kaufland")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Lidl")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Auchan")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Carrefour")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Metro")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Penny")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Profi")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Selgros")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                        <span>
                          <label>{t("Cora")}</label>
                          <br />
                          <small>0 LEI</small>
                        </span>
                      </div>
                    </div>
                    <Accordion
                      title={t("Additives")}
                      content={
                        <div>
                          {productData.additives ? (
                            <p>{productData.additives.join(", ")}</p>
                          ) : (
                            <p>{t("Not found")}</p>
                          )}
                        </div>
                      }
                    />
                    {productData.nutriscoreScore ? (
                      <p>
                        <strong>{t("Nutrition Score")}:</strong>{" "}
                        {productData.nutriscoreScore}
                      </p>
                    ) : (
                      <p>
                        <strong>{t("Nutrition score")}:</strong>{" "}
                        {t("Not available")}
                      </p>
                    )}
                    <ButtonPrimary
                      title={t("Scan other product")}
                      onClick={() => {
                        toggleModal2();
                        setProductData(null);
                      }}
                    />
                    <br />
                    <br />
                  </div>
                </>
              )}
            </Modal2>
            {productData ? (
              <ModalBottom {...configModal}>
                <div className="modalCenter">
                  <div className="fixedHeader">
                    <h3>
                      {t("Add")} {productData.productName}
                    </h3>
                  </div>
                  <div className="flexShare">
                    <button
                      aria-label="Add scanned product to saved"
                      name="Add scanned product to saved"
                      className="flexShareBtn"
                      disabled
                    >
                      <span>
                        <HiOutlineBookmark />
                      </span>
                      <small>{t("Saved")}</small>
                    </button>
                    <button
                      aria-label="Add scanned product to calendar"
                      name="Add scanned product to calendar"
                      className="flexShareBtn"
                      disabled
                    >
                      <span>
                        <HiOutlineCalendar />
                      </span>
                      <small>{t("Calendar")}</small>
                    </button>
                    <button
                      aria-label="Add scanned product to list"
                      name="Add scanned product to list"
                      className="flexShareBtn"
                      disabled
                    >
                      <span>
                        <RiFileList3Line />
                      </span>
                      <small>{t("List")}</small>
                    </button>
                  </div>
                </div>
              </ModalBottom>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
