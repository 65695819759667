const listsTypes = {
  ADD_NEW_LIST_START: "ADD_NEW_LIST_START",
  FETCH_LISTS_START: "FECTH_LISTS_START",
  SET_LISTS: "SET_LISTS",
  DELETE_LIST_START: "DELETE_LIST_START",
  FETCH_LIST_START: "FETCH_LIST_START",
  SET_LIST: "SET_LIST",
  UPDATE_LIST_START: "UPDATE_LIST_START",
  UPDATE_LIST_SUCCESS: "UPDATE_LIST_SUCCESS",
  UPDATE_LIST_ERROR: "UPDATE_LIST_ERROR",
};

export default listsTypes;
