import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dataCategories } from "../data/dataCategories";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavPage } from "../components/Navigation/Index";
import Footer from "../components/Footer";
import { InputSearch } from "../components/formElements/Inputs";

//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, FreeMode } from "swiper";
import "swiper/css/free-mode";
import "swiper/css";
import { PostsResults } from "../results/PostsResults";
import Skeleton from "../components/Skeleton";
import { useMetaTags } from "../hooks/useMetaTags";

function shuffleArray(array) {
  for (let i = array.length && array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function searchCategory() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}

export function RecipeCategory() {
  const { t } = useTranslation();
  const { link } = useParams();

  const category = dataCategories.find(
    (dataCategory) => dataCategory.link === link
  );

  useMetaTags({
    title: `${t(`${category.name}`)} | Listeat`,
    description: "Alege din miile de retete",
    image: "/assets/logo512.png",
    url: `${`https://listeat.ro/${link}`}`,
  });

  if (!category) {
    return <div>Category not found</div>;
  }

  return (
    <>
      <NavPage
        title={t(`${category.name}`)}
        image={category.image}
        withImage={true}
      />
      <div className="page">
        <PostsResults mainType="recipe" />
        <Footer />
      </div>
    </>
  );
}

export function RecipeCategoriesCarrousel() {
  const { t } = useTranslation();
  const [randomizedCategories, setRandomizedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    const singleWordCategories = dataCategories.filter(
      (dataCategory) => dataCategory.name.trim().split(" ").length === 1
    );
    setRandomizedCategories(shuffleArray(singleWordCategories));
    setLoading(false);
  }, []);

  if (loading)
    return (
      <div className="sliderBoxEmpty">
        <Swiper
          slidesPerView={3}
          spaceBetween={5}
          loop={true}
          freeMode={true}
          speed={5000}
          className="mySwiper"
          modules={[FreeMode]}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <button className="carrouselItem" />
            <span className="carrouselItemText" />
          </SwiperSlide>
          <SwiperSlide>
            <button className="carrouselItem" />
            <span className="carrouselItemText" />
          </SwiperSlide>
          <SwiperSlide>
            <button className="carrouselItem" />
            <span className="carrouselItemText" />
          </SwiperSlide>
          <SwiperSlide>
            <button className="carrouselItem" />
            <span className="carrouselItemText" />
          </SwiperSlide>
          <SwiperSlide>
            <button className="carrouselItem" />
            <span className="carrouselItemText" />
          </SwiperSlide>
        </Swiper>
      </div>
    );

  return (
    <div className="sliderBox" id="recipeCategories">
      <Swiper
        id="recipeCategories"
        slidesPerView={3}
        spaceBetween={5}
        loop={true}
        freeMode={true}
        speed={5000}
        className="mySwiper"
        modules={[FreeMode]}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
      >
        {randomizedCategories.map((dataCategory) => (
          <SwiperSlide key={dataCategory.name}>
            <Link to={`/recipe-categories/${dataCategory.link}`}>
              <button className="carrouselItem">
                <img src={dataCategory.image} alt="Listeat" />
                <span>{t(`${dataCategory.name}`)}</span>
              </button>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export function RecipeCategories() {
  const { t } = useTranslation();
  const [randomizedCategories, setRandomizedCategories] = useState([]);

  useMetaTags({
    title: `${t("Recipe categories")} | Listeat`,
    description: "Alege din miile de retete",
    image: "/assets/logo512.png",
    url: "https://listeat.ro/recipe-categories",
  });

  useEffect(() => {
    setRandomizedCategories(shuffleArray(dataCategories));
  }, []);

  if (!randomizedCategories && !dataCategories) {
    return <Skeleton categoriesPage={true} />;
  }

  return (
    <>
      <div className="page">
        <InputSearch
          placeholder={t("Search category")}
          id="myInput"
          onKeyUp={() => searchCategory()}
        />
        <br />
        <div className="flexCategories">
          <table id="myTable" cellSpacing="0" border="0">
            <tbody>
              {randomizedCategories.map((dataCategory, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/recipe-categories/${dataCategory.link}`}>
                      <button className="categoryBox">
                        <img src={dataCategory.image} alt="Listeat" />
                        <span>{t(`${dataCategory.name}`)}</span>
                        <span className="hiddenValue">
                          {t(`${dataCategory.value}`)}
                        </span>
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    </>
  );
}
