import React, { useState, useEffect } from "react";
import { firestore } from "../firebase/utils";
import { collection, addDoc, query, onSnapshot, orderBy } from "firebase/firestore";
import "./Chatbot.scss";

const Chatbot = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);

  const sendMessage = async () => {
    if (input.trim()) {
      await addDoc(collection(firestore, "botChats"), {
        prompt: input,
        sender: "user",
        timestamp: new Date(),
        isBot: false,
      });
      setInput("");
    }
  };

  useEffect(() => {
    const q = query(collection(firestore, "botChats"), orderBy("timestamp"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, []);

  const formatBotResponse = (response) => {
    if (!response) return null;
  
    const lines = response.split("\n").filter(line => line.trim() !== "");
    const formattedContent = [];
  
    lines.forEach((line, index) => {
      // Add headers for sections like recipes and tips
      if (line.startsWith("##")) {
        formattedContent.push(<h2 key={index}>{line.replace("##", "").trim()}</h2>);
      } else if (line.startsWith("1.")) {
        formattedContent.push(<h3 key={index}>{line.trim()}</h3>);
      } else if (line.startsWith("**Ingredients:**")) {
        formattedContent.push(<p key={index}><strong>Ingredients:</strong></p>);
      } else if (line.startsWith("**Instructions:**")) {
        formattedContent.push(<p key={index}><strong>Instructions:</strong></p>);
      } else if (line.startsWith("**Additional Tips:**")) {
        formattedContent.push(<h4 key={index}>Additional Tips:</h4>);
      } else if (line.startsWith("* ")) {
        // For ingredients and tips lists
        formattedContent.push(<ul key={index}><li>{line.replace("* ", "").trim()}</li></ul>);
      } else if (line.match(/^\d+\./)) {
        // For instructions list (using match to check for numbered steps)
        formattedContent.push(<ol key={index}><li>{line.trim()}</li></ol>);
      } else {
        // Plain text lines
        formattedContent.push(<p key={index}>{line.trim()}</p>);
      }
    });
  
    return <div>{formattedContent}</div>;
  };
  
  return (
    <div className="page">
      <div className="chatBox">
        {messages.map((msg) => (
          <div key={msg.id} className={msg.isBot ? "bot-message" : "user-message"}>
            {!msg.isBot && <div className="textRight">{msg.prompt}</div>}
            <div className="textLeft">{formatBotResponse(msg.response)}</div>
          </div>
        ))}
      </div>
   
      <div className="chatBotControls">
      <input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message"
      />
      <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chatbot;
