import advicesTypes from "./advices.types";
import adviceTypes from "./advices.types";

const INITIAL_STATE = {
  advices: [],
  advice: {},
};

const advicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case adviceTypes.SET_ADVICES:
      return {
        ...state,
        advices: action.payload,
      };
    case advicesTypes.SET_ADVICE:
      return {
        ...state,
        advice: action.payload,
      };
    default:
      return state;
  }
};

export default advicesReducer;
