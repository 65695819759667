const chatsTypes = {
  ADD_NEW_CHAT_START: "ADD_NEW_CHAT_START",
  FETCH_CHATS_START: "FETCH_CHATS_START",
  SET_CHATS: "SET_CHATS",
  DELETE_CHAT_START: "DELETE_CHAT_START",
  FETCH_CHAT_START: "FETCH_CHAT_START",
  SET_CHAT: "SET_CHAT",
  UPDATE_CHAT_START: "UPDATE_CHAT_START",
  UPDATE_CHAT_SUCCESS: "UPDATE_CHAT_SUCCESS",
  UPDATE_CHAT_FAIL: "UPDATE_CHAT_FAIL",

  GET_REALTIME_USERS: "GET_REALTIME_USERS",
  GET_REALTIME_MESSAGES: "GET_REALTIME_MESSAGES",
};

export default chatsTypes;
