import listsTypes from "./lists.types";
import listTypes from "./lists.types";

const INITIAL_STATE = {
  lists: [],
  myList: {},
  error: null,
};

const listsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case listTypes.SET_LISTS:
      return {
        ...state,
        lists: action.payload,
      };
    case listsTypes.SET_LIST:
      return {
        ...state,
        myList: action.payload,
      };
      case listsTypes.UPDATE_LIST_SUCCESS:
        return {
          ...state,
          lists: state.lists.map((list) =>
            list.documentID === action.payload.documentID ? action.payload : list
          ),
          error: null,
        };
      case listsTypes.UPDATE_LIST_ERROR:
        return {
          ...state,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default listsReducer;
