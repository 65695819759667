const notificationsTypes = {
  ADD_NEW_NOTIFICATION_START: "ADD_NEW_NOTIFICATION_START",
  FETCH_NOTIFICATIONS_START: "FETCH_NOTIFICATIONS_START",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  DELETE_NOTIFICATION_START: "DELETE_NOTIFICATION_START",
  FETCH_NOTIFICATION_START: "FETCH_NOTIFICATION_START",
  SET_NOTIFICATION: "SET_NOTIFICATION",
};

export default notificationsTypes;
