const businessClaimsTypes = {
  ADD_NEW_BUSINESS_CLAIM_START: "ADD_NEW_BUSINESS_CLAIM_START",
  FETCH_BUSINESS_CLAIMS_START: "FETCH_BUSINESS_CLAIMS_START",
  SET_BUSINESS_CLAIMS: "SET_BUSINESS_CLAIMS",
  DELETE_BUSINESS_CLAIM_START: "DELETE_BUSINESS_CLAIM_START",
  FETCH_BUSINESS_CLAIM_START: "FETCH_BUSINESS_CLAIM_START",
  SET_BUSINESS_CLAIM: "SET_ADVICE",
};

export default businessClaimsTypes;
