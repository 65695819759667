import nutritionsTypes from "./nutritions.types";
import nutritionTypes from "./nutritions.types";

const INITIAL_STATE = {
  nutritions: [],
  nutrition: {},
};

const nutritionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case nutritionTypes.SET_NUTRITIONS:
      return {
        ...state,
        nutritions: action.payload,
      };
    case nutritionsTypes.SET_NUTRITION:
      return {
        ...state,
        nutrition: action.payload,
      };
    default:
      return state;
  }
};

export default nutritionsReducer;
