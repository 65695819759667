import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useMetaTags } from "../hooks/useMetaTags";
import { RecipeCategoriesCarrousel } from "./RecipeCategories";
import { PostsResults } from "../results/PostsResults";
import { BusinessesCarrousel } from "../components/dashboard/BusinessesCarrousel";
import { SpecialistsCarrousel } from "../components/dashboard/SpecialistsCarrousel";

function Dashboard() {
  const { t } = useTranslation();

  useMetaTags({
    title: `${t("Welcome to Listeat")}`,
    description: `${t("Alege din miile de retete")}`,
    image: "/assets/logo512.png",
    url: "https://listeat.ro/dashboard",
  });

  return (
    <>
      <div className="page">
        <h3 className="flexHeader marginTopNone">
          {t("Recipe categories")}
          <Link to="/recipe-categories">
            <button>{t("See all")}</button>
          </Link>
        </h3>
        <br />
        <RecipeCategoriesCarrousel />
        <BusinessesCarrousel />
        <br />
        <SpecialistsCarrousel />
        <br />
        <h3 className="flexHeader">
          {t("Recently added")}
          <Link to="/explore">
            <button>{t("See all")}</button>
          </Link>
        </h3>
        <PostsResults mainTypeNull={true} />
      </div>
    </>
  );
}

export default Dashboard;
