import ingredientsTypes from "./ingredients.types";
import ingredientTypes from "./ingredients.types";

const INITIAL_STATE = {
  ingredients: [],
  ingredient: {},
};

const ingredientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ingredientTypes.SET_INGREDIENTS:
      return {
        ...state,
        ingredients: action.payload,
      };
    case ingredientsTypes.SET_INGREDIENT:
      return {
        ...state,
        ingredient: action.payload,
      };
    default:
      return state;
  }
};

export default ingredientsReducer;
