const usersTypes = {
  ADD_NEW_USER_START: "ADD_NEW_USER_START",
  FETCH_USERS_START: "FETCH_USERS_START",
  SET_USERS: "SET_USERS",
  DELETE_USER_START: "DELETE_USER_START",
  FETCH_USER_START: "FETCH_USER_START",
  SET_USER: "SET_USER",
};

export default usersTypes;
