import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setNotifications,
  setNotification,
  fetchNotificationsStart,
} from "./notifications.actions";
import {
  handleAddNotification,
  handleFetchNotifications,
  handleFetchNotification,
  handleDeleteNotification,
} from "./notifications.helpers";
import notificationsTypes from "./notifications.types";

export function* addNotification({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddNotification({
      ...payload,
      createdDate: timestamp,
    });
    yield put(fetchNotificationsStart());
  } catch (err) {}
}

export function* onAddNotificationStart() {
  yield takeLatest(
    notificationsTypes.ADD_NEW_NOTIFICATION_START,
    addNotification
  );
}

export function* fetchNotifications({ payload }) {
  try {
    const notifications = yield handleFetchNotifications(payload);
    yield put(setNotifications(notifications));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchNotificationsStart() {
  yield takeLatest(
    notificationsTypes.FETCH_NOTIFICATIONS_START,
    fetchNotifications
  );
}

export function* fetchNotification({ payload }) {
  try {
    const notification = yield handleFetchNotification(payload);
    yield put(setNotification(notification));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchNotificationStart() {
  yield takeLatest(
    notificationsTypes.FETCH_NOTIFICATION_START,
    fetchNotification
  );
}

export function* onDeleteNotificationStart() {
  yield takeLatest(
    notificationsTypes.DELETE_NOTIFICATION_START,
    deleteNotification
  );
}

export function* deleteNotification({ payload }) {
  try {
    yield handleDeleteNotification(payload);
    yield put(fetchNotificationsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* notificationsSagas() {
  yield all([
    call(onAddNotificationStart),
    call(onFetchNotificationsStart),
    call(onDeleteNotificationStart),
    call(onFetchNotificationStart),
  ]);
}
