import React from "react";

export function useMetaTags(metaTags) {
  React.useEffect(() => {
    const defaultMetaTags = {
      title: "Listeat - Ce mancam azi",
      description:
        "Salveza retetele preferate, planific meniuri si organizeaza lista de cumparaturi",
      image: "/assets/logo512.png",
      url: "https://listeat.ro",
      ...metaTags,
    };

    const { title, description, image, url } = defaultMetaTags;
    
    document.title = title;

    const descriptionTag = document.querySelector('meta[name="description"]');
    if (descriptionTag) {
      descriptionTag.setAttribute("content", description);
    } else {
      const newDescriptionTag = document.createElement("meta");
      newDescriptionTag.setAttribute("name", "description");
      newDescriptionTag.setAttribute("content", description);
      document.head.appendChild(newDescriptionTag);
    }

    const ogImageTag = document.querySelector('meta[property="og:image"]');
    if (ogImageTag) {
      ogImageTag.setAttribute("content", image);
    } else {
      const newOgImageTag = document.createElement("meta");
      newOgImageTag.setAttribute("property", "og:image");
      newOgImageTag.setAttribute("content", image);
      document.head.appendChild(newOgImageTag);
    }

    const ogUrlTag = document.querySelector('meta[property="og:url"]');
    if (ogUrlTag) {
      ogUrlTag.setAttribute("content", url);
    } else {
      const newOgUrlTag = document.createElement("meta");
      newOgUrlTag.setAttribute("property", "og:url");
      newOgUrlTag.setAttribute("content", url);
      document.head.appendChild(newOgUrlTag);
    }
  }, [metaTags]);

  // Return nothing, since this hook only updates the document meta tags
  return null;
}
