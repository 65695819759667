import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setIngredients,
  setIngredient,
  fetchIngredientsStart,
} from "./ingredients.actions";
import {
  handleAddIngredient,
  handleFetchIngredients,
  handleFetchIngredient,
  handleDeleteIngredient,
} from "./ingredients.helpers";
import ingredientsTypes from "./ingredients.types";

export function* addIngredient({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddIngredient({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchIngredientsStart());
  } catch (err) {}
}

export function* onAddIngredientStart() {
  yield takeLatest(ingredientsTypes.ADD_NEW_INGREDIENT_START, addIngredient);
}

export function* fetchIngredients({ payload }) {
  try {
    const ingredients = yield handleFetchIngredients(payload);
    yield put(setIngredients(ingredients));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchIngredientsStart() {
  yield takeLatest(ingredientsTypes.FETCH_INGREDIENTS_START, fetchIngredients);
}

export function* fetchIngredient({ payload }) {
  try {
    const ingredient = yield handleFetchIngredient(payload);
    yield put(setIngredient(ingredient));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchIngredientStart() {
  yield takeLatest(ingredientsTypes.FETCH_INGREDIENT_START, fetchIngredient);
}

export function* onDeleteIngredientStart() {
  yield takeLatest(ingredientsTypes.DELETE_INGREDIENT_START, deleteIngredient);
}

export function* deleteIngredient({ payload }) {
  try {
    yield handleDeleteIngredient(payload);
    yield put(fetchIngredientsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* ingredientsSagas() {
  yield all([
    call(onAddIngredientStart),
    call(onFetchIngredientsStart),
    call(onDeleteIngredientStart),
    call(onFetchIngredientStart),
  ]);
}
