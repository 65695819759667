import { firestore } from '../../firebase/utils';

export const handleAddPlan = (plan) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('plans')
      .doc()
      .set(plan)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchPlans = ({ filterType, startAfterDoc, persistPlans = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore.collection('plans').orderBy('createdDate').limit(pageSize);

    if (filterType) ref = ref.where('plansCategory', '==', filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...persistPlans,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeletePlan = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('plans')
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchPlan = (planID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("plans")
      .doc(planID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: planID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  })
}