import businessCartTypes from "./businessCart.types";

// export const addBusinessCartItem = (nextBusinessCartItem) => ({
//   type: businessCartTypes.ADD_TO_BUSINESS_CART,
//   payload: nextBusinessCartItem,
// });

export const addBusinessCartItem = (businessID, nextBusinessCartItem) => ({
  type: businessCartTypes.ADD_TO_BUSINESS_CART,
  payload: { businessID, nextBusinessCartItem }
});


// export const reduceBusinessCartItem = (businessCartItem) => ({
//   type: businessCartTypes.REDUCE_BUSINESS_CART_ITEM,
//   payload: businessCartItem,
// });

// export const removeBusinessCartItem = (businessCartItem) => ({
//   type: businessCartTypes.REMOVE_BUSINESS_CART_ITEM,
//   payload: businessCartItem,
// });

export const reduceBusinessCartItem = (businessID, businessCartItemToReduce) => ({
  type: businessCartTypes.REDUCE_BUSINESS_CART_ITEM,
  payload: { businessID, businessCartItemToReduce }
});

export const removeBusinessCartItem = (businessID, businessCartItemToRemove) => ({
  type: businessCartTypes.REMOVE_BUSINESS_CART_ITEM,
  payload: { businessID, businessCartItemToRemove }
});


export const clearBusinessCart = () => ({
  type: businessCartTypes.CLEAR_BUSINESS_CART,
});
