import menusTypes from "./menus.types";
import menuTypes from "./menus.types";

const INITIAL_STATE = {
  menus: [],
  menu: {},
};

const menusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case menuTypes.SET_MENUS:
      return {
        ...state,
        menus: action.payload,
      };
    case menusTypes.SET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case menusTypes.UPDATE_MENU_SUCCESS:
      return {
        ...state,
        menu: action.payload,
      };
    case menusTypes.REMOVE_MENU_ITEM_SUCCESS:
      return {
        ...state,
          menu: action.payload,
      };
    default:
      return state;
  }
};

export default menusReducer;
