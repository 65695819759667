const followRelationsTypes = {
  ADD_NEW_FOLLOW_RELATION_START: "ADD_NEW_FOLLOW_RELATION_START",
  FETCH_FOLLOW_RELATIONS_START: "FETCH_FOLLOW_RELATIONS_START",
  SET_FOLLOW_RELATIONS: "SET_FOLLOW_RELATIONS",
  DELETE_FOLLOW_RELATION_START: "DELETE_FOLLOW_RELATION_START",
  FETCH_FOLLOW_RELATION_START: "FETCH_FOLLOW_RELATION_START",
  SET_FOLLOW_RELATION: "SET_FOLLOW_RELATION",
  UPDATE_FOLLOW_RELATION_START: "UPDATE_FOLLOW_RELATION_START",
};

export default followRelationsTypes;
