import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//DB
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchBusinessesStart } from "../../redux/businesses/businesses.actions";
//STYLES
import "./Carrousel.scss";
import RoleIndicator from "../account/RoleIndicator";

const businessesSelector = (state) => state.businessesData.businesses;

const mapState = createSelector([businessesSelector], (businesses) => ({
  businesses,
}));

const isOpen = (business) => {
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleString("en-GB", { weekday: "long" }); // Get full day name for comparison
  const currentTime = currentDate.getHours() + currentDate.getMinutes() / 60; // Convert current time to decimal

  const { workingDays } = business;

  if (!workingDays || workingDays.length === 0) return false;

  // Find today's schedule in workingDays
  const todaySchedule = workingDays.find(
    (day) => day.day.toLowerCase() === currentDay.toLowerCase()
  );

  if (!todaySchedule || !todaySchedule.isOpen) return false;

  const { openingTime, closingTime } = todaySchedule;

  // Ensure openingTime and closingTime are strings and split them
  const [openingHour, openingMinute] = openingTime.split(":").map(Number);
  const [closingHour, closingMinute] = closingTime.split(":").map(Number);

  // Convert opening and closing times to decimal
  const openingDecimal = openingHour + openingMinute / 60;
  let closingDecimal = closingHour + closingMinute / 60;

  // Adjust for cases where closing time is after midnight
  if (closingHour === 0 && closingMinute === 0) closingDecimal = 24; // Midnight closing

  const isWithinOpeningHours =
    currentTime >= openingDecimal && currentTime < closingDecimal;

  return isWithinOpeningHours;
};

export const BusinessesCarrousel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businesses } = useSelector(mapState);
  const { data: businessesData } = businesses;

  useEffect(() => {
    dispatch(fetchBusinessesStart());
  }, [dispatch]);

  return (
    <div id="popularCreators">
      <h3 className="flexHeader">
        {t("Places")}
        <Link to="/places">
          <button>{t("See all")}</button>
        </Link>
      </h3>
      <div className="carrousel">
        <div className="carrouselScroll">
          {businessesData &&
            businessesData.map((business) => {
              return (
                <div key={business.documentID} value={business.documentID}>
                  <div className="carrouselItemAccounts">
                    <Link to={`/business/${business.documentID}`}>
                      <RoleIndicator image={business.logoUrl} user={business} />
                      <h3 className="carrouselItemAccountHeader">
                        {business.businessName}
                      </h3>
                      <p>{business.businessType}</p>
                      <p className={isOpen(business) ? "textGreen" : "textRed"}>
                        {isOpen(business) ? "Open" : "Closed"}
                      </p>
                    </Link>

                    <div className="carrouselItemAccountsBtn"></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
