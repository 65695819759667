import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setFollowRelations,
  setFollowRelation,
  fetchFollowRelationsStart,
} from "./followRelations.actions";
import {
  handleAddFollowRelation,
  handleFetchFollowRelations,
  handleFetchFollowRelation,
  handleDeleteFollowRelation,
  handleUpdateFollowRelation,
} from "./followRelations.helpers";
import followRelationsTypes from "./followRelations.types";

export function* addFollowRelation({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddFollowRelation({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchFollowRelationsStart());
  } catch (err) {}
}

export function* onAddFollowRelationStart() {
  yield takeLatest(followRelationsTypes.ADD_NEW_FOLLOW_RELATION_START, addFollowRelation);
}

export function* fetchFollowRelations({ payload }) {
  try {
    const followRelations = yield handleFetchFollowRelations(payload);
    yield put(setFollowRelations(followRelations));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchFollowRelationsStart() {
  yield takeLatest(
    followRelationsTypes.FETCH_FOLLOW_RELATIONS_START,
    fetchFollowRelations
  );
}

export function* fetchFollowRelation({ payload }) {
  try {
    const followRelation = yield handleFetchFollowRelation(payload);
    yield put(setFollowRelation(followRelation));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchFollowRelationStart() {
  yield takeLatest(
    followRelationsTypes.FETCH_FOLLOW_RELATION_START,
    fetchFollowRelation
  );
}

export function* onDeleteFollowRelationStart() {
  yield takeLatest(followRelationsTypes.DELETE_FOLLOW_RELATION_START, deleteFollowRelation);
}

export function* deleteFollowRelation({ payload }) {
  try {
    yield handleDeleteFollowRelation(payload);
    yield put(fetchFollowRelationsStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* updateFollowRelation({ payload }) {
  try {
    yield call(handleUpdateFollowRelation, payload);
    yield put(fetchFollowRelationsStart()); // Refetch follow relations to update the store
  } catch (err) {
    // Handle errors
  }
}

export function* onUpdateFollowRelationStart() {
  yield takeLatest(
    followRelationsTypes.UPDATE_FOLLOW_RELATION_START,
    updateFollowRelation
  );
}

export default function* followRelationsSagas() {
  yield all([
    call(onAddFollowRelationStart),
    call(onFetchFollowRelationsStart),
    call(onDeleteFollowRelationStart),
    call(onFetchFollowRelationStart),
    call(onUpdateFollowRelationStart),
  ]);
}
