import { firestore } from "../../firebase/utils";

export const handleAddPost = (post) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("posts")
      .doc()
      .set(post)
      .then((postID) => {
        resolve(postID);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const handleFetchPosts = ({ filterType, startAfterDoc, persistPosts = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 20;

    let ref = firestore
      .collection("posts")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("postType", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistPosts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeletePost = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("posts")
      .doc(documentID)
      .delete()
      .then(() => {
        // console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const handleFetchPost = (postID) => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection("posts")
//       .doc(postID)
//       .get()
//       .then((snapshot) => {
//         if (snapshot.exists) {
//           resolve({
//             ...snapshot.data(),
//             documentID: postID,
//           });
//         }
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const handleFetchPost = (postID) => {
  return new Promise((resolve, reject) => {
    const postRef = firestore.collection("posts").doc(postID);

    postRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error("Document does not exist");
        }

        const postData = doc.data();
        const newViewsCount = postData.isViewed ? postData.isViewed + 1 : 1;

        resolve({
          ...postData,
          documentID: postID,
        });

        return postRef.update({ isViewed: newViewsCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const handleSearchPosts = (searchTerm) => {
  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection("posts")
      .orderBy("title")
      .startAt(searchTerm)
      .endAt(searchTerm + "\uf8ff");

    ref
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            documentID: doc.id,
          };
        });
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdatePost = (postID, updatedData) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("posts")
      .doc(postID)
      .update(updatedData)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// export const handleIncrementViews = (postID) => {
//   return new Promise((resolve, reject) => {
//     const recipeRef = firestore.collection("posts").doc(postID);

//     firestore
//       .runTransaction((transaction) => {
//         return transaction.get(recipeRef).then((doc) => {
//           if (!doc.exists) {
//             console.log("Document does not exist")
//           }

//           const newViewsCount = doc.data().isViewed
//             ? doc.data().isViewed + 1
//             : 1;
//           transaction.update(recipeRef, { isViewed: newViewsCount });
//           return newViewsCount;
//         });
//       })
//       .then((newViewsCount) => {
//         resolve(newViewsCount);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const handleIncreaseSaves = (postID) => {
  return new Promise((resolve, reject) => {
    const postRef = firestore.collection("posts").doc(postID);

    postRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error("Document does not exist");
        }

        const newSavedByCount = (doc.data().savedBy || 0) + 1;
        postRef
          .update({ savedBy: newSavedByCount })
          .then(() => resolve(newSavedByCount))
          .catch(reject);
      })
      .catch(reject);
  });
};

export const handleDecreaseSaves = (postID) => {
  return new Promise((resolve, reject) => {
    const postRef = firestore.collection("posts").doc(postID);

    postRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error("Document does not exist");
        }

        const currentSavedByCount = doc.data().savedBy || 0;
        const newSavedByCount = Math.max(0, currentSavedByCount - 1);
        postRef
          .update({ savedBy: newSavedByCount })
          .then(() => resolve(newSavedByCount))
          .catch(reject);
      })
      .catch(reject);
  });
};

//UPDATE OR REMOVE POST MENU 
export const handleUpdatePostMenu = (documentID, updatedMenu) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("posts")
      .doc(documentID)
      .update(updatedMenu)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleRemovePostMenuItem = (documentID, mealType, postID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("posts")
      .doc(documentID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error("Document not found");
        }
        const menu = doc.data();
        const updatedMeal = menu[mealType].filter((item) => item !== postID);

        return firestore
          .collection("posts")
          .doc(documentID)
          .update({
            [mealType]: updatedMeal,
          });
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};