import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import usersReducer from "./users/users.reducer";
import postsReducer from "./posts/posts.reducer";
import nutritionsReducer from "./nutritions/nutritions.reducer";
import menusReducer from "./menus/menus.reducer";
import listsReducer from "./lists/lists.reducer";
import notificationsReducer from "./notifications/notifications.reducer";
import commentsReducer from "./comments/comments.reducer";

import advicesReducer from "./advices/advices.reducer";
import ingredientsReducer from "./ingredients/ingredients.reducer";
import businessesReducer from "./businesses/businesses.reducer";
import businessOrdersReducer from "./businessOrders/businessOrders.reducer";
import businessClaimsReducer from "./businessClaims/businessClaims.reducer";
import followRelationsReducer from "./followRelations/followRelations.reducer";
import followRequestsReducer from "./followRequests/followRequests.reducer";
import plansReducer from "./plans/plans.reducer";
import chatsReducer from "./chats/chats.reducer";
import cartReducer from "./cart/cart.reducer";
import businessCartReducer from "./businessCart/businessCart.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  usersData: usersReducer,

  postsData: postsReducer,
  nutritionsData: nutritionsReducer,

  menusData: menusReducer,
  listsData: listsReducer,

  notificationsData: notificationsReducer,
  commentsData: commentsReducer,

  advicesData: advicesReducer,
  ingredientsData: ingredientsReducer,
  businessesData: businessesReducer,
  businessOrdersData: businessOrdersReducer,
  businessClaimsData: businessClaimsReducer,
  followRelationsData: followRelationsReducer,
  followRequestsData: followRequestsReducer,
  plansData: plansReducer,
  chatsData: chatsReducer,
  cartData: cartReducer,
  businessCartData: businessCartReducer,
});

const configStorage = {
  key: "root",
  storage,
  whitelist: ["cartData", "businessCartData"],
};

export default persistReducer(configStorage, rootReducer);
