const businessOrdersTypes = {
  ADD_NEW_BUSINESS_ORDER_START: "ADD_NEW_BUSINESS_ORDER_START",
  FETCH_BUSINESS_ORDERS_START: "FETCH_BUSINESS_ORDERS_START",
  SET_BUSINESS_ORDERS: "SET_BUSINESS_ORDERS",
  DELETE_BUSINESS_ORDER_START: "DELETE_BUSINESS_ORDER_START",
  FETCH_BUSINESS_ORDER_START: "FETCH_BUSINESS_ORDER_START",
  SET_BUSINESS_ORDER: "SET_BUSINESS_ORDER",
};

export default businessOrdersTypes;
