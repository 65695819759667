import React, { useState, useRef } from "react";
import { HiOutlineX } from "react-icons/hi";

//STYLES
import "./Modals.scss";

export function Modal({ children, hideModal, extenderClass, index, id }) {
  if (hideModal) return null;
  return (
    <React.Fragment key={index}>
      <div className={`modal ${extenderClass}`}>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function Modal2({ children, hideModal2, extenderClass, index }) {
  if (hideModal2) return null;
  return (
    <React.Fragment key={index}>
      <div className={`modal ${extenderClass}`}>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalIngredients({ children, hideModalIngredients, index }) {
  if (hideModalIngredients) return null;
  return (
    <div className="modal" key={index}>
      <div>{children}</div>
    </div>
  );
}
export function ModalNotify({
  children,
  hideModalNotify,
  toggleModalNotify,
  index,
  extenderClass,
  closeOnBackdrop,
}) {
  if (hideModalNotify) return null;
  return (
    <React.Fragment key={index}>
      {!closeOnBackdrop ? (
        <div className="backdrop999" onClick={() => toggleModalNotify()} />
      ) : (
        <div className="backdrop999" />
      )}
      <div className={`modalNotify ${extenderClass}`}>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalNotify2({
  children,
  hideModalNotify2,
  toggleModalNotify2,
  index,
}) {
  if (hideModalNotify2) return null;
  return (
    <React.Fragment key={index}>
      <div className="backdrop999" onClick={() => toggleModalNotify2()} />
      <div className="modalNotify">
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalNotify3({
  children,
  hideModalNotify3,
  toggleModalNotify3,
  index,
}) {
  if (hideModalNotify3) return null;
  return (
    <React.Fragment key={index}>
      <div className="backdrop999" onClick={() => toggleModalNotify3()} />
      <div className="modalNotify">
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalVideo({
  children,
  hideModalVideo,
  toggleModalVideo,
  index,
}) {
  if (hideModalVideo) return null;
  return (
    <React.Fragment key={index}>
      <button className="closeModalBtn" onClick={() => toggleModalVideo()}>
        <HiOutlineX />
      </button>
      <div className="modalVideo">{children}</div>
    </React.Fragment>
  );
}
export function ModalTooltip(props) {
  const [setActive, setActiveState] = useState("");
  const [setModalFull, setModalFullState] = useState("modalFullContent");
  const [setModalFullBackdrop, setModalFullStateBackdrop] = useState(
    "backdrop transparent"
  );

  const content = useRef(null);

  function toggleModalFull() {
    setActiveState(setActive === "" ? "active" : "");
    setModalFullState(
      setActive === "active" ? "modalFullContent" : "modalFullContent show"
    );
    setModalFullStateBackdrop(
      setActive === "active"
        ? "backdrop transparent"
        : "backdrop transparent show"
    );
  }

  return (
    <>
      <div className={setModalFullBackdrop} onClick={toggleModalFull} />
      <div className={`modalTooltip ${setActive}`}>
        <span className={`modalButton ${setActive}`} onClick={toggleModalFull}>
          {props.title}
        </span>

        <div
          ref={content}
          id="modalFullContent"
          className={`modalFullContent ${setModalFull}`}
          onClick={toggleModalFull}
        >
          {props.content}
        </div>
      </div>
    </>
  );
}
export function ModalTooltipMultiple(props) {
  const [setActive, setActiveState] = useState("");
  const [setModalFull, setModalFullState] = useState("modalFullContent");
  const [setModalFullBackdrop, setModalFullStateBackdrop] = useState(
    "backdrop transparent"
  );

  const content = useRef(null);

  function toggleModalFull() {
    setActiveState(setActive === "" ? "active" : "");
    setModalFullState(
      setActive === "active" ? "modalFullContent" : "modalFullContent show"
    );
    setModalFullStateBackdrop(
      setActive === "active"
        ? "backdrop transparent"
        : "backdrop transparent show"
    );
  }

  return (
    <>
      <div className={setModalFullBackdrop} onClick={toggleModalFull} />
      <div
        className={`modalTooltip${
          props.chat === true ? " chatTooltip" : ""
        } ${setActive}`}
      >
        <span
          className={`modalButton${
            props.chat === true ? " chatButton" : ""
          } ${setActive}`}
          onClick={toggleModalFull}
        >
          {props.title}
        </span>
      </div>
      <div
        ref={content}
        id="modalFullContent"
        className={`modalFullContent ${setModalFull}`}
        onClick={toggleModalFull}
      >
        {props.content}
      </div>
    </>
  );
}
export function ModalTooltip2({
  children,
  hideModalTooltip,
  toggleModalTooltip,
  index,
}) {
  if (hideModalTooltip) return null;
  return (
    <>
      <div
        className="backdropTransparent"
        onClick={() => toggleModalTooltip()}
        key={index}
      />
      <div className="modalTooltip">{children}</div>
    </>
  );
}
export function ModalTooltipEdit({
  children,
  hideModalTooltipEdit,
  toggleModalTooltipEdit,
  index,
}) {
  if (hideModalTooltipEdit) return null;
  return (
    <>
      <div
        className="backdropTransparent"
        onClick={() => toggleModalTooltipEdit()}
        key={index}
      />
      <div className="modalTooltip">{children}</div>
    </>
  );
}
export function ModalBottom({
  children,
  hideModalBottom,
  toggleModalBottom,
  index,
}) {
  const modalRef = useRef(null);
  const startY = useRef(null);
  const threshold = 100;

  const handleTouchStart = (event) => {
    startY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!modalRef.current || !startY.current) return;

    const deltaY = event.touches[0].clientY - startY.current;
    if (deltaY > threshold) {
      toggleModalBottom();
    }
  };

  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModalBottom ? "hidden" : ""}`}
        onClick={() => toggleModalBottom()}
        ref={modalRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      />
      <div
        ref={modalRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className={`modalBottom ${hideModalBottom ? "" : "show"}`}
      >
        <span
          className="modalBottomCloseButton"
          onClick={() => toggleModalBottom()}
        >
          <span />
        </span>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalBottom2({
  children,
  hideModalBottom2,
  toggleModalBottom2,
  index,
}) {
  // if (hideModalBottom2) return null;
  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModalBottom2 ? "hidden" : ""}`}
        onClick={() => toggleModalBottom2()}
      />
      <div
        className={`modalBottom ${hideModalBottom2 ? "" : "show"}`}
        index="modalBottom"
      >
        <span
          className="modalBottomCloseButton"
          onClick={() => toggleModalBottom2()}
        >
          <span />
        </span>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalBottom3({
  children,
  hideModalBottom3,
  toggleModalBottom3,
  index,
}) {
  // if (hideModalBottom3) return null;
  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModalBottom3 ? "hidden" : ""}`}
        onClick={() => toggleModalBottom3()}
      />
      <div
        className={`modalBottom ${hideModalBottom3 ? "" : "show"}`}
        index="modalBottom"
      >
        <span
          className="modalBottomCloseButton"
          onClick={() => toggleModalBottom3()}
        >
          <span />
        </span>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalBottom4({
  children,
  hideModalBottom4,
  toggleModalBottom4,
  index,
}) {
  // if (hideModalBottom4) return null;
  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModalBottom4 ? "hidden" : ""}`}
        onClick={() => toggleModalBottom4()}
      />
      <div
        className={`modalBottom ${hideModalBottom4 ? "" : "show"}`}
        index="modalBottom"
      >
        <span
          className="modalBottomCloseButton"
          onClick={() => toggleModalBottom4()}
        >
          <span />
        </span>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ModalBottom5({
  children,
  hideModalBottom5,
  toggleModalBottom5,
  index,
}) {
  return (
    <React.Fragment key={index}>
      <div
        className={`backdropBottom ${hideModalBottom5 ? "hidden" : ""}`}
        onClick={() => toggleModalBottom5()}
      />
      <div
        className={`modalBottom ${hideModalBottom5 ? "" : "show"}`}
        index="modalBottom"
      >
        <span
          className="modalBottomCloseButton"
          onClick={() => toggleModalBottom5()}
        >
          <span />
        </span>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
}
export function ChatTimestamp({ children, hideSentAt }) {
  if (hideSentAt) return null;
  return <>{children}</>;
}
