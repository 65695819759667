const advicesTypes = {
  ADD_NEW_ADVICE_START: "ADD_NEW_ADVICE_START",
  FETCH_ADVICES_START: "FETCH_ADVICES_START",
  SET_ADVICES: "SET_ADVICES",
  DELETE_ADVICE_START: "DELETE_ADVICE_START",
  FETCH_ADVICE_START: "FETCH_ADVICE_START",
  SET_ADVICE: "SET_ADVICE",
};

export default advicesTypes;
