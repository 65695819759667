import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setChats,
  setChat,
  fetchChatsStart,
  updateChatSuccess,
} from "./chats.actions";
import {
  handleAddChat,
  handleFetchChats,
  handleFetchChat,
  handleDeleteChat,
} from "./chats.helpers";
import chatsTypes from "./chats.types";

export function* addChat({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddChat({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchChatsStart());
  } catch (err) {}
}

export function* onAddChatStart() {
  yield takeLatest(chatsTypes.ADD_NEW_CHAT_START, addChat);
}

export function* fetchChats({ payload }) {
  try {
    const chats = yield handleFetchChats(payload);
    yield put(setChats(chats));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchChatsStart() {
  yield takeLatest(chatsTypes.FETCH_CHATS_START, fetchChats);
}

export function* fetchChat({ payload }) {
  try {
    const chat = yield handleFetchChat(payload);
    yield put(setChat(chat));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchChatStart() {
  yield takeLatest(chatsTypes.FETCH_CHAT_START, fetchChat);
}

export function* onDeleteChatStart() {
  yield takeLatest(chatsTypes.DELETE_CHAT_START, deleteChat);
}

export function* deleteChat({ payload }) {
  try {
    yield handleDeleteChat(payload);
    yield put(fetchChatsStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* updateChat({ payload }) {
  try {
    const { id, newMessage } = payload;
    yield put(updateChatSuccess({ id, newMessage }));
  } catch (err) {
    // Handle error
  }
}

export function* onUpdateChatStart() {
  yield takeLatest(chatsTypes.UPDATE_CHAT_START, updateChat);
}

export default function* chatsSagas() {
  yield all([
    call(onAddChatStart),
    call(onFetchChatsStart),
    call(onUpdateChatStart),
    call(onDeleteChatStart),
    call(onFetchChatStart),
  ]);
}
