import { firestore } from "../../firebase/utils";

export const handleAddUser = (user) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc()
      .set(user)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchUsers = ({ filterType, startAfterDoc, persistUsers = [] }) => {
  return new Promise((resolve, reject) => {
    // const pageSize = 9;

    let ref = firestore
      .collection("users")
      .orderBy("createdDate", "desc")
      // .limit(pageSize);

    if (filterType) ref = ref.where("userCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistUsers,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          // isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteUser = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchUser = (userID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(userID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: userID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};


