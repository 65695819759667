import followRequestsTypes from "./followRequests.types";

export const addFollowRequestStart = (followRequestData) => ({
  type: followRequestsTypes.ADD_NEW_FOLLOW_REQUEST_START,
  payload: followRequestData,
});

export const fetchFollowRequestsStart = (filters = {}) => ({
  type: followRequestsTypes.FETCH_FOLLOW_REQUESTS_START,
  payload: filters,
});

export const setFollowRequests = (followRequests) => ({
  type: followRequestsTypes.SET_FOLLOW_REQUESTS,
  payload: followRequests,
});

export const deleteFollowRequestStart = (followRequestID) => ({
  type: followRequestsTypes.DELETE_FOLLOW_REQUEST_START,
  payload: followRequestID,
});

export const fetchFollowRequestStart = (followRequestID) => ({
  type: followRequestsTypes.FETCH_FOLLOW_REQUEST_START,
  payload: followRequestID,
});

export const setFollowRequest = (followRequest) => ({
  type: followRequestsTypes.SET_FOLLOW_REQUEST,
  payload: followRequest,
});
