import listsTypes from "./lists.types";

export const addListStart = (listData) => ({
  type: listsTypes.ADD_NEW_LIST_START,
  payload: listData,
});
export const fetchListsStart = (filters = {}) => ({
  type: listsTypes.FETCH_LISTS_START,
  payload: filters,
});
export const setLists = (lists) => ({
  type: listsTypes.SET_LISTS,
  payload: lists,
});
export const deleteListStart = (listID) => ({
  type: listsTypes.DELETE_LIST_START,
  payload: listID,
});
export const fetchListStart = (listID) => ({
  type: listsTypes.FETCH_LIST_START,
  payload: listID,
});
export const setList = (myList) => ({
  type: listsTypes.SET_LIST,
  payload: myList,
});
export const updateListStart = (listData) => ({
  type: listsTypes.UPDATE_LIST_START,
  payload: listData,
});
export const updateListSuccess = (list) => ({
  type: listsTypes.UPDATE_LIST_SUCCESS,
  payload: list,
});
export const updateListError = (error) => ({
  type: listsTypes.UPDATE_LIST_ERROR,
  payload: error,
});
