import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import { setLists, setList, fetchListsStart, updateListSuccess, updateListError } from "./lists.actions";
import {
  handleAddList,
  handleFetchLists,
  handleFetchList,
  handleDeleteList,
  handleUpdateList,
} from "./lists.helpers";
import listsTypes from "./lists.types";

export function* addList({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddList({
      ...payload,
      author: auth.currentUser.displayName,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchListsStart());
  } catch (err) {}
}

export function* onAddListStart() {
  yield takeLatest(listsTypes.ADD_NEW_LIST_START, addList);
}

export function* fetchLists({ payload }) {
  try {
    const lists = yield handleFetchLists(payload);
    yield put(setLists(lists));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchListsStart() {
  yield takeLatest(listsTypes.FETCH_LISTS_START, fetchLists);
}

export function* fetchList({ payload }) {
  try {
    const myList = yield handleFetchList(payload);
    yield put(setList(myList));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchListStart() {
  yield takeLatest(listsTypes.FETCH_LIST_START, fetchList);
}

export function* onDeleteListStart() {
  yield takeLatest(listsTypes.DELETE_LIST_START, deleteList);
}

export function* deleteList({ payload }) {
  try {
    yield handleDeleteList(payload);
    yield put(fetchListsStart());
  } catch (err) {
    // console.log(err);
  }
}
//UPDATE LIST
export function* updateList({ payload }) {
  try {
    yield call(handleUpdateList, payload);
    yield put(updateListSuccess(payload));
  } catch (error) {
    yield put(updateListError(error));
  }
}
export function* onUpdateListStart() {
  yield takeLatest(listsTypes.UPDATE_LIST_START, updateList);
}

export default function* listsSagas() {
  yield all([
    call(onAddListStart),
    call(onFetchListsStart),
    call(onDeleteListStart),
    call(onFetchListStart),
    call(onUpdateListStart),
  ]);
}
