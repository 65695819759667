import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//DB
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersStart } from "../../redux/users/users.actions";
import { fetchPostsStart } from "../../redux/posts/posts.actions";
//COMPONENTS
import RoleIndicator from "../account/RoleIndicator";
import { FollowButton } from "../formElements/Buttons";
import { fetchFollowRelationsStart } from "../../redux/followRelations/followRelations.actions";
import { createSelector } from "reselect";

import "./Carrousel.scss";

const postsSelector = (state) => state.postsData.posts;
const followRelationsSelector = (state) =>
  state.followRelationsData.followRelations;
const currentUserSelector = (state) => state.user.currentUser;
const usersSelector = (state) => state.usersData.users;

const mapState = createSelector(
  [postsSelector, followRelationsSelector, currentUserSelector, usersSelector],
  (posts, followRelations, currentUser, users) => ({
    posts,
    followRelations,
    currentUser,
    users,
  })
);

export const SpecialistsCarrousel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { currentUser, users, posts, followRelations } = useSelector(mapState);
  const { data: usersData } = users;
  const { data: postsData } = posts;
  const { data: followRelationsData } = followRelations;

  useEffect(() => {
    dispatch(fetchUsersStart());
    dispatch(fetchPostsStart());
    dispatch(fetchFollowRelationsStart());
  }, [dispatch]);

  useEffect(() => {
    if (usersData && postsData) {
      setLoading(false);
    }
  }, [usersData, postsData]);

  const getUserRecipeCount = (userID) => {
    if (!postsData) {
      return 0;
    }
    return (
      postsData && postsData.filter((post) => post.userID === userID).length
    );
  };

  let sortedUsers = [];

  if (Array.isArray(usersData)) {
    sortedUsers = [...usersData].sort((a, b) => {
      return (
        getUserRecipeCount(b.documentID) - getUserRecipeCount(a.documentID)
      );
    });
  }

  return (
    <div id="popularCreators">
      <h3 className="flexHeader">{t("Specialists")}</h3>
      <div className="carrousel">
        <div className="carrouselScroll">
          {sortedUsers &&
            sortedUsers.map((user, specialist) => {
              //CHECK FOLLOW RELATIONS
              const isUserFollowingOrFollower = () => {
                return (
                  followRelationsData &&
                  followRelationsData.some(
                    (relation) =>
                      relation.followerID === currentUser.id &&
                      relation.followingID === user.documentID
                  )
                );
              };

              if (
                (user.blocked && user.blocked.includes(currentUser.id)) ||
                (currentUser.blocked &&
                  currentUser.blocked.includes(user.documentID)) ||
                (!isUserFollowingOrFollower() && user.privacy === true) ||
                !user.displayName ||
                !user.documentID ||
                !user.email ||
                !user.username ||
                !user.speciality ||
                user.documentID === currentUser.id
              )
                return null;

              return (
                <div key={user.documentID} value={user.documentID}>
                  {!currentUser && null}
                  {currentUser && currentUser.id === user.documentID ? (
                    <Link to="/account">
                      <div className="carrouselItemAccounts">
                        <RoleIndicator
                          image={user.photoURL}
                          user={user}
                          isOnline={user.isOnline}
                        />
                        <h3 className="carrouselItemAccountHeader">
                          {user.displayName.split(" ")[0]}
                        </h3>
                        {user.speciality && <p>{t(`${user.speciality}`)}</p>}
                        <button
                          type="button"
                          name="My account button"
                          id="myAccountBtn"
                        >
                          <span className="followBtn">My account</span>
                        </button>
                      </div>
                    </Link>
                  ) : (
                    <div className="carrouselItemAccounts specialists">
                      <Link to={`/user/${user.documentID}`}>
                        <RoleIndicator
                          image={user.photoURL}
                          user={user}
                          isOnline={user.isOnline}
                        />
                        <h3 className="carrouselItemAccountHeader">
                          {user.displayName.split(" ")[0]}
                        </h3>
                      </Link>
                      {user.speciality && <p>{t(user.speciality)}</p>}
                      <div className="carrouselItemAccountsBtn">
                        <FollowButton
                          currentUser={currentUser}
                          profileUser={user}
                          targetID={user.documentID}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          <div className="carrouselItemAccounts">
            <Link to="/specialists">
              <div className="roleIndicatorBox">
                <span className="userBackground" />
                <img src="/assets/placeholders/groupUsers.png" alt="Listeat" />
              </div>
              <h3 className="carrouselItemAccountHeader">More</h3>
              <div className="carrouselItemAccountsBtn">
                <button className="userActionBtn unfollowBtn">
                  {t("View all")}
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
