import React, { useState, useEffect } from "react";
import { LuWifiOff } from "react-icons/lu";
import "./OfflineNotification.scss";

function OfflineNotification() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const goOnline = () => setIsOffline(false);
    const goOffline = () => setIsOffline(true);

    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  if (!isOffline) {
    return null;
  }

  return (
    <div className="offlineNotification">
      <LuWifiOff /> No internet connection
    </div>
  );
}

export default OfflineNotification;
