import usersTypes from "./users.types";

export const addUserStart = (userData) => ({
  type: usersTypes.ADD_NEW_USER_START,
  payload: userData,
});

export const fetchUsersStart = (filters = {}) => ({
  type: usersTypes.FETCH_USERS_START,
  payload: filters,
});

export const setUsers = (users) => ({
  type: usersTypes.SET_USERS,
  payload: users,
});

export const deleteUserStart = (userID) => ({
  type: usersTypes.DELETE_USER_START,
  payload: userID,
});

export const fetchUserStart = (userID) => ({
  type: usersTypes.FETCH_USER_START,
  payload: userID,
});

export const setUser = (user) => ({
  type: usersTypes.SET_USER,
  payload: user,
});
