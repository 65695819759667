import businessOrdersTypes from "./businessOrders.types";

export const addBusinessOrderStart = (businessOrderData) => ({
  type: businessOrdersTypes.ADD_NEW_BUSINESS_ORDER_START,
  payload: businessOrderData,
});

export const fetchBusinessOrdersStart = (filters = {}) => ({
  type: businessOrdersTypes.FETCH_BUSINESS_ORDERS_START,
  payload: filters,
});

export const setBusinessOrders = (businessOrders) => ({
  type: businessOrdersTypes.SET_BUSINESS_ORDERS,
  payload: businessOrders,
});

export const deleteBusinessOrderStart = (businessOrderID) => ({
  type: businessOrdersTypes.DELETE_BUSINESS_ORDER_START,
  payload: businessOrderID,
});

export const fetchBusinessOrderStart = (businessOrderID) => ({
  type: businessOrdersTypes.FETCH_BUSINESS_ORDER_START,
  payload: businessOrderID,
});

export const setBusinessOrder = (businessOrder) => ({
  type: businessOrdersTypes.SET_BUSINESS_ORDER,
  payload: businessOrder,
});
