const nutritionsTypes = {
  ADD_NEW_NUTRITION_START: "ADD_NEW_NUTRITION_START",
  FETCH_NUTRITIONS_START: "FETCH_NUTRITIONS_START",
  SET_NUTRITIONS: "SET_NUTRITIONS",
  DELETE_NUTRITION_START: "DELETE_NUTRITION_START",
  FETCH_NUTRITION_START: "FETCH_NUTRITION_START",
  SET_NUTRITION: "SET_NUTRITION",
};

export default nutritionsTypes;
