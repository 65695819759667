import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineTrash,
} from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  addBusinessCartItem,
  reduceBusinessCartItem,
  removeBusinessCartItem,
} from "../../redux/businessCart/businessCart.actions";
import {
  selectBusinessCartItemsForBusiness,
  selectBusinessCartTotalForBusiness,
} from "../../redux/businessCart/businessCart.selectors";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from "../../components/formElements/Buttons";
import { InputText } from "../../components/formElements/Inputs";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { createSelector } from "reselect";
import { addBusinessOrderStart } from "../../redux/businessOrders/businessOrders.actions";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

const CheckoutItem = ({
  recipeName,
  recipePrice,
  recipeImageUrl,
  quantity,
  business,
}) => {
  const dispatch = useDispatch();
  const businessID = business.documentID;

  // INCREASE ITEM
  const handleAddToBusinessCart = () => {
    dispatch(addBusinessCartItem(businessID, { recipeName }));
  };

  // DECREASE ITEM
  const handleReduceItem = () => {
    console.log("Component: Reducing item", { recipeName, businessID });
    dispatch(reduceBusinessCartItem(businessID, { recipeName }));
  };

  // REMOVE ITEM
  const handleRemoveBusinessCartItem = () => {
    dispatch(removeBusinessCartItem(businessID, { recipeName }));
  };

  return (
    <div>
      <h5 className="flexHeader marginTopNone capitalize cartItem">
        <span>
          {recipeImageUrl ? (
            <img className="cartImage" src={recipeImageUrl} alt={recipeName} />
          ) : (
            <img
              className="cartImage"
              src="/assets/placeholders/ingredientPlaceholder.svg"
              alt="Listeat"
            />
          )}
          {recipeName}
        </span>
        <span>{recipePrice} lei</span>
        <span className="cartQuantitySelector">
          <span
            className="cartBtn"
            onClick={() => handleReduceItem(recipeName)}
          >
            <HiOutlineChevronLeft />
          </span>
          <span>{quantity}</span>
          <span
            className="cartBtn"
            onClick={() => handleAddToBusinessCart(recipeName)}
          >
            <HiOutlineChevronRight />
          </span>
        </span>
        <span onClick={() => handleRemoveBusinessCartItem(recipeName)}>
          <HiOutlineTrash color="#FF6900" />
        </span>
      </h5>
    </div>
  );
};

const libraries = ["places"];

export const CheckoutList = ({ toggleModalBottom, business }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);
  const { t } = useTranslation();
  const [deliveryPhone, setDeliveryPhone] = useState("");
  const [deliveryStreet, setDeliveryStreet] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [deliveryBuilding, setDeliveryBuilding] = useState("");
  const [deliveryBuildingSection, setDeliveryBuildingSection] = useState("");
  const [deliveryFloor, setDeliveryFloor] = useState("");
  const [deliveryFlat, setDeliveryFlat] = useState("");

  const businessCartItems = useSelector((state) =>
    selectBusinessCartItemsForBusiness(state, business.documentID)
  );
  const total = useSelector((state) =>
    selectBusinessCartTotalForBusiness(state, business.documentID)
  );
  const [showContactDetails, setShowCheckoutDetails] = useState(false);

  //ADDRESS AUTOCOMPLETE
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBpbcdvO9XTV8D0-WHZrIjk9O4qhKNZ-Qo",
    libraries,
  });

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setDeliveryStreet(place.formatted_address);
    }
  };

  // Adjust handleBusinessOrderSubmit to retrieve the documentID of the created order
  const handleBusinessOrderSubmit = async () => {
    const orderData = {
      businessID: business.documentID,
      businessName: business.businessName,
      deliveryContact: deliveryPhone,
      deliveryStreet,
      deliveryNumber,
      deliveryBuilding,
      deliveryBuildingSection,
      deliveryFloor,
      deliveryFlat,
    };
    if (currentUser) {
      orderData.currentUser = {
        id: currentUser.id,
        email: currentUser.email,
      };
    }

    const newOrder = await dispatch(addBusinessOrderStart(orderData));

    return newOrder?.documentID;
  };

  // CHECKOUT TO WHATSAPP
  const sendToWhatsApp = async () => {
    if (businessCartItems && businessCartItems.length > 0) {
      const itemsText = businessCartItems
        .map(
          (item) =>
            `${item.recipeName} - ${item.quantity} x ${item.recipePrice}LEI`
        )
        .join("\n");
      const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        `${deliveryStreet} ${deliveryNumber}`
      )}`;

      // Submit order and get documentID
      try {
        const orderID = await handleBusinessOrderSubmit();
        const message = `Comanda mea la ${
          business.businessName
        } de pe Listeat:\n\n${itemsText}\n\nTotal: ${total}LEI\n\nAdresa livrare: Strada: ${deliveryStreet}, Numarul: ${deliveryNumber}${
          deliveryBuilding ? `, Bloc: ${deliveryBuilding}` : ""
        }${
          deliveryBuildingSection ? `, Scara: ${deliveryBuildingSection}` : ""
        }${deliveryFloor ? `, Etaj: ${deliveryFloor}` : ""}${
          deliveryFlat ? `, Apartament: ${deliveryFlat}` : ""
        }\n\nMaps: ${googleMapsLink}\n\nNumar telefon: ${deliveryPhone}\n\nLink comanda: https://listeat.ro/business-order/${orderID}`;

        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/+40${business.contactPhone}?text=${encodedMessage}`;
        window.open(whatsappUrl, "_blank");
      } catch (error) {
        // console.error("Error submitting order:", error);
        // alert(t("Error sending order to WhatsApp"));
      }
    } else {
      // alert(t("Your cart is empty"));
    }
  };

  const toggleShowContactDetails = () => {
    if (showContactDetails) {
      setShowCheckoutDetails(false);
    } else {
      setShowCheckoutDetails(true);
    }
  };

  return (
    <div>
      {businessCartItems && businessCartItems.length > 0 ? (
        <>
          {!showContactDetails ? (
            <>
              {businessCartItems &&
                businessCartItems.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <CheckoutItem {...item} business={business} />
                    </React.Fragment>
                  );
                })}
              <h3 className="flexHeader">
                Total: <span>{total} LEI</span>
              </h3>
            </>
          ) : (
            <div
              className={`checkoutDetails${
                showContactDetails ? " showContactDetails" : ""
              }`}
            >
              <h3>{t("Delivery details")}</h3>
              <InputText
                label={t("Phone number")}
                type="number"
                handleChange={(e) => setDeliveryPhone(e.target.value)}
                required
              />
              {isLoaded ? (
                <Autocomplete
                  onLoad={(auto) => setAutocomplete(auto)}
                  onPlaceChanged={onPlaceChanged}
                  // handleChange={(e) => setAddress(e.target.value)}
                >
                  <InputText
                    label={t("Street address")}
                    name="address"
                    value={deliveryStreet}
                    placeholder="Strada Unirii"
                    required={!deliveryStreet}
                    handleChange={(e) => setDeliveryStreet(e.target.value)}
                  />
                </Autocomplete>
              ) : (
                <InputText
                  label={t("Street address")}
                  name="address"
                  value={deliveryStreet}
                  placeholder="Strada Unirii"
                  required={!deliveryStreet}
                  handleChange={(e) => setDeliveryStreet(e.target.value)}
                />
              )}
              <div className="flexBox">
                <InputText
                  label={t("Number")}
                  name="number"
                  value={deliveryNumber}
                  placeholder="79"
                  required
                  handleChange={(e) => setDeliveryNumber(e.target.value)}
                />
                <InputText
                  label={t("Building")}
                  name="building"
                  value={deliveryBuilding}
                  placeholder="V7A"
                  required
                  handleChange={(e) => setDeliveryBuilding(e.target.value)}
                />
                <InputText
                  label={t("Section")}
                  name="buildingSection"
                  value={deliveryBuildingSection}
                  placeholder="A"
                  handleChange={(e) =>
                    setDeliveryBuildingSection(e.target.value)
                  }
                />
                <InputText
                  label={t("Floor")}
                  name="floor"
                  value={deliveryFloor}
                  placeholder="1"
                  handleChange={(e) => setDeliveryFloor(e.target.value)}
                />
                <InputText
                  label={t("Flat")}
                  name="number"
                  value={deliveryFlat}
                  placeholder="7"
                  handleChange={(e) => setDeliveryFlat(e.target.value)}
                />
              </div>
              <small>{t("Save this data to actual account")}</small>
            </div>
          )}
          {!showContactDetails ? (
            <>
              <ButtonSecondary
                title={t("Add delivery details")}
                // onClick={sendToWhatsApp}
                onClick={() => toggleShowContactDetails()}
                disabled={businessCartItems && businessCartItems.length < 1}
              />
              <ButtonTertiary
                title={t("Close")}
                onClick={() => toggleModalBottom()}
              />
            </>
          ) : (
            <>
              <ButtonPrimary
                title={t("Send order")}
                onClick={sendToWhatsApp}
                // onClick={() => toggleShowContactDetails()}
                disabled={businessCartItems && businessCartItems.length < 1}
              />
              <ButtonTertiary
                title={t("Cancel")}
                onClick={() => toggleShowContactDetails()}
              />
            </>
          )}

          <br />
          <br />
        </>
      ) : (
        <>
          <br />
          <p>
            {t("Your cart is empty")}.{" "}
            <strong onClick={toggleModalBottom}>{t("View menu")}</strong>
          </p>
          <br />
          <br />
        </>
      )}
    </div>
  );
};
