import businessClaimsTypes from "./businessClaims.types";

export const addBusinessClaimStart = (businessClaimData) => ({
  type: businessClaimsTypes.ADD_NEW_BUSINESS_CLAIM_START,
  payload: businessClaimData,
});

export const fetchBusinessClaimsStart = (filters = {}) => ({
  type: businessClaimsTypes.FETCH_BUSINESS_CLAIMS_START,
  payload: filters,
});

export const setBusinessClaims = (businessClaims) => ({
  type: businessClaimsTypes.SET_BUSINESS_CLAIMS,
  payload: businessClaims,
});

export const deleteBusinessClaimStart = (businessClaimID) => ({
  type: businessClaimsTypes.DELETE_BUSINESS_CLAIM_START,
  payload: businessClaimID,
});

export const fetchBusinessClaimStart = (businessClaimID) => ({
  type: businessClaimsTypes.FETCH_BUSINESS_CLAIM_START,
  payload: businessClaimID,
});

export const setBusinessClaim = (businessClaim) => ({
  type: businessClaimsTypes.SET_BUSINESS_CLAIM,
  payload: businessClaim,
});
