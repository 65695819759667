import nutritionsTypes from "./nutritions.types";

export const addNutritionStart = (nutritionData) => ({
  type: nutritionsTypes.ADD_NEW_NUTRITION_START,
  payload: nutritionData,
});

export const fetchNutritionsStart = (filters = {}) => ({
  type: nutritionsTypes.FETCH_NUTRITIONS_START,
  payload: filters,
});

export const setNutritions = (nutritions) => ({
  type: nutritionsTypes.SET_NUTRITIONS,
  payload: nutritions,
});

export const deleteNutritionStart = (nutritionID) => ({
  type: nutritionsTypes.DELETE_NUTRITION_START,
  payload: nutritionID,
});

export const fetchNutritionStart = (nutritionID) => ({
  type: nutritionsTypes.FETCH_NUTRITION_START,
  payload: nutritionID,
});

export const setNutrition = (nutrition) => ({
  type: nutritionsTypes.SET_NUTRITION,
  payload: nutrition,
});
