import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setBusinessOrders,
  setBusinessOrder,
  fetchBusinessOrdersStart,
} from "./businessOrders.actions";
import {
  handleAddBusinessOrder,
  handleFetchBusinessOrders,
  handleFetchBusinessOrder,
  handleDeleteBusinessOrder,
} from "./businessOrders.helpers";
import businessOrdersTypes from "./businessOrders.types";

export function* addBusinessOrder({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddBusinessOrder({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchBusinessOrdersStart());
  } catch (err) {}
}

export function* onAddBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.ADD_NEW_BUSINESS_ORDER_START,
    addBusinessOrder
  );
}

export function* fetchBusinessOrders({ payload }) {
  try {
    const businessOrders = yield handleFetchBusinessOrders(payload);
    yield put(setBusinessOrders(businessOrders));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessOrdersStart() {
  yield takeLatest(
    businessOrdersTypes.FETCH_BUSINESS_ORDERS_START,
    fetchBusinessOrders
  );
}

export function* fetchBusinessOrder({ payload }) {
  try {
    const businessOrder = yield handleFetchBusinessOrder(payload);
    yield put(setBusinessOrder(businessOrder));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.FETCH_BUSINESS_ORDER_START,
    fetchBusinessOrder
  );
}

export function* onDeleteBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.DELETE_BUSINESS_ORDER_START,
    deleteBusinessOrder
  );
}

export function* deleteBusinessOrder({ payload }) {
  try {
    yield handleDeleteBusinessOrder(payload);
    yield put(fetchBusinessOrdersStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* businessOrdersSagas() {
  yield all([
    call(onAddBusinessOrderStart),
    call(onFetchBusinessOrdersStart),
    call(onDeleteBusinessOrderStart),
    call(onFetchBusinessOrderStart),
  ]);
}
