import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiChevronLeft,
  HiOutlineAdjustments,
  HiOutlineChevronLeft,
  HiOutlineSearch,
  HiOutlineX,
} from "react-icons/hi";
import { BiBarcodeReader } from "react-icons/bi";
import { ButtonPrimary } from "./formElements/Buttons";
import { InputCheckbox, InputRadio, InputSearch } from "./formElements/Inputs";
import { Modal, Modal2, ModalBottom } from "./genericComponents/Modals";
import { Accordion } from "./genericComponents/Accordion";
import { BarcodeScannerContainer } from "./formElements/BarcodeScanner";
import { searchPostsStart } from "../redux/posts/posts.actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function SearchFilter({
  allIngredients,
  handleIngredientChange,
  onSearch,
  hasFilter,
  hasScanner,
  searchType,
}) {
  const { t } = useTranslation();

  SearchFilter.defaultProps = {
    hasFilter: true,
    hasScanner: true,
  };

  //SEARCH RECIPES NEW
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(searchPostsStart(searchTerm));
  };

  //MODAL
  const [hideModal, setHideModal] = useState(true);
  const toggleModal = () => setHideModal(!hideModal);
  const [hideModal2, setHideModal2] = useState(true);
  const toggleModal2 = () => setHideModal2(!hideModal2);
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const configModal = {
    hideModal,
    toggleModal,
    hideModal2,
    toggleModal2,
    hideModalBottom,
    toggleModalBottom,
  };

  //FILTER BY INGREDIENTS
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const handleIngredientCheckboxChange = (event) => {
    const ingredientId = event.target.value;
    const isSelected = event.target.checked;
    if (isSelected) {
      setSelectedIngredients([...selectedIngredients, ingredientId]);
    } else {
      setSelectedIngredients(
        selectedIngredients.filter((id) => id !== ingredientId)
      );
    }
    handleIngredientChange(event);
  };

  const isIngredientSelected = (ingredient) => {
    return selectedIngredients.includes(ingredient.id);
  };
  const filteredIngredients = selectedIngredients.filter(isIngredientSelected);

  const handleRemoveFilter = (ingredientId) => {
    setSelectedIngredients(
      selectedIngredients.filter((id) => id !== ingredientId)
    );

    const cleanEvent = {
      target: {
        value: ingredientId,
        checked: false,
      },
    };

    handleIngredientCheckboxChange(cleanEvent);
  };

  return (
    <>
      <div className="flexSearch">
        <form
          onSubmit={handleSearchSubmit}
          className={`${hasScanner ? "hasScanner" : ""} ${
            hasFilter ? "hasFilter" : ""
          }`}
        >
          <InputSearch
            id="searchInput"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={
              searchType ? `${t(`Search ${searchType}`)}` : `${t("Search")}`
            }
          />
          <button
            aria-label="searchBtn"
            type="submit"
            className="searchSubmitButton"
            disabled={searchTerm.length < 3}
          >
            <HiOutlineSearch />
          </button>
        </form>
        {hasFilter && (
          <button
            aria-label="filtersBtn"
            type="button"
            className="filtersBtn"
            onClick={() => toggleModalBottom()}
            id="recipeFilters"
          >
            <HiOutlineAdjustments />
          </button>
        )}
        {hasScanner && (
          <Link to="/barcode-scanner">
            <button
              aria-label="barcodeScannerBtn"
              className="filtersBtn"
              // onClick={() => toggleModal2()}
              id="barcodeScanner"
            >
              <BiBarcodeReader />
            </button>
          </Link>
        )}
      </div>
      {hasFilter === true && (
        <>
          <div className="flexRadio">
            <div className="checkboxCardBox">
              {selectedIngredients.map((ingredientId, index) => {
                const ingredient =
                  allIngredients &&
                  allIngredients.find(
                    (ingr) => ingr.id.toString() === ingredientId
                  );

                if (ingredient) {
                  return (
                    <div key={ingredientId} className="selectedFilter">
                      <label htmlFor={ingredient.id}>
                        <span>
                          <p>
                            <strong className="flexFilter">
                              <img src={ingredient.asset} alt="Listeat" />
                              {ingredient.name}{" "}
                              <HiOutlineX
                                onClick={() => handleRemoveFilter(ingredientId)}
                              />
                            </strong>
                          </p>
                        </span>
                      </label>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <ModalBottom id="id" {...configModal}>
            <>
              <div className="fixedHeader">
                <h3>
                  {t("Filters")}
                  {selectedIngredients.length === 0
                    ? null
                    : ` - ${selectedIngredients.length}`}
                </h3>
              </div>
              <div className="addRecipeForm">
                <h5 className="flexHeader">
                  {t("Search by ingredient")}

                  <button
                    aria-label="seeAllIngredientsBtn"
                    onClick={() => toggleModal()}
                  >
                    {t("See all")}
                  </button>
                </h5>
                <br />

                <div className="flexRadio">
                  <div className="checkboxCardBox">
                    {filteredIngredients.length > 0
                      ? filteredIngredients.map((ingredient, index) => (
                          <div key={index}>
                            <InputCheckbox
                              type="checkbox"
                              id={ingredient.id}
                              value={ingredient.id}
                              label={ingredient.id}
                              onChange={handleIngredientCheckboxChange}
                              checked={isIngredientSelected(ingredient)}
                              className="inputCheckbox"
                            />
                          </div>
                        ))
                      : allIngredients &&
                        allIngredients.map((ingredient, index) => {
                          if (
                            ingredient.name !== "Macrou" &&
                            ingredient.name !== "Macrou" &&
                            ingredient.name !== "Usturoi" &&
                            ingredient.name !== "Ulei de masline" &&
                            ingredient.name !== "Faina de grau" &&
                            ingredient.name !== "Oua de gaina" &&
                            ingredient.name !== "Piept de pui" &&
                            ingredient.name !== "Bacon" &&
                            ingredient.name !== "Carne tocata vita-oaie" &&
                            ingredient.name !== "Carne tocata de porc"
                          )
                            return null;
                          return (
                            <div key={index}>
                              <InputCheckbox
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.id}
                                onChange={handleIngredientCheckboxChange}
                                checked={isIngredientSelected(ingredient)}
                                className="inputCheckbox"
                                label={ingredient.name}
                              />
                            </div>
                          );
                        })}
                  </div>
                </div>
                <br />
                <h5 className="flexHeader">{t("Categories")}</h5>
                <br />
                <div className="flexRadio">
                  <div className="checkboxCardBox">
                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="breakfast"
                        value="Breakfast"
                        label={t("Breakfast")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="mainDish"
                        value="Main dish"
                        label={t("Main dish")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="sideDish"
                        value="Side dish"
                        label={t("Side dish")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="appetizer"
                        value="Appetizer"
                        label={t("Appetizer")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="dessert"
                        value="Dessert"
                        label={t("Dessert")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="beverage"
                        value="Beverage"
                        label={t("Beverage")}
                      />
                    </div>

                    <div>
                      <InputRadio
                        name="recipeCategory"
                        id="snack"
                        value="Snack"
                        label={t("Snack")}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <h5 className="flexHeader">{t("Cook time")}</h5>
                <br />
                <div className="flexRadio">
                  <div className="checkboxCardBox">
                    <div>
                      <InputRadio
                        name="cookTime"
                        id="under15Min"
                        value="Under 15 min"
                        label={t("Under 15 min")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="cookTime"
                        id="under30Min"
                        value="Under 30 min"
                        label={t("Under 30 min")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="cookTime"
                        id="under1h"
                        value="Under 1 hour"
                        label={t("Under 1 hour")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="cookTime"
                        id="over1Hour"
                        value="Over 1 hour"
                        label={t("Over 1 hour")}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <h5 className="flexHeader">
                  {t("Type")}{" "}
                  <button aria-label="seeAllTypeBtn">{t("See all")}</button>
                </h5>
                <br />
                <div className="flexRadio">
                  <div className="checkboxCardBox">
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="vegan"
                        value="Vegan"
                        label={t("Vegan")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="vegetarian"
                        value="Vegetarian"
                        label={t("Vegetarian")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="keto"
                        value="Keto"
                        label={t("Keto")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="dairyFree"
                        value="Dairy free"
                        label={t("Dairy free")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="glutenFree"
                        value="Gluten free"
                        label={t("Gluten free")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="lactoVegetarian"
                        value="Lacto vegetarian"
                        label={t("Lacto vegetarian")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeType"
                        id="lowCarbs"
                        value="Low carbs"
                        label={t("Low carbs")}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <h5 className="flexHeader">
                  {t("Cuisine")}
                  <button aria-label="seeAllCuisineBtn">{t("See all")}</button>
                </h5>
                <br />
                <div className="flexRadio">
                  <div className="checkboxCardBox">
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="romanian"
                        value="Romanian"
                        label={t("Romanian")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="chinese"
                        value="Chinese"
                        label={t("Chinese")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="african"
                        value="African"
                        label={t("African")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="japanese"
                        value="Japanese"
                        label={t("Japanese")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="indian"
                        value="Indian"
                        label={t("Indian")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="french"
                        value="French"
                        label={t("French")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="italian"
                        value="Italian"
                        label={t("Italian")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="thai"
                        value="Thai"
                        label={t("Thai")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="moroccan"
                        value="Moroccan"
                        label={t("Moroccan")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="turkish"
                        value="Turkish"
                        label={t("Turkish")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="mexican"
                        value="Mexican"
                        label={t("Mexican")}
                      />
                    </div>
                    <div>
                      <InputRadio
                        name="recipeCuisine"
                        id="greek"
                        value="Greek"
                        label={t("Greek")}
                      />
                    </div>
                  </div>
                  <br />
                  <h5 className="flexHeader">
                    {t("Nutrition")}
                    <button aria-label="seeAllCuisineBtn">
                      {t("See all")}
                    </button>
                  </h5>
                  <br />
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      <div>
                        <InputRadio
                          name="nutritionFilter"
                          id="highInFiber"
                          value="High in fiber"
                          label={t("High in fiber")}
                        />
                      </div>
                      <div>
                        <InputRadio
                          name="nutritionFilter"
                          id="lowCalories"
                          value="Low calories"
                          label={t("Low calories")}
                        />
                      </div>
                      <div>
                        <InputRadio
                          name="nutritionFilter"
                          id="lowCarbs"
                          value="Low carbs"
                          label={t("Low carbs")}
                        />
                      </div>
                      <div>
                        <InputRadio
                          name="nutritionFilter"
                          id="weightLoss"
                          value="Weight loss"
                          label={t("Weight loss")}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <h5 className="flexHeader">
                    {t("PH")}
                    <button aria-label="seeAllCuisineBtn">
                      {t("See all")}
                    </button>
                  </h5>
                  <br />
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      <div>
                        <InputRadio
                          name="phFilter"
                          id="acid"
                          value="Acid"
                          label={t("Acid")}
                        />
                      </div>
                      <div>
                        <InputRadio
                          name="phFilter"
                          id="neutral"
                          value="Neautral"
                          label={t("Neutral")}
                        />
                      </div>
                      <div>
                        <InputRadio
                          name="phFilter"
                          id="alkaline"
                          value="Alkaline"
                          label={t("Alkaline")}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <span className="fixedButton">
                    <ButtonPrimary
                      title={t("Apply")}
                      onClick={() => toggleModalBottom()}
                    />
                  </span>
                </div>
              </div>
            </>
          </ModalBottom>
          <Modal {...configModal} id="allIngredientsModal">
            <button className="closeModalBtn" onClick={() => toggleModal()}>
              <HiChevronLeft />
            </button>
            <h3>{t("Search by ingredient")}</h3>
            <div className="modalBackground">
              <Accordion
                title={t("Meat")}
                content={
                  <>
                    <br />
                    <div className="flexRadio">
                      <div className="checkboxCardBox">
                        {filteredIngredients.length > 0
                          ? filteredIngredients.map((ingredient, index) => (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            ))
                          : allIngredients &&
                            allIngredients.map((ingredient, index) => {
                              if (ingredient.type !== "Carne") return null;
                              return (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    id={ingredient.id}
                                    value={ingredient.id}
                                    onChange={handleIngredientCheckboxChange}
                                    className="inputCheckbox"
                                  />
                                  <label htmlFor={ingredient.id}>
                                    <span>
                                      <p>
                                        <strong>{ingredient.name}</strong>
                                      </p>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </>
                }
              />
              <Accordion
                title={t("Fish")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.id}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Peste") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Dairy and eggs")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (
                              ingredient.type !== "Oua" &&
                              ingredient.type !== "Lactate"
                            )
                              return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Vegetables")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Legume") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Flour")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Fainoase") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Seminte")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Seminte") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Fruits")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Fructe") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Seasoning")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Condimente") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Oil and vinegar")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Ulei si otet") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <Accordion
                title={t("Sauces")}
                content={
                  <div className="flexRadio">
                    <div className="checkboxCardBox">
                      {filteredIngredients.length > 0
                        ? filteredIngredients.map((ingredient, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={ingredient.id}
                                value={ingredient.name}
                                onChange={handleIngredientCheckboxChange}
                                className="inputCheckbox"
                              />
                              <label htmlFor={ingredient.id}>
                                <span>
                                  <p>
                                    <strong>{ingredient.name}</strong>
                                  </p>
                                </span>
                              </label>
                            </div>
                          ))
                        : allIngredients &&
                          allIngredients.map((ingredient, index) => {
                            if (ingredient.type !== "Sosuri") return null;
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={ingredient.id}
                                  value={ingredient.id}
                                  onChange={handleIngredientCheckboxChange}
                                  className="inputCheckbox"
                                />
                                <label htmlFor={ingredient.id}>
                                  <span>
                                    <p>
                                      <strong>{ingredient.name}</strong>
                                    </p>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                }
              />
              <br />
              <br />
              <div className="fixedButton">
                <ButtonPrimary
                  title={t("Apply")}
                  onClick={() => toggleModal()}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default SearchFilter;
