import { all, call } from "redux-saga/effects";

import userSagas from "./user/user.sagas";
import usersSagas from "./users/users.sagas";

import postsSagas from "./posts/posts.sagas";
import nutritionsSagas from "./nutritions/nutritions.sagas";

import menusSagas from "./menus/menus.sagas";
import listsSagas from "./lists/lists.sagas";
import ordersSagas from "./orders/orders.sagas";

import advicesSagas from "./advices/advices.sagas";
import commentsSagas from "./comments/comments.sagas";
import notificationsSagas from "./notifications/notifications.sagas";
import ingredientsSagas from "./ingredients/ingredients.sagas";
import businessesSagas from "./businesses/businesses.sagas";
import businessOrdersSagas from "./businessOrders/businessOrders.sagas";
import businessClaimsSagas from "./businessClaims/businessClaims.sagas";
import followRelationsSagas from "./followRelations/followRelations.sagas";
import followRequestsSagas from "./followRequests/followRequests.sagas";
import plansSagas from "./plans/plans.sagas";
import chatsSagas from "./chats/chats.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(usersSagas),
    call(postsSagas),
    call(nutritionsSagas),
    call(menusSagas),
    call(listsSagas),
    call(advicesSagas),
    call(commentsSagas),
    call(notificationsSagas),
    call(ingredientsSagas),
    call(businessesSagas),
    call(businessClaimsSagas),
    call(businessOrdersSagas),
    call(followRelationsSagas),
    call(followRequestsSagas),
    call(ordersSagas),
    call(plansSagas),
    call(chatsSagas),
  ]);
}
