import followRelationsTypes from "./followRelations.types";

export const addFollowRelationStart = (followRelationsData) => ({
  type: followRelationsTypes.ADD_NEW_FOLLOW_RELATION_START,
  payload: followRelationsData,
});

export const fetchFollowRelationsStart = (filters = {}) => ({
  type: followRelationsTypes.FETCH_FOLLOW_RELATIONS_START,
  payload: filters,
});

export const setFollowRelations = (followRelations) => ({
  type: followRelationsTypes.SET_FOLLOW_RELATIONS,
  payload: followRelations,
});

export const deleteFollowRelationStart = (followRelationID) => ({
  type: followRelationsTypes.DELETE_FOLLOW_RELATION_START,
  payload: followRelationID,
});

export const fetchFollowRelationStart = (followRelationID) => ({
  type: followRelationsTypes.FETCH_FOLLOW_RELATION_START,
  payload: followRelationID,
});

export const setFollowRelation = (followRelation) => ({
  type: followRelationsTypes.SET_FOLLOW_RELATION,
  payload: followRelation,
});

export const updateFollowRelationStart = (followRelationData) => ({
  type: followRelationsTypes.UPDATE_FOLLOW_RELATION_START,
  payload: followRelationData,
});