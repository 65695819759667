import React, { useState, useEffect, useRef } from "react";
import "./Toasts.scss";

export function ToastNotification(props, index) {
  const [showToast, setShowToast] = useState(true);

  //SWIPE
  const [swiped, setSwiped] = useState(false);
  const swipeableRef = useRef(null);

  let xDown = null;
  let yDown = null;

  const handleTouchStart = (e) => {
    xDown = e.touches[0].clientX;
    yDown = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (yDiff > 0) {
      setSwiped(true);
    }

    xDown = null;
    yDown = null;
  };

  useEffect(() => {
    const swipeable = swipeableRef.current;
    swipeable.addEventListener("touchstart", handleTouchStart);
    swipeable.addEventListener("touchmove", handleTouchMove);

    return () => {
      swipeable.removeEventListener("touchstart", handleTouchStart);
      swipeable.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  if (!showToast) {
    return null;
  }

  return (
    <div ref={swipeableRef} className={`swipeable ${swiped ? "swiped" : ""}`}>
      <div key={index} className="toastNotification">
        {props.content}
      </div>
    </div>
  );
}
