import businessesTypes from "./businesses.types";

export const addBusinessStart = (businessData) => ({
  type: businessesTypes.ADD_NEW_BUSINESS_START,
  payload: businessData,
});

export const fetchBusinessesStart = (filters = {}) => ({
  type: businessesTypes.FETCH_BUSINESSES_START,
  payload: filters,
});

export const setBusinesses = (businesses) => ({
  type: businessesTypes.SET_BUSINESSES,
  payload: businesses,
});

export const deleteBusinessStart = (businessID) => ({
  type: businessesTypes.DELETE_BUSINESS_START,
  payload: businessID,
});

export const fetchBusinessStart = (businessID) => ({
  type: businessesTypes.FETCH_BUSINESS_START,
  payload: businessID,
});

export const setBusiness = (business) => ({
  type: businessesTypes.SET_BUSINESS,
  payload: business,
});

export const updateBusinessStart = (businessData) => ({
  type: businessesTypes.UPDATE_BUSINESS_START,
  payload: businessData,
});

export const updateBusinessSuccess = (business) => ({
  type: businessesTypes.UPDATE_BUSINESS_SUCCESS,
  payload: business,
});
