import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setBusinessClaims,
  setBusinessClaim,
  fetchBusinessClaimsStart,
} from "./businessClaims.actions";
import {
  handleAddBusinessClaim,
  handleFetchBusinessClaims,
  handleFetchBusinessClaim,
  handleDeleteBusinessClaim,
} from "./businessClaims.helpers";
import businessClaimsTypes from "./businessClaims.types";

export function* addBusinessClaim({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddBusinessClaim({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchBusinessClaimsStart());
  } catch (err) {}
}

export function* onAddBusinessClaimStart() {
  yield takeLatest(
    businessClaimsTypes.ADD_NEW_BUSINESS_CLAIM_START,
    addBusinessClaim
  );
}

export function* fetchBusinessClaims({ payload }) {
  try {
    const businessClaims = yield handleFetchBusinessClaims(payload);
    yield put(setBusinessClaims(businessClaims));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessClaimsStart() {
  yield takeLatest(
    businessClaimsTypes.FETCH_BUSINESS_CLAIMS_START,
    fetchBusinessClaims
  );
}

export function* fetchBusinessClaim({ payload }) {
  try {
    const businessClaim = yield handleFetchBusinessClaim(payload);
    yield put(setBusinessClaim(businessClaim));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessClaimStart() {
  yield takeLatest(
    businessClaimsTypes.FETCH_BUSINESS_CLAIM_START,
    fetchBusinessClaim
  );
}

export function* onDeleteBusinessClaimStart() {
  yield takeLatest(
    businessClaimsTypes.DELETE_BUSINESS_CLAIM_START,
    deleteBusinessClaim
  );
}

export function* deleteBusinessClaim({ payload }) {
  try {
    yield handleDeleteBusinessClaim(payload);
    yield put(fetchBusinessClaimsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* businessClaimsSagas() {
  yield all([
    call(onAddBusinessClaimStart),
    call(onFetchBusinessClaimsStart),
    call(onDeleteBusinessClaimStart),
    call(onFetchBusinessClaimStart),
  ]);
}
