export const dataCategories = [
  {
    name: "Most appreciated",
    image: "/assets/categories/appreciated.webp",
    conditions: " ",
    value: "Apreciate",
    link: "most-appreciated",
  },
  {
    name: "Beef",
    image: "/assets/categories/beef.webp",
    conditions: " ",
    value: "Vita",
    link: "beef",
  },
  {
    name: "Broth",
    image: "/assets/categories/broth.webp",
    conditions: " ",
    value: "Bors",
    link: "broth",
  },
  {
    name: "Budget",
    image: "/assets/categories/budget.webp",
    conditions: " ",
    value: "buget",
    link: "budget",
  },
  {
    name: "Chicken",
    image: "/assets/categories/chicken.webp",
    conditions: " ",
    link: "chicken",
  },
  {
    name: "Dessert",
    image: "/assets/categories/dessert.webp",
    conditions: " ",
    link: "dessert",
  },
  {
    name: "Diabetics",
    image: "/assets/categories/diabetics.webp",
    conditions: " ",
    link: "diabetics",
  },
  {
    name: "Dried food",
    image: "/assets/categories/driedFood.webp",
    conditions: " ",
    link: "dried-food",
  },
  {
    name: "Drinks",
    image: "/assets/categories/drink.webp",
    conditions: " ",
    link: "drinks",
  },
  {
    name: "Fast food",
    image: "/assets/categories/fastFood.webp",
    conditions: " ",
    link: "fast-food",
  },
  {
    name: "Fish",
    image: "/assets/categories/fish.avif",
    conditions: " ",
    link: "fish",
  },
  {
    name: "Game",
    image: "/assets/categories/game.webp",
    conditions: " ",
    link: "game",
  },
  {
    name: "Gluten free",
    image: "/assets/categories/glutenFree.avif",
    conditions: " ",
    link: "gluten-free",
  },
  {
    name: "Grill",
    image: "/assets/categories/grill.avif",
    conditions: " ",
    link: "grill",
  },
  {
    name: "Healthy",
    image: "/assets/categories/healthy.avif",
    conditions: " ",
    value: "Sanatoase",
    link: "healthy",
  },
  {
    name: "High in fiber",
    image: "/assets/categories/highFiber.avif",
    conditions: " ",
    link: "high-in-fiber",
  },
  {
    name: "Keto",
    image: "/assets/categories/keto.avif",
    conditions: " ",
    link: "keto",
  },
  {
    name: "Kid friendly",
    image: "/assets/categories/kidFriendly.avif",
    conditions: " ",
    link: "kid-friendly",
  },
  {
    name: "Low calories",
    image: "/assets/categories/lowCalories.avif",
    conditions: " ",
    link: "low-calories",
  },
  {
    name: "Low carbs",
    image: "/assets/categories/lowCarbs.avif",
    conditions: " ",
    link: "low-carb",
  },
  {
    name: "Lunch",
    image: "/assets/categories/lunch.avif",
    conditions: " ",
    link: "lunch",
  },
  {
    name: "Noodle",
    image: "/assets/categories/noodles.avif",
    conditions: " ",
    link: "noodle",
  },
  {
    name: "Oven",
    image: "/assets/categories/oven.avif",
    conditions: " ",
    link: "oven",
  },
  {
    name: "Pasta",
    image: "/assets/categories/pasta.avif",
    conditions: " ",
    link: "pasta",
  },
  {
    name: "Pet friendly",
    image: "/assets/categories/pets.avif",
    conditions: " ",
    link: "pet-friendly",
  },
  {
    name: "Pickles",
    image: "/assets/categories/pickles.avif",
    conditions: " ",
    value: "Muraturi",
    link: "pickles",
  },
  {
    name: "Pizza",
    image: "/assets/categories/pizza.avif",
    conditions: " ",
    link: "pizza",
  },
  {
    name: "Pork",
    image: "/assets/categories/pork.avif",
    conditions: " ",
    link: "pork",
  },
  {
    name: "Raw",
    image: "/assets/categories/raw.avif",
    conditions: " ",
    link: "raw",
  },
  {
    name: "Rice",
    image: "/assets/categories/rice.avif",
    conditions: " ",
    link: "rice",
  },
  {
    name: "Salad",
    image: "/assets/categories/salad.avif",
    conditions: " ",
    link: "salad",
  },
  {
    name: "Sauce",
    image: "/assets/categories/sauce.avif",
    conditions: " ",
    link: "sauce",
  },
  {
    name: "Sea food",
    image: "/assets/categories/seaFood.avif",
    conditions: " ",
    link: "sea-food",
  },
  {
    name: "Seasonal",
    image: "/assets/categories/seasonal.avif",
    conditions: " ",
    link: "seasonal",
  },
  {
    name: "Seasoning",
    image: "/assets/categories/seasoning.avif",
    conditions: " ",
    link: "seasoning",
  },
  {
    name: "Slow cooker",
    image: "/assets/categories/slowCooker.avif",
    conditions: " ",
    link: "slow-cooker",
  },
  {
    name: "Smoothie",
    image: "/assets/categories/smoothie.avif",
    conditions: " ",
    link: "smoothie",
  },
  {
    name: "Snacks",
    image: "/assets/categories/snack.avif",
    conditions: " ",
    link: "snacks",
  },
  {
    name: "Soup",
    image: "/assets/categories/soup.avif",
    conditions: " ",
    link: "soup",
  },
  {
    name: "Steak",
    image: "/assets/categories/steak.avif",
    conditions: " ",
    link: "steak",
  },
  {
    name: "Stew",
    image: "/assets/categories/stew.avif",
    conditions: " ",
    link: "stew",
  },
  {
    name: "Under 30 minutes",
    image: "/assets/categories/under30.avif",
    conditions: " ",
    link: "under-30-minutes",
  },
  {
    name: "Vegan",
    image: "/assets/categories/vegan.avif",
    conditions: " ",
    link: "vegan",
  },
  {
    name: "Vegetarian",
    image: "/assets/categories/vegetarian.avif",
    conditions: " ",
    link: "vegetarian",
  },
  {
    name: "Weight loss",
    image: "/assets/categories/weightLoss.avif",
    conditions: " ",
    link: "weight-loss",
  },
];