import { firestore } from "../../firebase/utils";

export const handleAddFollowRelation = (followRelation) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRelations")
      .doc()
      .set(followRelation)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchFollowRelations = ({
  filterType,
  startAfterDoc,
  persistFollowRelations = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("followRelations")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("followRelationCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistFollowRelations,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteFollowRelation = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRelations")
      .doc(documentID)
      .delete()
      .then(() => {
        // console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchFollowRelation = (followRelationID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRelations")
      .doc(followRelationID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: followRelationID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateFollowRelation = (followRelationData) => {
  const { documentID, ...updateData } = followRelationData;
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRelations")
      .doc(documentID)
      .update(updateData)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};