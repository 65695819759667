import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Tour.scss";
import Joyride from "react-joyride";

function Tour() {
  const { t } = useTranslation();
  const location = useLocation();

  // TOUR
  const [run, setRun] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [joyrideSteps, setJoyrideSteps] = useState([]);

  const getJoyrideSteps = (pathname) => {
    switch (pathname) {
      case "/dashboard":
        return [
          {
            title: t("Hello! I'm the 'Add post' button"),
            target: "#addButton",
            content: t(
              "From here you can add new recipes, menus or ingredients."
            ),
            placement: "left-start",
            disableBeacon: true,
          },
          {
            title: t("I'm the 'Notifications' button"),
            target: "#notificationsButton",
            content: t("Here you access received notifications."),
            placement: "auto",
          },
          {
            title: t("I bring you to 'Your account'"),
            target: "#accountButton",
            content: t(
              "Here you can find your personal preferences and other account settings."
            ),
            placement: "auto",
          },
          {
            title: t("Recipe categories"),
            target: "#recipeCategories",
            content: t("Here you can find recipes by your preferences."),
            placement: "auto",
          },
          {
            title: t("Search"),
            target: "#searchInput",
            content: t(
              "Here you can search recipes, menus, ingredients and blog articles."
            ),
            placement: "top",
          },
          {
            title: t("Recipe filters"),
            target: "#recipeFilters",
            content: t(
              "Select the ingredients that you have in the fridge or pantry and see what you can make with them."
            ),
            placement: "top",
          },
          {
            title: t("Barcode scanner"),
            target: "#barcodeScanner",
            content: t(
              "Scan any food, drink or other product and get nutritional data and prices or add it to your recipe or menu."
            ),
            placement: "top",
          },
          {
            title: t("Recipe results"),
            target: "#recipeCard",
            content: t(
              "Here you can search recipes, menus, ingredients and blog articles."
            ),
            placement: "top",
          },
          {
            title: t("Food GPT"),
            target: "#foodGpt",
            content: t(
              "Here you can search recipes, menus, ingredients and blog articles."
            ),
            placement: "top",
          },
          {
            title: t("Explore posts"),
            target: "#exploreButton",
            content: t(
              "Here you can find recipes, menus, ingredients, articles and many more."
            ),
            placement: "top",
          },
          {
            title: t("Menu planner"),
            target: "#menuPlannerButton",
            content: t(
              "Here you can plan your daily, weekly or monthly menus and also track your nutrition."
            ),
            placement: "top",
          },
          {
            title: t("Shopping list"),
            target: "#shoppingListButton",
            content: t(
              "Here you can view the list of groceries you have to get from added recipes in the menu planner and more."
            ),
            placement: "top",
            disableBeacon: true,
          },
        ];
      case "/explore":
        return [
          {
            title: t("Post tabs"),
            target: "#tabs",
            content: t("Here you can find recipes by your preferences."),
            placement: "auto",
          },
          {
            title: t("Post sub tabs"),
            target: "#subTabs",
            content: t("Here you can find recipes by your preferences."),
            placement: "auto",
          },
        ];
      case "/account":
        return [
          {
            title: t("Settings"),
            target: "#settingsButton",
            content: t("Highlighting settings element 1"),
            placement: "bottom",
            disableBeacon: true,
          },
          {
            title: t("Messages"),
            target: "#messagesButton",
            content: t("Highlighting settings element 2"),
            placement: "bottom",
          },
          {
            title: t("Share account"),
            target: "#shareButton",
            content: t("Highlighting settings element 2"),
            placement: "bottom",
          },
        ];
      case "/menu-planner":
        return [
          {
            title: t("Day selector"),
            target: "#dayButtons",
            content: t(
              "These are the weekdays. Select the day by clicking on it and start to plan your meals."
            ),
            placement: "bottom",
            disableBeacon: true,
          },
          {
            title: t("Month selector"),
            target: "#monthSelector",
            content: t(
              "These are the weekdays. Select the day by clicking on it and start to plan your meals."
            ),
            placement: "bottom",
            disableBeacon: true,
          },
          {
            title: t("View today's nutrition"),
            target: "#nutritionButton",
            content: t("Highlighting settings element 2"),
            placement: "top",
            disableBeacon: true,
            disableScrolling: true,
          },
          {
            title: t("Fill daily meal"),
            target: "#meal",
            content: t("Highlighting settings element 2"),
            placement: "bottom",
            disableBeacon: true,
            disableScrolling: true,
          },
        ];
      case "/shopping-list":
        return [
          {
            title: t("Add custom item"),
            target: "#addCustomItem",
            content: t("Highlighting settings element 1"),
            placement: "bottom",
            disableBeacon: true,
          },
          {
            title: t("Shopping list archive"),
            target: "#archiveListButton",
            content: t("Here you can find your shopping history."),
            placement: "right-end",
          },
          {
            title: t("Delete list"),
            target: "#deleteListButton",
            content: t("Highlighting settings element 1"),
            placement: "bottom",
          },
          {
            title: t("Share list"),
            target: "#shareListButton",
            content: t("Highlighting settings element 1"),
            placement: "bottom",
          },
          {
            title: t("Compare list"),
            target: "#compareListButton",
            content: t("Highlighting settings element 1"),
            placement: "bottom",
          },
          {
            title: t("Order list"),
            target: "#orderListButton",
            content: t("Highlighting settings element 1"),
            placement: "bottom",
          },
        ];
      default:
        return [];
    }
  };

  const customStyles = {
    buttonNext: {
      background: "#FF6900",
      borderRadius: "50px",
    },
    buttonBack: {
      color: "#FF6900",
    },
    tooltipContainer: {
      textAlign: "left",
    },
    tooltipFooter: {
      marginTop: "0",
    },
    beacon: {
      inner: "#000",
      outer: "#000",
    },
  };

  useEffect(() => {
    const storedStepKey = `tourStep_${location.pathname}`;
    const storedStep = Math.max(
      parseInt(localStorage.getItem(storedStepKey), 10) || 0
    );
    const tourCompleted = localStorage.getItem(
      `tourCompleted_${location.pathname}`
    );

    if (!tourCompleted) {
      setRun(true);
      setTourStep(storedStep);
    }

    const steps = getJoyrideSteps(location.pathname);
    setJoyrideSteps(steps);
  }, [location.pathname, t]);

  const handleJoyrideStepChange = (data) => {
    const { index, action, type } = data;

    if (type === "step:after") {
      const stepToStore = index === 0 ? 2 : index; // Store 2 if at the first step
      localStorage.setItem(`tourStep_${location.pathname}`, stepToStore); // Save the current step index
    }

    if (type === "step:after" && action === "close") {
      localStorage.setItem(`tourCompleted_${location.pathname}`, "true"); // Mark the tour as completed
      setRun(false);
    }

    if (type === "step:after" && action === "next") {
      const nextStepIndex = index + 1;
      if (nextStepIndex < joyrideSteps.length) {
        setTourStep(nextStepIndex);
      } else {
        // Mark the tour as completed for this page
        localStorage.setItem(`tourCompleted_${location.pathname}`, "true");
        localStorage.removeItem(`tourStep_${location.pathname}`); // Clear stored step
        setRun(false); // Stop the tour once it's completed
      }
    }

    if (type === "step:after" && action === "back") {
      const prevStepIndex = index - 1;
      if (prevStepIndex >= 0) {
        setTourStep(prevStepIndex);
      }
    }
  };

  return (
    <>
      <Joyride
        steps={joyrideSteps}
        run={run}
        continuous
        controlled
        scrollToFirstStep
        stepIndex={tourStep}
        showProgress
        callback={handleJoyrideStepChange}
        autoStart={true}
        disableScrollParentFix={true}
        spotlightPadding={10}
        styles={customStyles}
      />
    </>
  );
}

export default Tour;
