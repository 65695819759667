const ingredientsTypes = {
  ADD_NEW_INGREDIENT_START: "ADD_NEW_INGREDIENT_START",
  FETCH_INGREDIENTS_START: "FETCH_INGREDIENTS_START",
  SET_INGREDIENTS: "SET_INGREDIENTS",
  DELETE_INGREDIENT_START: "DELETE_INGREDIENT_START",
  FETCH_INGREDIENT_START: "FETCH_INGREDIENT_START",
  SET_INGREDIENT: "SET_INGREDIENT",
};

export default ingredientsTypes;
