import businessClaimsTypes from "./businessClaims.types";
import businessClaimTypes from "./businessClaims.types";

const INITIAL_STATE = {
  businessClaims: [],
  businessClaim: {},
};

const businessClaimsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessClaimTypes.SET_BUSINESS_CLAIMS:
      return {
        ...state,
        businessClaims: action.payload,
      };
    case businessClaimsTypes.SET_BUSINESS_CLAIM:
      return {
        ...state,
        businessClaim: action.payload,
      };
    default:
      return state;
  }
};

export default businessClaimsReducer;
