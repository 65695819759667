import { createSelector } from "reselect";

export const selectBusinessCartData = (state) => state.businessCartData;

export const selectBusinessCartItems = createSelector(
  [selectBusinessCartData],
  (businessCartData) => businessCartData.businessCartItems
);

export const selectBusinessCartItemsCount = createSelector(
  [selectBusinessCartItems],
  (businessCartItems) =>
    businessCartItems.reduce(
      (quantity, businessCartItem) => quantity + businessCartItem.quantity,
      0
    )
);

export const selectBusinessCartTotal = createSelector([selectBusinessCartItems], (businessCartItems) =>
  businessCartItems.reduce(
    (quantity, businessCartItem) =>
      quantity + businessCartItem.quantity * businessCartItem.recipePrice,
    0
  )
);

//SELECTOR

const selectBusinessCarts = (state) => state.businessCartData.businessCarts;


// export const selectBusinessCartForBusiness = createSelector(
//   [selectBusinessCarts, (_, businessID) => businessID],
//   (businessCarts, businessID) => {
//     console.log("Business Carts State: ", businessCarts);
//     console.log("Specific Business ID: ", businessID);
//     const cartItems = businessCarts[businessID] ? businessCarts[businessID].items : [];
//     console.log("Cart Items for Business: ", cartItems);
//     return cartItems;
//   }
// );

// export const selectBusinessCartTotalForBusiness = createSelector(
//   [selectBusinessCartForBusiness],
//   (businessCartItems) => {
//     console.log("Business Cart Items in Total Selector:", businessCartItems);
//     if (!Array.isArray(businessCartItems)) {
//       console.error("Non-array type received:", businessCartItems);
//       return 0;
//     }
//     return businessCartItems.reduce(
//       (total, item) => total + item.quantity * item.recipePrice, 0
//     );
//   }
// );

export const selectBusinessCartItemsForBusiness = createSelector(
  [selectBusinessCarts, (_, businessId) => businessId],
  (businessCarts, businessId) => {
    return businessCarts[businessId] ? businessCarts[businessId].items : [];
  }
);

export const selectBusinessCartTotalForBusiness = createSelector(
  [selectBusinessCartItemsForBusiness],
  (businessCartItems) => {
    if (!Array.isArray(businessCartItems)) {
      return 0;
    }
    return businessCartItems.reduce(
      (total, item) => total + item.quantity * item.recipePrice, 0
    );
  }
);
