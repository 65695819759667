import React from "react";
import "./RoleIndicator.scss";

function RoleIndicator(props) {
  const userRoles = props.user.userRoles;
  const image = props.image;

  const getRoleBackgroundClass = () => {
    const roleClasses = {
      admin: "adminBackground",
      business: "businessBackground",
      pro: "proBackground",
      creator: "creatorBackground",
      user: "userBackground",
    };

    const priorityRoles = ["admin", "business", "pro", "creator", "user"];

    for (const role of priorityRoles) {
      if (userRoles && userRoles.includes(role)) {
        return roleClasses[role];
      }
    }

    return "userBackground";
  };

  const imageUrl = image || "/assets/placeholders/userPlaceholder.png";

  return (
    <div className="roleIndicatorBox">
      <span className={getRoleBackgroundClass()} />
      <img src={image} alt="Listeat" />
      <div
        className={`onlineIndicator ${props.isOnline ? "online" : "offline"}`}
      />
    </div>
  );
}

export default RoleIndicator;
