import { firestore } from "../../firebase/utils";

export const handleAddNotification = (notification) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("notifications")
      .doc()
      .set(notification)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchNotifications = ({
  filterType,
  startAfterDoc,
  persistNotifications = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 2;

    let ref = firestore
      .collection("notifications")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("notificationCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistNotifications,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteNotification = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("notifications")
      .doc(documentID)
      .delete()
      .then(() => {
        // console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchNotification = (notificationID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("notifications")
      .doc(notificationID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: notificationID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
