import { firestore } from "../../firebase/utils";

export const handleAddList = (myList) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("lists")
      .doc()
      .set(myList)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchLists = ({
  filterType,
  startAfterDoc,
  persistLists = [],
}) => {
  return new Promise((resolve, reject) => {
    // const pageSize = 1;

    let ref = firestore.collection("lists").orderBy("createdDate", "asc");
    // .limit(pageSize);

    if (filterType) ref = ref.where("listCategory", "===", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        // const totalCount = snapshot.size;

        const data = [
          ...persistLists,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          // queryDoc: snapshot.docs[totalCount - 1],
          // isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteList = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("lists")
      .doc(documentID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchList = (listID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("lists")
      .doc(listID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: listID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function searchList() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}
export const handleUpdateList = (list) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("lists")
      .doc(list.documentID)
      .update(list)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};