import React, { useState, useRef } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { storage } from "../firebase/utils";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { HiOutlineRefresh } from "react-icons/hi";
import { PiLightningBold, PiLightningSlashBold } from "react-icons/pi";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../components/formElements/Buttons";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const FridgeRecipeGenerator = () => {
  const { currentUser } = useSelector(mapState);
  const [capturedImage, setCapturedImage] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [recipe, setRecipe] = useState("");
  const [loading, setLoading] = useState(false);
  const webcamRef = useRef(null);
  const [torchOn, setTorchOn] = useState(false);

  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [videoStream, setVideoStream] = useState(null);

  const videoConstraints = {
    facingMode: facingMode,
  };
  
  //CAPTURE IMAGE
  const handleCapture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setCapturedImage(imageSrc);
      await handleUploadImage(imageSrc); 
    }
  };
  // UPLOAD IMAGE
  const handleUploadImage = async (base64Image) => {
    setLoading(true);
    try {
      const storageRef = ref(storage, `fridgeImages/${currentUser.id}/${Date.now()}.jpg`);

      await uploadString(storageRef, base64Image, "data_url");

      const imageUrl = await getDownloadURL(storageRef);
      await handleDetectObjects(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // const handleDetectObjects = async (imageUrl) => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       "https://us-central1-listeatco.cloudfunctions.net/api/detectObjects",
  //       { image: imageUrl },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const detectedIngredients = response.data.ingredients || [];
  //     const ingredientLabels = detectedIngredients.map((ingredient) => ingredient.label);
  //     setIngredients(ingredientLabels);
  //   } catch (error) {
  //     console.error("Error detecting objects:", error);
  //     alert("Error detecting objects. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // DETECT OBJECTS
  const handleDetectObjects = async (imageUrl) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://us-central1-listeatco.cloudfunctions.net/api/detectObjects",
        { image: imageUrl },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const detectedIngredients = response.data.ingredients || [];
      const edibleIngredients = detectedIngredients
        .filter(ingredient => ingredient.label && ingredient.label.toLowerCase() !== 'non-edible')
        .map((ingredient) => ingredient.label);
      
      setIngredients(edibleIngredients);
    } catch (error) {
      console.error("Error detecting objects:", error);
      alert("Error detecting objects. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // GENERATE RECIPE
  const handleGenerateRecipe = async () => {
    if (ingredients.length === 0) {
      alert("No ingredients detected. Please upload a different image.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "https://us-central1-listeatco.cloudfunctions.net/api/generateRecipe", // Replace with your actual Cloud Function URL
        { items: ingredients },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setRecipe(response.data.recipe || "No recipe could be generated.");
    } catch (error) {
      console.error("Error generating recipe:", error);
      alert("Error generating recipe. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // TAKE ANOTHER PHOTO
  const handleTakeAnother = () => {
    setCapturedImage(null);
    setIngredients([]);
    setRecipe("");
  };
  //TOGGLE TORCH
  const toggleTorch = async () => {
    if (webcamRef.current) {
      const videoTrack = webcamRef.current.stream.getVideoTracks()[0];

      if (videoTrack && "applyConstraints" in videoTrack) {
        try {
          await videoTrack.applyConstraints({
            advanced: [{ torch: !torchOn }],
          });
          setTorchOn(!torchOn);
        } catch (error) {
          console.error("Error toggling torch:", error);
        }
      } else {
        console.warn("Torch feature not supported on this device.");
      }
    }
  };
  //TOGGLE VIEW
  const toggleFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  };

  return (
    <div className="page">
      <div className="productScanner objectDetector">
        <h3>Take a photo of your groceries</h3>
        <div className="videoContainer">
        {!capturedImage ? (
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              videoConstraints={videoConstraints}
            />
            <br/>
            <ButtonSecondary title={loading ? "Capturing..." : "Capture"} onClick={handleCapture} disabled={loading}/>
          </div>
        ) : (
          <div>
            <img src={capturedImage} alt="Captured Fridge" />
            <br/>
            <ButtonSecondary title="Take another" onClick={handleTakeAnother} disabled={loading}/>
          </div>
        )}
         <button
              aria-label="scannerFlashBtn"
              className="buttonScanner torch"
              onClick={toggleTorch}
            >
              {torchOn ? <PiLightningSlashBold /> : <PiLightningBold />}
          </button>
          <button
            aria-label="scannerToggleBtn"
            className="buttonScanner"
            onClick={toggleFacingMode}
          >
            <HiOutlineRefresh />
          </button>
        </div>

        {ingredients.length > 0 && (
          <div>
            <h3>Found ingredients</h3>
            <ol>
              {ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ol>
            <ButtonPrimary title={loading ? "Generating recipe..." : "Generate recipe"} onClick={handleGenerateRecipe} disabled={loading}/>
          </div>
        )}
        {recipe && (
          <div>
            <h3>Generated recipe</h3>
            <p>{recipe}</p>
            <ButtonTertiary title="Generate another one"/>
          </div>
        )}
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  );
};

export default FridgeRecipeGenerator;
