export const dataIngredients = [
  {
    id: "11001",
    value: "ulei de floarea soarelui",
    name: "Ulei de floarea soarelui",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11002",
    value: "ulei de masline",
    name: "Ulei de masline",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11003",
    value: "ulei de arahide",
    name: "Ulei de arahide",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11004",
    value: "ulei de cocos",
    name: "Ulei de cocos",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11005",
    value: "ulei de migdale",
    name: "Ulei de migdale",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11006",
    value: "ulei de nuci",
    name: "Ulei de nuci",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11007",
    value: "ulei de orez",
    name: "Ulei de orez",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11008",
    value: "ulei de palmier",
    name: "Ulei de palmier",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11009",
    value: "ulei de porumb",
    name: "Ulei de porumb",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11010",
    value: "ulei de susan",
    name: "Ulei de susan",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11011",
    value: "ulei din samburi de struguri",
    name: "Ulei din samburi de struguri",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11012",
    value: "ulei rapita",
    name: "Ulei rapita",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11013",
    value: "otet de orez",
    name: "Otet de orez",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11014",
    value: "otet de mere",
    name: "Otet de mere",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11015",
    value: "otet balsamic",
    name: "Otet balsamic",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "11016",
    value: "otet de vin",
    name: "Otet de vin",
    type: "Ulei si otet",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/oil.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12001",
    value: "nuca de cocos fulgi",
    name: "Nuca de cocos fulgi",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12002",
    value: "nuca de cocos proaspata",
    name: "Nuca de cocos proaspata",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12003",
    value: "nuci",
    name: "Nuci",
    type: "Nuci",
    // type: "Oleaginoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsWalnuts.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12004",
    value: "arahide",
    name: "Arahide",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12005",
    value: "alune",
    name: "Alune",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsPeanuts.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12006",
    value: "caju",
    name: "Caju",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsCashew.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12007",
    value: "nuci braziliene",
    name: "Nuci braziliene",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsBrazil.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12008",
    value: "nuci pecan",
    name: "Nuci pecan",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsPecans.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12009",
    value: "fistic",
    name: "Fistic",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsPistachio.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12010",
    value: "nuci de macadamia",
    name: "Nuci de macadamia",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsMacadamia.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12011",
    value: "alune de padure",
    name: "Alune de padure",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsHazelnuts.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12012",
    value: "nuci de pin",
    name: "Nuci de pin",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsPine.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "12013",
    value: "castane",
    name: "Castane",
    type: "Nuci",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/nutsWalnuts.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13001",
    value: "seminte de chia",
    name: "Seminte de chia",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13002",
    value: "arpacas",
    name: "Arpacas",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13003",
    value: "orez",
    name: "Orez",
    type: "Seminte",
    calories: "123",
    carbohydrates: "26",
    protein: "2.9",
    fat: "0.4",
    fiber: "0.9",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13004",
    value: "naut",
    name: "Naut",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13005",
    value: "linte",
    name: "Linte",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13006",
    value: "seminte de in",
    name: "Seminte de in",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13007",
    value: "seminte de psylium",
    name: "Seminte de Psylium",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13008",
    value: "seminte de dovleac ",
    name: "Seminte de dovleac ",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13009",
    value: "seminte de floarea soarelui",
    name: "Seminte de floarea soarelui",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13010",
    value: "seminte de ",
    name: "Seminte de ",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13011",
    value: "chimen",
    name: "Chimen",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13012",
    value: "susan",
    name: "Susan",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13013",
    value: "mac",
    name: "Mac",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13014",
    value: "germeni de grau",
    name: "Germeni de grau",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13015",
    value: "seminte de canepa",
    name: "Seminte de canepa",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13016",
    value: "miez de caise",
    name: "Miez de caise",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "13017",
    value: "hrisca",
    name: "Hrisca",
    type: "Seminte",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14001",
    value: "bicarbonat de sodiu",
    name: "Bicarbonat de sodiu",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14002",
    value: "colorant alimentar verde",
    name: "Colorant alimentar verde",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14003",
    value: "colorant alimentar rosu",
    name: "Colorant alimentar rosu",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14004",
    value: "colorant alimentar galben",
    name: "Colorant alimentar galben",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14005",
    value: "colorant alimentar mov",
    name: "Colorant alimentar mov",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14006",
    value: "praf de copt",
    name: "Praf de copt",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14007",
    value: "gelfix",
    name: "Gelfix",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "14008",
    value: "amidon",
    name: "Amidon",
    type: "Aditivi",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01038",
    value: "busuioc proaspat",
    name: "Busuioc proaspat",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01039",
    value: "chilli",
    name: "Chilli",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01040",
    value: "chivas",
    name: "Chivas",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01041",
    value: "cimbru proaspat",
    name: "Cimbru proaspat",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01042",
    value: "cimbru uscat",
    name: "Cimbru uscat",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01043",
    value: "coriandru",
    name: "Coriandru",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01044",
    value: "cuisoare",
    name: "Cuisoare",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01045",
    value: "esenta de lamaie",
    name: "Esenta de lamaie",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01046",
    value: "esenta de migdale",
    name: "Esenta de migdale",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01047",
    value: "esenta de rom",
    name: "Esenta de rom",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01048",
    value: "esenta de vanilie",
    name: "Esenta de vanilie",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01049",
    value: "ghimbir",
    name: "Ghimbir",
    type: "Condimente",
    calories: "80",
    carbohydrates: "17.8",
    protein: "1.8",
    fat: "0.8",
    fiber: "2",
    salt: "0",
    sugars: "0",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01050",
    value: "leustean",
    name: "Leustean",
    type: "Condimente",
    calories: "37",
    carbohydrates: "6.4",
    protein: "3",
    fat: "0.8",
    fiber: "2",
    salt: "0",
    sugars: "0",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01051",
    value: "marar",
    name: "Marar",
    type: "Condimente",
    calories: "43",
    carbohydrates: "7",
    protein: "3",
    fat: "1.1",
    fiber: "2",
    salt: "0",
    sugars: "0",
    asset: "/assets/ingredients/dill.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01052",
    value: "oregano",
    name: "Oregano",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01053",
    value: "oregano proaspat",
    name: "Oregano proaspat",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01054",
    value: "patrunjel",
    name: "Patrunjel",
    type: "Condimente",
    calories: "36",
    carbohydrates: "6.3",
    protein: "3",
    fat: "0.8",
    fiber: "3.3",
    salt: "0",
    sugars: "0",
    asset: "/assets/ingredients/parsley.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01055",
    value: "scortisoara",
    name: "Scortisoara",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01056",
    value: "usturoi",
    name: "Usturoi",
    type: "Condimente",
    calories: "149",
    carbohydrates: "33",
    protein: "6",
    fat: "0.51",
    fiber: "2",
    salt: "16.9",
    sugars: "0",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01057",
    value: "usturoi verde",
    name: "Usturoi verde",
    type: "Condimente",
    calories: "30",
    carbohydrates: "6",
    protein: "2",
    fat: "0",
    fiber: "0",
    salt: "0",
    sugars: "0",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01058",
    value: "zahar",
    name: "Zahar",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01059",
    value: "zahar  brun",
    name: "Zahar  brun",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01060",
    value: "zahar pudra",
    name: "Zahar pudra",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01061",
    value: "pul biber",
    name: "Pul biber",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01062",
    value: "sare de lamaie",
    name: "Sare de lamaie",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "01063",
    value: "sare ",
    name: "Sare ",
    type: "Condimente",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02076",
    value: "afine",
    name: "Afine",
    type: "Fructe",
    calories: "2",
    carbohydrates: "",
    protein: "10",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/blueberry.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02077",
    value: "ananas",
    name: "Ananas",
    type: "Fructe",
    calories: "83",
    carbohydrates: "",
    protein: "1",
    fat: "1",
    fiber: "2.3",
    salt: "",
    sugars: "16.3",
    asset: "/assets/ingredients/pineapple.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02078",
    value: "avocado",
    name: "Avocado",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/avocado.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02079",
    value: "banane",
    name: "Banane",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/banana.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02080",
    value: "caise",
    name: "Caise",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02081",
    value: "caise deshidratate",
    name: "Caise deshidratate",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02082",
    value: "capsune",
    name: "Capsune",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02083",
    value: "catina",
    name: "Catina",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02084",
    value: "cirese",
    name: "Cirese",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02085",
    value: "cirese ",
    name: "Cirese ",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02086",
    value: "cirese amare",
    name: "Cirese amare",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02087",
    value: "cirese confiate",
    name: "Cirese confiate",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02088",
    value: "clementine",
    name: "Clementine",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02089",
    value: "coacaze",
    name: "Coacaze",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02090",
    value: "coaja de lamaie",
    name: "Coaja de lamaie",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02091",
    value: "coaja de portocale",
    name: "Coaja de portocale",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02092",
    value: "curmale",
    name: "Curmale",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02093",
    value: "goji",
    name: "Goji",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02094",
    value: "grapefruit alb",
    name: "Grapefruit alb",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02095",
    value: "grapefruit rosu",
    name: "Grapefruit rosu",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02096",
    value: "gutui",
    name: "Gutui",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02097",
    value: "kiwi",
    name: "Kiwi",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02098",
    value: "lamai",
    name: "Lamai",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02099",
    value: "lime",
    name: "Lime",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02100",
    value: "mandarine",
    name: "Mandarine",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02101",
    value: "mango ",
    name: "Mango ",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02102",
    value: "mango deshidratat",
    name: "Mango deshidratat",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02103",
    value: "mere bot de iepure",
    name: "Mere bot de iepure",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/apple.gif",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02104",
    value: "mere golden",
    name: "Mere golden",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02105",
    value: "mere idared",
    name: "Mere idared",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02106",
    value: "mere ionatan",
    name: "Mere ionatan",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/apple.gif",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02107",
    value: "mere rosii",
    name: "Mere rosii",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/apple.gif",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02108",
    value: "mure",
    name: "Mure",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/blackberry.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02109",
    value: "papaya",
    name: "Papaya",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02110",
    value: "pepene galben",
    name: "Pepene galben",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02111",
    value: "pere ",
    name: "Pere ",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02112",
    value: "piersici",
    name: "Piersici",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02113",
    value: "pomelo",
    name: "Pomelo",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02114",
    value: "portocale",
    name: "Portocale",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02115",
    value: "portocale rosii",
    name: "Portocale rosii",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02116",
    value: "prune",
    name: "Prune",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02117",
    value: "prune deshidratate",
    name: "Prune deshidratate",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02118",
    value: "smochine",
    name: "Smochine",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02119",
    value: "smochine deshidratate",
    name: "Smochine deshidratate",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02120",
    value: "stafide",
    name: "Stafide",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02121",
    value: "struguri  albi fara samburi",
    name: "Struguri  albi fara samburi",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02122",
    value: "struguri albi",
    name: "Struguri albi",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "02123",
    value: "zmeura",
    name: "Zmeura",
    type: "Fructe",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/raspberry.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03162",
    value: "masline negre ",
    name: "Masline negre ",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/olives.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03163",
    value: "masline negre fara samburi",
    name: "Masline negre fara samburi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/olives.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03164",
    value: "brocoli",
    name: "Brocoli",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/broccoli.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03165",
    value: "ciuperci champignon brune",
    name: "Ciuperci champignon brune",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/mushrooms.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03166",
    value: "morcovi",
    name: "Morcovi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/carrots.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03167",
    value: "telina frunze",
    name: "Telina frunze",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/celeryLeafs.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03168",
    value: "telina radacina",
    name: "Telina radacina",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/celeryRoot.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03169",
    value: "ciuperci champignon",
    name: "Ciuperci champignon",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/mushrooms.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03170",
    value: "rosii cherry",
    name: "Rosii cherry",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/tomato.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03172",
    value: "conopida",
    name: "Conopida",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03173",
    value: "castraveti",
    name: "Castraveti",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03174",
    value: "vinete",
    name: "Vinete",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03175",
    value: "masline verzi",
    name: "Masline verzi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/olives.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03176",
    value: "masline verzi fara samburi",
    name: "Masline verzi fara samburi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/olives.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03177",
    value: "masline kalamata",
    name: "Masline kalamata",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/olives.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03178",
    value: "masline kalamata fara samburi",
    name: "Masline kalamata fara samburi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/olives.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03179",
    value: "ardei kapia",
    name: "Ardei kapia",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/pepperKapia.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03180",
    value: "gulii",
    name: "Gulii",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03181",
    value: "praz",
    name: "Praz",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03183",
    value: "ciuperci",
    name: "Ciuperci",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/mushrooms.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03184",
    value: "cartofi noi",
    name: "Cartofi noi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/potatoWhite.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03185",
    value: "ceapa",
    name: "Ceapa",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/onionsYellow.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03186",
    value: "ciuperci pleurotus",
    name: "Ciuperci pleurotus",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/mushrooms.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03187",
    value: "patrunjel frunze",
    name: "Patrunjel frunze",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03188",
    value: "patrunjel radacina",
    name: "Patrunjel radacina",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03189",
    value: "pastarnac",
    name: "Pastarnac",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03190",
    value: "ardei gras",
    name: "Ardei gras",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/redPeppers.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03191",
    value: "gogosari",
    name: "Gogosari",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03192",
    value: "cartofi",
    name: "Cartofi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/potatoWhite.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03193",
    value: "dovleac",
    name: "Dovleac",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03194",
    value: "dovlecei zuccihini",
    name: "Dovlecei zuccihini",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03195",
    value: "dovlecei",
    name: "Dovlecei",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03196",
    value: "varza rosie",
    name: "Varza rosie",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cabbageRed.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03197",
    value: "rosii cuburi in conserva",
    name: "Rosii cuburi in conserva",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/tomato.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03198",
    value: "rosii uscate la soare",
    name: "Rosii uscate la soare",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/tomato.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03199",
    value: "cepa rosie ",
    name: "Cepa rosie ",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03200",
    value: "cartofi rosii",
    name: "Cartofi rosii",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/potatoRed.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03201",
    value: "ridichi rosii",
    name: "Ridichi rosii",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03202",
    value: "cartofi dulci",
    name: "Cartofi dulci",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/potatoSweet.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03203",
    value: "sos de rosii",
    name: "Sos de rosii",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03204",
    value: "varza alba",
    name: "Varza alba",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cabbageWhite.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03205",
    value: "ceapa alba",
    name: "Ceapa alba",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/onionsYellow.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03206",
    value: "cartofi albi",
    name: "Cartofi albi",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/potatoWhite.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03207",
    value: "rosii intregi in conserva ",
    name: "Rosii intregi in conserva ",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/tomato.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03208",
    value: "fasole conserva",
    name: "Fasole conserva",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "03209",
    value: "mazare ",
    name: "Mazare ",
    type: "Legume",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04061",
    value: "bulgur",
    name: "Bulgur",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04062",
    value: "faina de grau",
    name: "Faina de grau",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04063",
    value: "faina de hrisca",
    name: "Faina de hrisca",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04064",
    value: "faina de naut",
    name: "Faina de naut",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04065",
    value: "faina de orez",
    name: "Faina de orez",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04066",
    value: "faina de secara",
    name: "Faina de secara",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04067",
    value: "faina integrala",
    name: "Faina integrala",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04068",
    value: "faina integrala de hrisca",
    name: "Faina integrala de hrisca",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04069",
    value: "gris",
    name: "Gris",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04070",
    value: "malai",
    name: "Malai",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04071",
    value: "malai grisat",
    name: "Malai grisat",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04072",
    value: "mei",
    name: "Mei",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04073",
    value: "mei decorticat",
    name: "Mei decorticat",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04074",
    value: "pesmet",
    name: "Pesmet",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/flour.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04075",
    value: "tarate",
    name: "Tarate",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04076",
    value: "spaghete uscate",
    name: "Spaghete uscate",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04077",
    value: "foi de lasagna ",
    name: "Foi de lasagna ",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05078",
    value: "fussili uscate",
    name: "Fussili uscate",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04079",
    value: "pene",
    name: "Pene",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06080",
    value: "tagliatele uscate",
    name: "Tagliatele uscate",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04081",
    value: "paste - scoici",
    name: "Paste - scoici",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "07082",
    value: "taitei",
    name: "Taitei",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04083",
    value: "taitei de orez",
    name: "Taitei de orez",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08084",
    value: "spaghete de orez",
    name: "Spaghete de orez",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "04085",
    value: "couscous",
    name: "Couscous",
    type: "Fainoase",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05001",
    value: "bacon",
    name: "Bacon",
    type: "Carne",
    calories: "183",
    carbohydrates: "0.2",
    protein: "20.9",
    fat: "10.9",
    fiber: "0",
    salt: "11",
    sugars: "0",
    asset: "/assets/ingredients/bacon.jpg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05003",
    value: "burta de vita",
    name: "Burta de vita",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05004",
    value: "carne tocata vita-oaie",
    name: "Carne tocata vita-oaie",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/meatMinced.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05005",
    value: "carne tocata vita-porc",
    name: "Carne tocata vita-porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05006",
    value: "carne tocata de curcan",
    name: "Carne tocata de curcan",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05007",
    value: "carne tocata de porc",
    name: "Carne tocata de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05008",
    value: "carne tocata de pui",
    name: "Carne tocata de pui",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05009",
    value: "carne tocata de vita",
    name: "Carne tocata de vita",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05010",
    value: "coaste de berbecut",
    name: "Coaste de berbecut",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05011",
    value: "coaste porc",
    name: "Coaste porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05012",
    value: "cotlet de porc",
    name: "Cotlet de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05013",
    value: "cotlet de vitel ",
    name: "Cotlet de vitel ",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05014",
    value: "creier de porc",
    name: "Creier de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05015",
    value: "ficat de berbecut",
    name: "Ficat de berbecut",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05016",
    value: "ficat de oaie",
    name: "Ficat de oaie",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05017",
    value: "ficat de porc",
    name: "Ficat de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05018",
    value: "ficat de pui",
    name: "Ficat de pui",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05019",
    value: "ficat de vita",
    name: "Ficat de vita",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05020",
    value: "fleica",
    name: "Fleica",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05021",
    value: "inima de porc",
    name: "Inima de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05022",
    value: "muschi de vita",
    name: "Muschi de vita",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05023",
    value: "muschiulet de porc",
    name: "Muschiulet de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05024",
    value: "muschiulet de pui",
    name: "Muschiulet de pui",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05025",
    value: "pamani de porc",
    name: "Pamani de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05026",
    value: "piept de porc ",
    name: "Piept de porc ",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05027",
    value: "piept de pui",
    name: "Piept de pui",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05028",
    value: "pui grill",
    name: "Pui grill",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05029",
    value: "pui intreg",
    name: "Pui intreg",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05030",
    value: "pulpa de porc",
    name: "Pulpa de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05031",
    value: "pulpa de vita",
    name: "Pulpa de vita",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05032",
    value: "pulpe dezosate  de pui",
    name: "Pulpe dezosate  de pui",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05033",
    value: "pulpe inferioare de pui",
    name: "Pulpe inferioare de pui",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05034",
    value: "pulpe superioare de pui ",
    name: "Pulpe superioare de pui ",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05035",
    value: "rasol de porc",
    name: "Rasol de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05036",
    value: "rasol de vita",
    name: "Rasol de vita",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05037",
    value: "rinichi de porc",
    name: "Rinichi de porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "05038",
    value: "spata porc",
    name: "Spata porc",
    type: "Carne",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06124",
    value: "unt 65% ",
    name: "Unt 65% ",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06139",
    value: "lapte de vaca",
    name: "Lapte de vaca",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06125",
    value: "lapte de migdale",
    name: "Lapte de migdale",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06126",
    value: "lapte de bivolita",
    name: "Lapte de bivolita",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06127",
    value: "telemea de bivolita",
    name: "Telemea de bivolita",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06128",
    value: "iaurt de  bivolita",
    name: "Iaurt de  bivolita",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06129",
    value: "branza cu mucegai",
    name: "Branza cu mucegai",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06130",
    value: "branza brie",
    name: "Branza brie",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06131",
    value: "unt",
    name: "Unt",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06132",
    value: "unt 85%",
    name: "Unt 85%",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06133",
    value: "lapte batut",
    name: "Lapte batut",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06134",
    value: "camembert",
    name: "Camembert",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06135",
    value: "telemea",
    name: "Telemea",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06136",
    value: "crema de branza",
    name: "Crema de branza",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06137",
    value: "lapte de cocos",
    name: "Lapte de cocos",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06138",
    value: "lapte condensat",
    name: "Lapte condensat",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06140",
    value: "cascaval dalia",
    name: "Cascaval dalia",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cascaval.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06141",
    value: "cascaval emantaler",
    name: "Cascaval emantaler",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cascaval.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06142",
    value: "branza feta de  vaca",
    name: "Branza feta de  vaca",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cheese.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06143",
    value: "branza  proaspata",
    name: "Branza  proaspata",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cheese.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06144",
    value: "lapte de capra",
    name: "Lapte de capra",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06145",
    value: "iaurt de capra",
    name: "Iaurt de capra",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06146",
    value: "gorgonzola",
    name: "Gorgonzola",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06147",
    value: "iaurt grecesc",
    name: "Iaurt grecesc",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06148",
    value: "kefir",
    name: "Kefir",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06149",
    value: "smantana lichida",
    name: "Smantana lichida",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06150",
    value: "mascarpone",
    name: "Mascarpone",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06151",
    value: "mozzarella",
    name: "Mozzarella",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06152",
    value: "parmezan",
    name: "Parmezan",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06153",
    value: "lapte praf",
    name: "Lapte praf",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/milk.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06154",
    value: "ricotta",
    name: "Ricotta",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06155",
    value: "cascaval rucar",
    name: "Cascaval rucar",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/cascaval.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06156",
    value: "unt sarat",
    name: "Unt sarat",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06157",
    value: "sana",
    name: "Sana",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06158",
    value: "smantana",
    name: "Smantana",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06159",
    value: "telemea de capra",
    name: "Telemea de capra",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06160",
    value: "unt nesarat",
    name: "Unt nesarat",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "06161",
    value: "iaurt",
    name: "Iaurt",
    type: "Lactate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "07208",
    value: "oua de gaina",
    name: "Oua de gaina",
    type: "Oua",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/ingredients/eggs.png",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "07209",
    value: "oua de prepelita",
    name: "Oua de prepelita",
    type: "Oua",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "07210",
    value: "oua de rata",
    name: "Oua de rata",
    type: "Oua",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08211",
    value: "hamsie",
    name: "Hamsie",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08212",
    value: "baby hering",
    name: "Baby hering",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08213",
    value: "dorada ",
    name: "Dorada ",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08214",
    value: "crap",
    name: "Crap",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08215",
    value: "icre de crap",
    name: "Icre de crap",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08216",
    value: "hering ",
    name: "Hering ",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08217",
    value: "macrou",
    name: "Macrou",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08218",
    value: "macrou marinat",
    name: "Macrou marinat",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08219",
    value: "icre de stiuca",
    name: "Icre de stiuca",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08220",
    value: "somon",
    name: "Somon",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08221",
    value: "icre de somon",
    name: "Icre de somon",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08222",
    value: "somon file",
    name: "Somon file",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08223",
    value: "macrou afumat",
    name: "Macrou afumat",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08224",
    value: "somon afumat",
    name: "Somon afumat",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08225",
    value: "sprot",
    name: "Sprot",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08226",
    value: "pastrav tricolor",
    name: "Pastrav tricolor",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "08227",
    value: "pastrav",
    name: "Pastrav",
    type: "Peste",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09228",
    value: "baby spanac",
    name: "Baby spanac",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09230",
    value: "linte conserva",
    name: "Linte conserva",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09231",
    value: "porumb conserva",
    name: "Porumb conserva",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09232",
    value: "salata eisberg",
    name: "Salata eisberg",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09233",
    value: "salata verde",
    name: "Salata verde",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09234",
    value: "naut conserva",
    name: "Naut conserva",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09235",
    value: "salata rucola",
    name: "Salata rucola",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09236",
    value: "spanac",
    name: "Spanac",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09237",
    value: "spanac conserva",
    name: "Spanac conserva",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "09238",
    value: "salata valeriana",
    name: "Salata valeriana",
    type: "Salate",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "10239",
    value: "bulion",
    name: "Bulion",
    type: "Sosuri",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
  {
    id: "10240",
    value: "sos de soia",
    name: "Sos de soia",
    type: "Sosuri",
    calories: "",
    carbohydrates: "",
    protein: "",
    fat: "",
    fiber: "",
    salt: "",
    sugars: "",
    asset: "/assets/placeholders/ingredientPlaceholder.svg",
    quantity: "",
    unit: "",
    isDone: false,
    brand: "",
    brandType: "",
  },
];
