import React, { useState, useRef } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import "./Accordion.scss";

export function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordionIcon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordionIcon" : "accordionIcon rotate"
    );
  }

  return (
    <>
      <div className={`accordion ${setActive}`}>
        <span
          className={`accordionButton ${setActive}`}
          onClick={toggleAccordion}
        >
          {props.title}
          <HiOutlineChevronDown className={`accordionIcon ${setRotate}`} />
        </span>

        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="accordionContent"
        >
          {props.content}
        </div>
      </div>
    </>
  );
}
