import notificationsTypes from "./notifications.types";

export const addNotificationStart = (notificationData) => ({
  type: notificationsTypes.ADD_NEW_NOTIFICATION_START,
  payload: notificationData,
});
export const fetchNotificationsStart = (filters = {}) => ({
  type: notificationsTypes.FETCH_NOTIFICATIONS_START,
  payload: filters,
});

export const setNotifications = (notifications) => ({
  type: notificationsTypes.SET_NOTIFICATIONS,
  payload: notifications,
});

export const fetchNotificationStart = (notificationID) => ({
  type: notificationsTypes.FETCH_NOTIFICATION_START,
  payload: notificationID,
});

export const setNotification = (notification) => ({
  type: notificationsTypes.SET_NOTIFICATION,
  payload: notification,
});

export const deleteNotificationStart = (notificationID) => ({
  type: notificationsTypes.DELETE_NOTIFICATION_START,
  payload: notificationID,
});
