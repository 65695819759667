import commentsTypes from "./comments.types";

export const addCommentStart = (commentData) => ({
  type: commentsTypes.ADD_NEW_COMMENT_START,
  payload: commentData,
});

export const fetchCommentsStart = (filters = {}) => ({
  type: commentsTypes.FETCH_COMMENTS_START,
  payload: filters,
});

export const setComments = (comments) => ({
  type: commentsTypes.SET_COMMENTS,
  payload: comments,
});

export const deleteCommentStart = (commentID) => ({
  type: commentsTypes.DELETE_COMMENT_START,
  payload: commentID,
});

export const fetchCommentStart = (commentID) => ({
  type: commentsTypes.FETCH_COMMENT_START,
  payload: commentID,
});

export const setComment = (comment) => ({
  type: commentsTypes.SET_COMMENT,
  payload: comment,
});
