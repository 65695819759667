import advicesTypes from "./advices.types";

export const addAdviceStart = (adviceData) => ({
  type: advicesTypes.ADD_NEW_ADVICE_START,
  payload: adviceData,
});

export const fetchAdvicesStart = (filters = {}) => ({
  type: advicesTypes.FETCH_ADVICES_START,
  payload: filters,
});

export const setAdvices = (advices) => ({
  type: advicesTypes.SET_ADVICES,
  payload: advices,
});

export const deleteAdviceStart = (adviceID) => ({
  type: advicesTypes.DELETE_ADVICE_START,
  payload: adviceID,
});

export const fetchAdviceStart = (adviceID) => ({
  type: advicesTypes.FETCH_ADVICE_START,
  payload: adviceID,
});

export const setAdvice = (advice) => ({
  type: advicesTypes.SET_ADVICE,
  payload: advice,
});
