import usersTypes from "./users.types";
import userTypes from "./users.types";

const INITIAL_STATE = {
  users: [],
  user: {},
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case usersTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };


    default:
      return state;
  }
};

export default usersReducer;
