import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setFollowRequests,
  setFollowRequest,
  fetchFollowRequestsStart,
} from "./followRequests.actions";
import {
  handleAddFollowRequest,
  handleFetchFollowRequests,
  handleFetchFollowRequest,
  handleDeleteFollowRequest,
} from "./followRequests.helpers";
import followRequestsTypes from "./followRequests.types";

export function* addFollowRequest({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddFollowRequest({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchFollowRequestsStart());
  } catch (err) {}
}

export function* onAddFollowRequestStart() {
  yield takeLatest(
    followRequestsTypes.ADD_NEW_FOLLOW_REQUEST_START,
    addFollowRequest
  );
}

export function* fetchFollowRequests({ payload }) {
  try {
    const followRequests = yield handleFetchFollowRequests(payload);
    yield put(setFollowRequests(followRequests));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchFollowRequestsStart() {
  yield takeLatest(
    followRequestsTypes.FETCH_FOLLOW_REQUESTS_START,
    fetchFollowRequests
  );
}

export function* fetchFollowRequest({ payload }) {
  try {
    const followRequest = yield handleFetchFollowRequest(payload);
    yield put(setFollowRequest(followRequest));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchFollowRequestStart() {
  yield takeLatest(
    followRequestsTypes.FETCH_FOLLOW_REQUEST_START,
    fetchFollowRequest
  );
}

export function* onDeleteFollowRequestStart() {
  yield takeLatest(
    followRequestsTypes.DELETE_FOLLOW_REQUEST_START,
    deleteFollowRequest
  );
}

export function* deleteFollowRequest({ payload }) {
  try {
    yield handleDeleteFollowRequest(payload);
    yield put(fetchFollowRequestsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* followRequestsSagas() {
  yield all([
    call(onAddFollowRequestStart),
    call(onFetchFollowRequestsStart),
    call(onDeleteFollowRequestStart),
    call(onFetchFollowRequestStart),
  ]);
}
