import { firestore } from "../../firebase/utils";

export const handleAddComment = (comment) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("comments")
      .doc()
      .set(comment)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchComments = ({
  filterType,
  startAfterDoc,
  persistComments = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("comments")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("commentCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistComments,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteComment = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("comments")
      .doc(documentID)
      .delete()
      .then(() => {
        // console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchComment = (commentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("comments")
      .doc(commentID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: commentID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function searchComment() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("search");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}
