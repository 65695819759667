import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

// SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  InputSelect,
  InputTime,
  InputTextarea,
} from "../../components/formElements/Inputs";
import {
  ButtonSecondary,
  ButtonTertiary,
} from "../../components/formElements/Buttons";

export const BusinessBooking = ({
  phoneNumber,
  toggleModalBottom2,
  currentUser,
}) => {
  const getRomaniaTime = () => {
    const romaniaOffset = moment().isDST() ? 180 : 120;
    return moment().utcOffset(romaniaOffset).locale("ro").startOf("day");
  };

  useEffect(() => {
    const currentDateInRomania = moment()
      .utcOffset(moment().isDST() ? 180 : 120)
      .locale("ro");

    setSelectedDate(currentDateInRomania);
    setSelectedDay(currentDateInRomania);
  }, []);

  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(getRomaniaTime());
  const [selectedDay, setSelectedDay] = useState(getRomaniaTime());
  const [time, setTime] = useState("");
  const [bookingNotes, setBookingNotes] = useState("");
  const [peopleCount, setPeopleCount] = useState();

  const getWeeksArray = () => {
    const startOfWeek = moment()
      .utcOffset(moment().isDST() ? 180 : 120)
      .locale("ro")
      .startOf("isoWeek");

    const weeksArray = [];
    for (let week = 0; week < 5; week++) {
      const weekDays = [];
      for (let day = 0; day < 7; day++) {
        weekDays.push(
          startOfWeek
            .clone()
            .add(week * 7 + day, "days")
            .toDate()
        );
      }
      weeksArray.push(weekDays);
    }
    return weeksArray;
  };

  const weeksArray = useMemo(getWeeksArray, [selectedDate]);

  const handleDayClick = (day) => {
    const momentDay = moment(day);
    setSelectedDay(momentDay);
    setSelectedDate(momentDay);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handlePeopleCountChange = (event) => {
    setPeopleCount(event.target.value);
  };

  const sendMessageToWhatsApp = () => {
    const message = `${t("My appointment for")} ${peopleCount} ${t(
      "person(s) is set for"
    )} ${selectedDay.format("dddd, MMMM Do YYYY")} ${t("at")} ${time}, ${t(
      "on name"
    )} ${currentUser.displayName} ${
      bookingNotes ? `Notes: ${bookingNotes}` : null
    }`;
    const waUrl = `https://wa.me/+40${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(waUrl, "_blank");
    toggleModalBottom2();
  };

  const dayHeaders = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const isDaySelected = (day) => {
    return formatDate(day) === formatDate(selectedDay.toDate());
  };

  const isToday = (day) => {
    return formatDate(day) === formatDate(moment().toDate());
  };

  const isPastDay = (day) => {
    const formattedDay = moment(day).format("YYYY-MM-DD");
    const todayFormatted = moment().format("YYYY-MM-DD");
    return formattedDay < todayFormatted;
  };

  return (
    <div className="bookingBox">
      <h3>
        {t("Booking")}
        <br />
        <small>{selectedDate.format("dddd, Do MMM ")}</small>
      </h3>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        {dayHeaders.map((header, index) => (
          <div
            className="weekHeader"
            key={index}
            style={{
              width: "calc(14.28% - 4px)",
              textAlign: "center",
              margin: "2px",
            }}
          >
            <small>{t(`${header}`)}</small>
          </div>
        ))}
        <Swiper slidesPerView={1} className="weekSwiper">
          {weeksArray.map((week, index) => (
            <SwiperSlide key={index}>
              {week.map((day, dayIndex) => (
                <button
                  className="weekDay"
                  key={dayIndex}
                  onClick={() => handleDayClick(day)}
                  disabled={isPastDay(day)}
                  style={{
                    width: "calc(14.28% - 4px)",
                    height: "40px",
                    margin: "2px",
                    background: "transparent",
                    transition: ".35s",
                    borderRadius: "10px",
                    color: isDaySelected(day)
                      ? "#FF6900"
                      : "#999" && isToday(day)
                      ? "#333"
                      : "#999",
                    border: isDaySelected(day)
                      ? "2px solid #FF6900"
                      : "2px solid transparent" && isToday(day)
                      ? "2px solid #eee"
                      : "2px solid transparent",
                  }}
                >
                  {day.getDate()}
                </button>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <br />

      <div className="flexBox">
        <InputTime
          id="time"
          label="Time"
          value={time}
          handleChange={handleTimeChange}
        />
        <InputSelect
          id="guests"
          label={t("Guests")}
          name="Guests selector"
          // value={peopleCount}
          handleChange={handlePeopleCountChange}
          required
          options={[
            {
              value: "",
              name: `${t("Choose category")}`,
            },
            {
              value: 1,
              name: "1",
            },
            {
              value: 2,
              name: "2",
            },
            {
              value: 3,
              name: "3",
            },
            {
              value: 4,
              name: "4",
            },
            {
              value: 5,
              name: "5",
            },
            {
              value: 6,
              name: "6",
            },
            {
              value: 7,
              name: "7",
            },
            {
              value: 8,
              name: "8",
            },
            {
              value: 9,
              name: "9",
            },
            {
              value: 10,
              name: "10",
            },
          ]}
        />
      </div>
      <InputTextarea
        label={t("Notes")}
        handleChange={(e) => setBookingNotes(e.target.value)}
      />
      <ButtonSecondary
        title={t("Book")}
        onClick={sendMessageToWhatsApp}
        disabled={!selectedDay || !peopleCount === 0 || !time}
      />
      <ButtonTertiary
        title={t("Cancel")}
        onClick={toggleModalBottom2 || setTime(null) || setPeopleCount(0)}
      />
      <br />
    </div>
  );
};
