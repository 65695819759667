import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setNutritions,
  setNutrition,
  fetchNutritionsStart,
} from "./nutritions.actions";
import {
  handleAddNutrition,
  handleFetchNutritions,
  handleFetchNutrition,
  handleDeleteNutrition,
} from "./nutritions.helpers";
import nutritionsTypes from "./nutritions.types";

export function* addNutrition({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddNutrition({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchNutritionsStart());
  } catch (err) {}
}

export function* onAddNutritionStart() {
  yield takeLatest(nutritionsTypes.ADD_NEW_NUTRITION_START, addNutrition);
}

export function* fetchNutritions({ payload }) {
  try {
    const nutritions = yield handleFetchNutritions(payload);
    yield put(setNutritions(nutritions));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchNutritionsStart() {
  yield takeLatest(nutritionsTypes.FETCH_NUTRITIONS_START, fetchNutritions);
}

export function* fetchNutrition({ payload }) {
  try {
    const nutrition = yield handleFetchNutrition(payload);
    yield put(setNutrition(nutrition));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchNutritionStart() {
  yield takeLatest(nutritionsTypes.FETCH_NUTRITION_START, fetchNutrition);
}

export function* onDeleteNutritionStart() {
  yield takeLatest(nutritionsTypes.DELETE_NUTRITION_START, deleteNutrition);
}

export function* deleteNutrition({ payload }) {
  try {
    yield handleDeleteNutrition(payload);
    yield put(fetchNutritionsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* nutritionsSagas() {
  yield all([
    call(onAddNutritionStart),
    call(onFetchNutritionsStart),
    call(onDeleteNutritionStart),
    call(onFetchNutritionStart),
  ]);
}
