import { firestore } from "../../firebase/utils";

export const handleAddFollowRequest = (followRequest) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRequests")
      .doc()
      .set(followRequest)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchFollowRequests = ({
  filterType,
  startAfterDoc,
  persistFollowRequests = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("followRequests")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("followRequestCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistFollowRequests,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteFollowRequest = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRequests")
      .doc(documentID)
      .delete()
      .then(() => {
        // console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchFollowRequest = (followRequestID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("followRequests")
      .doc(followRequestID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: followRequestID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
