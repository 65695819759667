const menusTypes = {
  ADD_NEW_MENU_START: "ADD_NEW_MENU_START",
  FETCH_MENUS_START: "FETCH_MENUS_START",
  SET_MENUS: "SET_MENUS",
  DELETE_MENU_START: "DELETE_MENU_START",
  FETCH_MENU_START: "FETCH_MENU_START",
  SET_MENU: "SET_MENU",
  UPDATE_MENU_START: "UPDATE_MENU_START",
  UPDATE_MENU_SUCCESS: "UPDATE_MENU_SUCCESS",

  REMOVE_MENU_ITEM_START: "REMOVE_MENU_ITEM_START",
  REMOVE_MENU_ITEM_SUCCESS: "REMOVE_MENU_ITEM_SUCCESS",
};

export default menusTypes;
