const followRequestsTypes = {
  ADD_NEW_FOLLOW_REQUEST_START: "ADD_NEW_FOLLOW_REQUEST_START",
  FETCH_FOLLOW_REQUESTS_START: "FETCH_FOLLOW_REQUESTS_START",
  SET_FOLLOW_REQUESTS: "SET_FOLLOW_REQUESTS",
  DELETE_FOLLOW_REQUEST_START: "DELETE_FOLLOW_REQUEST_START",
  FETCH_FOLLOW_REQUEST_START: "FETCH_FOLLOW_REQUEST_START",
  SET_FOLLOW_REQUEST: "SET_FOLLOW_REQUEST",
};

export default followRequestsTypes;
