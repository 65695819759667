import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsStart } from "../redux/notifications/notifications.actions";
import { fetchFollowRequestsStart } from "../redux/followRequests/followRequests.actions";
import { createSelector } from 'reselect';
import { useTranslation } from "react-i18next";

const currentUserSelector = state => state.user.currentUser;
const usersSelector = state => state.usersData.users;
const listsSelector = state => state.listsData.lists;
const notificationsSelector = state => state.notificationsData.notifications;
const notificationSelector = state => state.notificationsData.notification;
const followRequestsSelector = state => state.followRequestsData.followRequests;

const mapState = createSelector(
  [
    currentUserSelector,
    usersSelector,
    listsSelector,
    notificationsSelector,
    notificationSelector,
    followRequestsSelector
  ],
  (currentUser, users, lists, notifications, notification, followRequests) => ({
    currentUser,
    users,
    lists,
    notifications,
    notification,
    followRequests
  })
);

//FOR TOP NAV
export const NotificationsCounter = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const { currentUser, notifications, followRequests } = useSelector(mapState);
  const { data: notificationsData } = notifications;
  const { data: followRequestsData } = followRequests;


    useEffect(() => {
      if (currentUser.id) {
        dispatch(fetchNotificationsStart(currentUser.id));
        dispatch(fetchFollowRequestsStart(currentUser.id));
      }
    }, [currentUser.id, dispatch]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const notificationCount = notificationsData
        ? notificationsData.filter(
            (notification) =>
              notification.receiverID === currentUser.id &&
              !notification.isViewed
          ).length
        : 0;

      const followRequestCount = followRequestsData
        ? followRequestsData.filter(
            (request) =>
              request.followingID === currentUser.id && !request.isViewed
          ).length
        : 0;

      setCount(notificationCount + followRequestCount);
    }
  }, [currentUser, notificationsData, followRequestsData]);

  if (count === 0) {
    return null; 
  }

  return (
    <span className="notificationBullet">
     {/* {count} */}
    </span>
  );
};

//FOR BOTTOM NAV
export function ListQuantity() {
  const {t} = useTranslation();
  const { currentUser, lists } = useSelector(mapState);
  const { data: listsData } = lists;

  if (!currentUser) return null;

  const userLists = listsData && listsData.filter((myList) => myList.userID === currentUser.id);

  const ingredientCount = userLists && userLists.reduce((acc, myList) => {
    return acc + (myList.ingredients ? myList.ingredients.length : 0);
  }, 0);

  if (ingredientCount < 1) return (
    <div className="emptyShoppingList">
      <span>
      <img src="/assets/icons/discount.webp" alt="Listeat" className="headerImage"/>
      <h4>{t("Shopping list is empty")}</h4>
      </span>
    </div>
    )
    ;

  return (
    <>
      <button className="listQuantityButton">
        <span>{ingredientCount}</span>
      </button>
    </>
  );
}