import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkUserBusinessAccount,
  checkUserCreatorAccount,
  checkUserIsAdmin,
  checkUserProAccount,
} from "./userRoles";
import { createSelector } from "reselect";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

export function useAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/auth");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

function useAdminAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserIsAdmin(currentUser)) {
      navigate("/permission-restricted");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

function useProAccount(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserProAccount(currentUser)) {
      navigate("/permission-restricted");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

function useBusinessAccount(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserBusinessAccount(currentUser)) {
      navigate("/permission-restricted");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

function useCreatorAccount(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserCreatorAccount(currentUser)) {
      navigate("/permission-restricted");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

export const WithAuth = (props) => useAuth(props) && props.children;
export const WithAdminAuth = (props) => useAdminAuth(props) && props.children;
export const WithProAccount = (props) => useProAccount(props) && props.children;
export const WithCreatorAccount = (props) =>
  useCreatorAccount(props) && props.children;
export const WithBusinessAccount = (props) =>
  useBusinessAccount(props) && props.children;
