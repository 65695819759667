import chatsTypes from "./chats.types";
import chatTypes from "./chats.types";

const INITIAL_STATE = {
  chats: [],
  chat: {},
};

const chatsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case chatTypes.SET_CHATS:
      return {
        ...state,
        chats: action.payload,
      };
    case chatsTypes.SET_CHAT:
      return {
        ...state,
        chat: action.payload,
      };
    case `${chatsTypes.GET_REALTIME_USERS}_REQUEST`:
      break;
    case `${chatsTypes.GET_REALTIME_USERS}_SUCCESS`:
      state = {
        ...state,
        users: action.payload.users,
      };
      break;
    case chatsTypes.GET_REALTIME_MESSAGES:
      state = {
        ...state,
        chats: action.payload.chats,
      };
      break;
    case `${chatsTypes.GET_REALTIME_MESSAGES}_FAILURE`:
      state = {
        ...state,
        chats: [],
      };
      break
    default:
      return state;
  }
};

export default chatsReducer;
