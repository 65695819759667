import React, { useState } from "react";
import {
  HiOutlineExclamationCircle,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineSearch,
} from "react-icons/hi";
import "./formElements.scss";

export function InputText({ id, handleChange, label, name, ...otherProps }) {
  return (
    <div className="input">
      <input
        type="text"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputTextIcon({
  id,
  handleChange,
  label,
  icon,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <span className="inputTextIcon">{icon}</span>
      <input
        type="text"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputTextRequired({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <input
        type="text"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      <HiOutlineExclamationCircle className="inputRequiredIcon" />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputFile({
  id,
  handleChange,
  label,
  name,
  hiddenInput,
  ...otherProps
}) {
  return (
    <div className="input">
      <input
        type="file"
        id={id}
        name={name}
        className={!hiddenInput ? "inputText" : "inputText hidden"}
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label
          htmlFor={id}
          className={`inputLabel ${hiddenInput ? "labelImage" : ""}`}
        >
          {label}
        </label>
      )}
    </div>
  );
}
export function InputPassword({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  const [visible, setVisibility] = useState(false);
  const password = visible ? "text" : "password";

  return (
    <>
      <div className="inputPassword">
        <input
          type={password}
          id={id}
          name={name}
          className="inputText"
          onChange={handleChange}
          {...otherProps}
          required
        />
        {visible ? (
          <HiOutlineEye
            color="#FF6900"
            onClick={() => setVisibility((visibility) => !visibility)}
          />
        ) : (
          <HiOutlineEyeOff
            onClick={() => setVisibility((visibility) => !visibility)}
          />
        )}
        {label && (
          <label htmlFor={id} className="inputLabel">
            {label}
          </label>
        )}
      </div>
    </>
  );
}
export function InputPasswordHidden({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <>
      <div className="inputPassword">
        <input
          type="password"
          id={id}
          name={name}
          className="inputText"
          onChange={handleChange}
          {...otherProps}
          required
        />
        {label && (
          <label htmlFor={id} className="inputLabel">
            {label}
          </label>
        )}
      </div>
    </>
  );
}
export function InputSearch({
  id,
  handleChange,
  label,
  name,
  hasScanner,
  hasFilter,
  ...otherProps
}) {
  return (
    <div className={`inputSearch ${hasFilter} ${hasScanner} `}>
      {label && (
        <label htmlFor="id" className="inputLabel">
          {label}
        </label>
      )}
      <HiOutlineSearch />
      <input
        type="search"
        id={id}
        name={name}
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
}
export function InputEmail({ id, handleChange, label, name, ...otherProps }) {
  return (
    <div className="input">
      <input
        type="email"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
        required
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputPhone({
  id,
  handleChange,
  handleChangePrefix,
  label,
  name,
  prefix,
  ...otherProps
}) {
  return (
    <div className="input phone">
      <InputSelect
        id="phonePrefix"
        name="Phone prefix"
        required
        value={prefix}
        onChange={handleChangePrefix}
        options={[
          {
            value: "",
            name: "--",
          },
          {
            value: "RO +40",
            name: "RO +40",
          },
          {
            value: "UK +44",
            name: "UK +44",
          },
        ]}
      />
      <input
        type="number"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
        required
        minLength="10"
        maxLength="10"
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputDate({ id, handleChange, label, name, ...otherProps }) {
  return (
    <div className="input">
      <input
        id={id}
        type="date"
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputTime({ id, handleChange, label, name, ...otherProps }) {
  return (
    <div className="input">
      <input
        id={id}
        type="time"
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputColor({ handleChange, label, name, ...otherProps }) {
  return (
    <>
      {label && <label>{label}</label>}
      <div className="inputColor">
        <input
          type="color"
          name={name}
          required
          onChange={handleChange}
          {...otherProps}
        />
      </div>
    </>
  );
}
export function InputTextarea({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <textarea id={id} onChange={handleChange} name={name} {...otherProps} />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputTextareaRequired({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <textarea
        id={id}
        onChange={handleChange}
        name={name}
        {...otherProps}
        required
      />
      <HiOutlineExclamationCircle className="inputRequiredIcon" />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}

export function InputCheckbox({
  id,
  handleChange,
  label,
  name,
  checked,
  ...otherProps
}) {
  return (
    <div>
      <input
        type="checkbox"
        className={`inputCheckbox${checked ? " checked" : ""}`}
        onChange={handleChange}
        id={id}
        name={name}
        checked={checked}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id}>
          <span>
            <p>
              <small>{label}</small>
            </p>
          </span>
        </label>
      )}
    </div>
  );
}
export function InputCheckboxRound({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <>
      <input
        type="checkbox"
        className="inputCheckbox"
        onChange={handleChange}
        id={id}
        name={name}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id}>
          <span>
            <p>
              <small>{label}</small>
            </p>
          </span>
        </label>
      )}
    </>
  );
}
export function InputRange({ id, handleChange, label, name, ...otherProps }) {
  return (
    <>
      {label && <label>{label}</label>}

      <input
        type="range"
        name={name}
        id={id}
        className="inputRange"
        onChange={handleChange}
        {...otherProps}
      />
    </>
  );
}
export function InputRadio({
  id,
  handleChange,
  label,
  name,
  defaultChecked,
  ...otherProps
}) {
  return (
    <>
      <input
        type="radio"
        id={id}
        name={name}
        className="inputRange"
        onChange={handleChange}
        defaultChecked={defaultChecked}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id}>
          <span>
            <p>
              <small>{label}</small>
            </p>
          </span>
        </label>
      )}
    </>
  );
}
export function InputSelect({
  id,
  options,
  value,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  if (!Array.isArray(options) || options.length < 1) return null;

  return (
    <div className="input">
      <select
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
        {...otherProps}
      >
        {options.map((option, index) => {
          const { value, name } = option;
          return (
            <option id={id} key={index} value={value} disabled={!value}>
              {name}
            </option>
          );
        })}
      </select>
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputSelectRequired({
  id,
  options,
  value,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  if (!Array.isArray(options) || options.length < 1) return null;

  return (
    <div className="input">
      <select
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
        {...otherProps}
        required
      >
        {options.map((option, index) => {
          const { value, name } = option;

          return (
            <option id={id} key={index} value={value}>
              {name}
            </option>
          );
        })}
      </select>
      <HiOutlineExclamationCircle className="inputRequiredIcon" />

      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export const CustomCheckbox = ({ checked, onChange }) => {
  return (
    <label className="customCheckbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={`checkbox ${checked ? "checked" : ""}`}></span>
    </label>
  );
};
