import followRequestsTypes from "./followRequests.types";
import followRequestTypes from "./followRequests.types";

const INITIAL_STATE = {
  followRequests: [],
  followRequest: {},
};

const followRequestsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case followRequestTypes.SET_FOLLOW_REQUESTS:
      return {
        ...state,
        followRequests: action.payload,
      };
    case followRequestsTypes.SET_FOLLOW_REQUEST:
      return {
        ...state,
        followRequest: action.payload,
      };
    default:
      return state;
  }
};

export default followRequestsReducer;
