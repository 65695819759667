import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import Tesseract from "tesseract.js";
import { ButtonPrimary } from "./formElements/Buttons";
import { HiOutlineRefresh } from "react-icons/hi";
import { PiLightningBold, PiLightningSlashBold } from "react-icons/pi";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export const BusinessMenuScanner = () => {
  const [scannedText, setScannedText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const webcamRef = useRef(null);
  const [torchOn, setTorchOn] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);

  const captureAndScanImage = async () => {
    setIsProcessing(true);
    const imageSrc = webcamRef.current.getScreenshot();

    Tesseract.recognize(imageSrc, "eng", {
      logger: (m) => console.log(m),
    })
      .then(({ data: { text } }) => {
        setScannedText(text);
        setIsProcessing(false);
      })
      .catch((err) => {
        console.error("OCR Error:", err);
        setIsProcessing(false);
      });
  };

  const videoConstraints = {
    facingMode: facingMode,
  };

  //RESET SCAN OUTPUT
  const resetScannedText = () => {
    setScannedText("");
  };

  //TOGGLE TORCH
  const toggleTorch = async () => {
    if (webcamRef.current) {
      const videoTrack = webcamRef.current.stream.getVideoTracks()[0];

      if (videoTrack && "applyConstraints" in videoTrack) {
        try {
          await videoTrack.applyConstraints({
            advanced: [{ torch: !torchOn }],
          });
          setTorchOn(!torchOn);
        } catch (error) {
          console.error("Error toggling torch:", error);
        }
      } else {
        console.warn("Torch feature not supported on this device.");
      }
    }
  };

  //TOGGLE VIEW
  const toggleFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  };

  return (
    <div className="page">
      {!scannedText ? (
        <>
          <div className="productScanner">
            <div className="videoContainer full">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <button
                aria-label="scannerFlashBtn"
                className="buttonScanner torch"
                onClick={toggleTorch}
              >
                {torchOn ? <PiLightningSlashBold /> : <PiLightningBold />}
              </button>
              <button
                aria-label="scannerToggleBtn"
                className="buttonScanner"
                onClick={toggleFacingMode}
              >
                <HiOutlineRefresh />
              </button>
            </div>
          </div>
          <br />
          <ButtonPrimary
            title={isProcessing ? "Processing..." : "Scan menu"}
            onClick={captureAndScanImage}
            disabled={isProcessing}
          />
        </>
      ) : (
        <div>
          <h3>Scanned text</h3>
          <textarea
            value={scannedText}
            onChange={(e) => setScannedText(e.target.value)}
            rows={10}
            cols={50}
          />

          <ButtonPrimary title="Scan another" onClick={resetScannedText} />
        </div>
      )}
    </div>
  );
};

export default BusinessMenuScanner;
