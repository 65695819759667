import { firestore } from "../../firebase/utils";
import chatsTypes from "./chats.types";

export const handleAddChat = (chat) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("chats")
      .doc()
      .set(chat)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchChats = ({ filterType, startAfterDoc, persistChats = [] }) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("chats")
      .orderBy("createdDate", "asc")
      .limit(pageSize);

    if (filterType) ref = ref.where("chatCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistChats,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteChat = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("chats")
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchChat = (chatID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("chats")
      .doc(chatID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: chatID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const handleUpdateChat = (chatId, newMessage) => {
  return new Promise((resolve, reject) => {
    const chatRef = firestore.collection("chats").doc(chatId);
    chatRef
      .update({
        messages: firestore.FieldValue.arrayUnion(newMessage),
      })
      .then(() => resolve({ id: chatId, newMessage }))
      .catch((err) => reject(err));
  });
};


// export const getRealtimeUsers = (uid) => {
//   return async (dispatch) => {
//     dispatch({ type: `${chatsTypes.GET_REALTIME_USERS}_REQUEST` });

//     const db = firestore();
//     const unsubscribe = db
//       .collection("users")
//       //.where("uid", "!=", uid)
//       .onSnapshot((querySnapshot) => {
//         const users = [];
//         querySnapshot.forEach(function (doc) {
//           if (doc.data().uid !== uid) {
//             users.push(doc.data());
//           }
//         });
//         //console.log(users);

//         dispatch({
//           type: `${chatsTypes.GET_REALTIME_USERS}_SUCCESS`,
//           payload: { users },
//         });
//       });

//     return unsubscribe;
//   };
// };

export const updateMessage = (msgObj) => {
  return async (dispatch) => {
    
     if (!msgObj.user_uid_1 || !msgObj.user_uid_2) {
      console.error("Missing required fields in msgObj", msgObj);
      return;
    }

    firestore.collection("chats")
      .add({
        ...msgObj,
        isView: false,
        createdAt: new Date(),
      })
      .then((data) => {
        console.log(data);
        //success
        // dispatch({
        //     type: userConstants.GET_REALTIME_MESSAGES,
        // })
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getRealtimeConversations = (user) => {
  return async (dispatch) => {
    const db = firestore;
    db.collection("chats")
      // .where("user_uid_1", "in", [user.uid_1, user.uid_2])
      .orderBy("createdAt", "asc")
      .onSnapshot((querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          if (
            (doc.data().user_uid_1 === user.uid_1 &&
              doc.data().user_uid_2 === user.uid_2) ||
            (doc.data().user_uid_1 === user.uid_2 &&
              doc.data().user_uid_2 === user.uid_1)
          ) {
            chats.push(doc.data());
          }
          // if(conversations.length > 0){
          // }else{
          //     dispatch({
          //         type: `${userConstants.GET_REALTIME_MESSAGES}_FAILURE`,
          //         payload: { conversations }
          //     })
          // }
        });
        dispatch({
          type: chatsTypes.GET_REALTIME_MESSAGES,
          payload: { chats },
        });

        console.log(chats);
      });
    //user_uid_1 == 'myid' and user_uid_2 = 'yourId' OR user_uid_1 = 'yourId' and user_uid_2 = 'myId'
  };
};
