import menusTypes from "./menus.types";

export const addMenuStart = (menuData) => ({
  type: menusTypes.ADD_NEW_MENU_START,
  payload: menuData,
});

export const fetchMenusStart = (filters = {}) => ({
  type: menusTypes.FETCH_MENUS_START,
  payload: filters,
});

export const setMenus = (menus) => ({
  type: menusTypes.SET_MENUS,
  payload: menus,
});

export const deleteMenuStart = (menuID) => ({
  type: menusTypes.DELETE_MENU_START,
  payload: menuID,
});

export const fetchMenuStart = (menuID) => ({
  type: menusTypes.FETCH_MENU_START,
  payload: menuID,
});

export const setMenu = (menu) => ({
  type: menusTypes.SET_MENU,
  payload: menu,
});

export const updateMenuStart = (menuData) => ({
  type: menusTypes.UPDATE_MENU_START,
  payload: menuData,
});

export const updateMenuSuccess = (menuData) => ({
  type: menusTypes.UPDATE_MENU_SUCCESS,
  payload: menuData,
});

//REMOVE MENU ITEM
export const removeMenuItemStart = (payload) => ({
  type: menusTypes.REMOVE_MENU_ITEM_START,
  payload: payload,
});

export const removeMenuItemSuccess = (menuData) => ({
  type: menusTypes.REMOVE_MENU_ITEM_SUCCESS,
  payload: menuData,
});
