import businessesTypes from "./businesses.types";
import businessTypes from "./businesses.types";

const INITIAL_STATE = {
  businesses: [],
  business: {},
};

const businessesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessesTypes.SET_BUSINESSES:
      return {
        ...state,
        businesses: action.payload,
      };
    case businessTypes.SET_BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case businessesTypes.UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: state.businesses.map((business) =>
          business.documentID === action.payload.documentID
            ? action.payload
            : business
        ),
      };
    default:
      return state;
  }
};

export default businessesReducer;
