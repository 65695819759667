const commentsTypes = {
  ADD_NEW_COMMENT_START: "ADD_NEW_COMMENT_START",
  FETCH_COMMENTS_START: "FETCH_COMMENTS_START",
  SET_COMMENTS: "SET_COMMENTS",
  DELETE_COMMENT_START: "DELETE_COMMENT_START",
  FETCH_COMMENT_START: "FETCH_COMMENT_START",
  SET_COMMENT: "SET_COMMENT",
};

export default commentsTypes;
