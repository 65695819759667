import chatsTypes from "./chats.types";

export const addChatStart = (chatData) => ({
  type: chatsTypes.ADD_NEW_CHAT_START,
  payload: chatData,
});

export const fetchChatsStart = (filters = {}) => ({
  type: chatsTypes.FETCH_CHATS_START,
  payload: filters,
});

export const setChats = (chats) => ({
  type: chatsTypes.SET_CHATS,
  payload: chats,
});

export const deleteChatStart = (chatID) => ({
  type: chatsTypes.DELETE_CHAT_START,
  payload: chatID,
});

export const fetchChatStart = (chatID) => ({
  type: chatsTypes.FETCH_CHAT_START,
  payload: chatID,
});

export const setChat = (chat) => ({
  type: chatsTypes.SET_CHAT,
  payload: chat,
});

export const updateChatStart = (chatID, updatedData) => ({
  type: chatsTypes.UPDATE_CHAT_START,
  payload: { chatID, updatedData },
});

export const updateChatSuccess = () => ({
  type: chatsTypes.UPDATE_CHAT_SUCCESS,
});

export const updateChatFail = (error) => ({
  type: chatsTypes.UPDATE_CHAT_FAIL,
  payload: error,
});