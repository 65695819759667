import plansTypes from "./plans.types";

export const addPlanStart = (plansData) => ({
  type: plansTypes.ADD_NEW_PLAN_START,
  payload: plansData,
});

export const fetchPlansStart = (filters = {}) => ({
  type: plansTypes.FETCH_PLANS_START,
  payload: filters,
});

export const setPlans = (plans) => ({
  type: plansTypes.SET_PLANS,
  payload: plans,
});

export const deletePlanStart = (planID) => ({
  type: plansTypes.DELETE_PLAN_START,
  payload: planID,
});

export const fetchPlanStart = (planID) => ({
  type: plansTypes.FETCH_PLAN_START,
  payload: planID,
});

export const setPlan = (plan) => ({
  type: plansTypes.SET_PLAN,
  payload: plan,
});