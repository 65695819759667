import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import { setAdvices, setAdvice, fetchAdvicesStart } from "./advices.actions";
import {
  handleAddAdvice,
  handleFetchAdvices,
  handleFetchAdvice,
  handleDeleteAdvice,
} from "./advices.helpers";
import advicesTypes from "./advices.types";

export function* addAdvice({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddAdvice({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchAdvicesStart());
  } catch (err) {}
}

export function* onAddAdviceStart() {
  yield takeLatest(advicesTypes.ADD_NEW_ADVICE_START, addAdvice);
}

export function* fetchAdvices({ payload }) {
  try {
    const advices = yield handleFetchAdvices(payload);
    yield put(setAdvices(advices));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchAdvicesStart() {
  yield takeLatest(advicesTypes.FETCH_ADVICES_START, fetchAdvices);
}

export function* fetchAdvice({ payload }) {
  try {
    const advice = yield handleFetchAdvice(payload);
    yield put(setAdvice(advice));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchAdviceStart() {
  yield takeLatest(advicesTypes.FETCH_ADVICE_START, fetchAdvice);
}

export function* onDeleteAdviceStart() {
  yield takeLatest(advicesTypes.DELETE_ADVICE_START, deleteAdvice);
}

export function* deleteAdvice({ payload }) {
  try {
    yield handleDeleteAdvice(payload);
    yield put(fetchAdvicesStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* advicesSagas() {
  yield all([
    call(onAddAdviceStart),
    call(onFetchAdvicesStart),
    call(onDeleteAdviceStart),
    call(onFetchAdviceStart),
  ]);
}
