import notificationsTypes from "./notifications.types";
import notificationTypes from "./notifications.types";

const INITIAL_STATE = {
  notifications: [],
  notification: {},
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case notificationTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case notificationsTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
