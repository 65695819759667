import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import { setMenus, setMenu, fetchMenusStart, removeMenuItemSuccess } from "./menus.actions";
import {
  handleAddMenu,
  handleFetchMenus,
  handleFetchMenu,
  handleDeleteMenu,
  handleUpdateMenu,
  handleRemoveMenuItem,
} from "./menus.helpers";
import menusTypes from "./menus.types";

export function* addMenu({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddMenu({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchMenusStart());
  } catch (err) {}
}

export function* onAddMenuStart() {
  yield takeLatest(menusTypes.ADD_NEW_MENU_START, addMenu);
}

export function* fetchMenus({ payload }) {
  try {
    const menus = yield handleFetchMenus(payload);
    yield put(setMenus(menus));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchMenusStart() {
  yield takeLatest(menusTypes.FETCH_MENUS_START, fetchMenus);
}

export function* fetchMenu({ payload }) {
  try {
    const menu = yield handleFetchMenu(payload);
    yield put(setMenu(menu));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchMenuStart() {
  yield takeLatest(menusTypes.FETCH_MENU_START, fetchMenu);
}

export function* deleteMenu({ payload }) {
  try {
    yield handleDeleteMenu(payload);
    yield put(fetchMenusStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteMenuStart() {
  yield takeLatest(menusTypes.DELETE_MENU_START, deleteMenu);
}


export function* updateMenu({ payload }) {
  try {
    const { documentID, ...updatedMenu } = payload;
    yield handleUpdateMenu(documentID, updatedMenu);
    yield put(fetchMenusStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* onUpdateMenuStart() {
  yield takeLatest(menusTypes.UPDATE_MENU_START, updateMenu);
}

export function* removeMenuItem({ payload }) {
  try {
    const { documentID, mealType, postID } = payload;
    // Call the helper function to remove the item from the menu in the database
    yield call(handleRemoveMenuItem, documentID, mealType, postID);

    // Fetch the updated menu to check if all meal types are empty
    const updatedMenu = yield call(handleFetchMenu, documentID);

    // Check if all mealTypes are empty
    const isEmptyMenu = ["breakfast", "lunch", "dinner", "snack", "baby", "pet"] // Add other meal types if any
      .every((meal) => !updatedMenu[meal] || updatedMenu[meal].length === 0);

    if (isEmptyMenu) {
      // If all mealTypes are empty, delete the menu
      yield call(handleDeleteMenu, documentID);
    yield put(fetchMenusStart());

    } else {
      // If not empty, update the state with the new menu
      yield put(removeMenuItemSuccess(updatedMenu));
    yield put(fetchMenusStart());

    }
  } catch (err) {
    console.log(err);
  }
}


export function* onRemoveMenuItemStart() {
  yield takeLatest(menusTypes.REMOVE_MENU_ITEM_START, removeMenuItem);
}

export default function* menusSagas() {
  yield all([
    call(onAddMenuStart),
    call(onFetchMenusStart),
    call(onDeleteMenuStart),
    call(onFetchMenuStart),
     call(onUpdateMenuStart),
     call(onRemoveMenuItemStart),
  ]);
}
