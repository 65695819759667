import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setComments,
  setComment,
  fetchCommentsStart,
} from "./comments.actions";
import {
  handleAddComment,
  handleFetchComments,
  handleFetchComment,
  handleDeleteComment,
} from "./comments.helpers";
import commentsTypes from "./comments.types";

export function* addComment({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddComment({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchCommentsStart());
  } catch (err) {}
}

export function* onAddCommentStart() {
  yield takeLatest(commentsTypes.ADD_NEW_COMMENT_START, addComment);
}

export function* fetchComments({ payload }) {
  try {
    const comments = yield handleFetchComments(payload);
    yield put(setComments(comments));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchCommentsStart() {
  yield takeLatest(commentsTypes.FETCH_COMMENTS_START, fetchComments);
}

export function* fetchComment({ payload }) {
  try {
    const comment = yield handleFetchComment(payload);
    yield put(setComment(comment));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchCommentStart() {
  yield takeLatest(commentsTypes.FETCH_COMMENT_START, fetchComment);
}

export function* onDeleteCommentStart() {
  yield takeLatest(commentsTypes.DELETE_COMMENT_START, deleteComment);
}

export function* deleteComment({ payload }) {
  try {
    yield handleDeleteComment(payload);
    yield put(fetchCommentsStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* commentsSagas() {
  yield all([
    call(onAddCommentStart),
    call(onFetchCommentsStart),
    call(onDeleteCommentStart),
    call(onFetchCommentStart),
  ]);
}
