// export const existingBusinessCartItem = ({ prevBusinessCartItems, nextBusinessCartItem }) => {
//   return prevBusinessCartItems.find(
//     (businessCartItem) =>
//       businessCartItem.recipeName === nextBusinessCartItem.recipeName
//   );
// };
export const existingBusinessCartItem = ({ prevBusinessCartItems, nextBusinessCartItem }) => {
  if (!Array.isArray(prevBusinessCartItems)) {
    console.error("prevBusinessCartItems is not an array:", prevBusinessCartItems);
    return undefined;
  }

  return prevBusinessCartItems.find(
    businessCartItem => businessCartItem.recipeName === nextBusinessCartItem.recipeName
  );
};


export const handleAddToBusinessCart = ({ prevBusinessCartItems, nextBusinessCartItem }) => {
  const quantityIncrement = 1;
  const businessCartItemExists = existingBusinessCartItem({ prevBusinessCartItems, nextBusinessCartItem });

  if (businessCartItemExists) {
    return (prevBusinessCartItems.map((businessCartItem) =>
        businessCartItem.recipeName === nextBusinessCartItem.recipeName
          ? {
              ...businessCartItem,
              quantity: businessCartItem.quantity + quantityIncrement,
            }
          : businessCartItem
      ) 
    );
  }

  return [
    ...prevBusinessCartItems,
    {
      ...nextBusinessCartItem,
      quantity: quantityIncrement,
    },
  ];
};

export const handleRemoveBusinessCartItem = ({
  prevBusinessCartItems,
  businessCartItemToRemove,
}) => {
  return prevBusinessCartItems.filter(
    (item) => item.recipeName !== businessCartItemToRemove.recipeName
  );
};

// export const handleReduceBusinessCartItem = ({
//   prevBusinessCartItems,
//   businessCartItemToReduce,
// }) => {
//   if (!Array.isArray(prevBusinessCartItems)) {
//     console.error("prevBusinessCartItems is not an array:", prevBusinessCartItems);
//     return [];
//   }

//   const existingCartItem = prevBusinessCartItems.find(
//     item => item.recipeName === businessCartItemToReduce.recipeName
//   );
//   // const existingBusinessCartItem = prevBusinessCartItems.find(
//   //   (businessCartItem) =>
//   //     businessCartItem.recipeName === businessCartItemToReduce.recipeName
//   // );

//   // if (existingBusinessCartItem.quantity === 1) {
//   //   return prevBusinessCartItems.filter(
//   //     (businessCartItem) =>
//   //       businessCartItem.recipeName !== existingBusinessCartItem.recipeName
//   //   );
//   // }

//   return prevBusinessCartItems.map((businessCartItem) =>
//     businessCartItem.recipeName === existingBusinessCartItem.recipeName
//       ? {
//           ...businessCartItem,
//           quantity: businessCartItem.quantity - 1,
//         }
//       : businessCartItem
//   );
// };


export const handleReduceBusinessCartItem = ({
  prevBusinessCartItems,
  businessCartItemToReduce,
}) => {
  if (!Array.isArray(prevBusinessCartItems)) {
    console.error("prevBusinessCartItems is not an array:", prevBusinessCartItems);
    return [];
  }
  const existingCartItem = prevBusinessCartItems.find(
    item => item.recipeName === businessCartItemToReduce.recipeName
  );

  if (existingCartItem && existingCartItem.quantity === 1) {
    return prevBusinessCartItems.filter(
      item => item.recipeName !== existingCartItem.recipeName
    );
  }

  return prevBusinessCartItems.map((businessCartItem) =>
    businessCartItem.recipeName === existingCartItem.recipeName
      ? {
          ...businessCartItem,
          quantity: businessCartItem.quantity - 1,
        }
      : businessCartItem
  );

};


