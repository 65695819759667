import ingredientsTypes from "./ingredients.types";

export const addIngredientStart = (ingredientData) => ({
  type: ingredientsTypes.ADD_NEW_INGREDIENT_START,
  payload: ingredientData,
});

export const fetchIngredientsStart = (filters = {}) => ({
  type: ingredientsTypes.FETCH_INGREDIENTS_START,
  payload: filters,
});

export const setIngredients = (ingredients) => ({
  type: ingredientsTypes.SET_INGREDIENTS,
  payload: ingredients,
});

export const deleteIngredientStart = (ingredientID) => ({
  type: ingredientsTypes.DELETE_INGREDIENT_START,
  payload: ingredientID,
});

export const fetchIngredientStart = (ingredientID) => ({
  type: ingredientsTypes.FETCH_INGREDIENT_START,
  payload: ingredientID,
});

export const setIngredient = (ingredient) => ({
  type: ingredientsTypes.SET_INGREDIENT,
  payload: ingredient,
});
