import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

//DATABASE
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { removePostStart, savePostStart } from "../../redux/user/user.actions";
import { fetchUsersStart } from "../../redux/users/users.actions";
import { addNotificationStart } from "../../redux/notifications/notifications.actions";
import {
  addFollowRequestStart,
  deleteFollowRequestStart,
  fetchFollowRequestsStart,
} from "../../redux/followRequests/followRequests.actions";
import {
  addFollowRelationStart,
  deleteFollowRelationStart,
  fetchFollowRelationsStart,
  updateFollowRelationStart,
} from "../../redux/followRelations/followRelations.actions";
import { updateBlockListStart } from "../../redux/user/user.actions";
import {
  addPostStart,
  decreaseSavesStart,
  fetchPostsStart,
  increaseSavesStart,
  updatePostMenuStart,
} from "../../redux/posts/posts.actions";

//COMPONENTS
import {
  ModalBottom,
  ModalBottom2,
  ModalNotify,
} from "../genericComponents/Modals";
import { ToastNotification } from "../genericComponents/Toasts";

//ASSETS
import {
  HiOutlineChevronLeft,
  HiOutlineChevronUp,
  HiOutlineBookmark,
  HiOutlineKey,
} from "react-icons/hi";
import { HiBookmark } from "react-icons/hi2";
import { LiaCrownSolid } from "react-icons/lia";
import { TbCalendarDown } from "react-icons/tb";

//STYLES
import "./Buttons.scss";
import { InputDate, InputSelect } from "./Inputs";

const postsSelector = (state) => state.postsData.posts;
const postSelector = (state) => state.postsData.post;
const currentUserSelector = (state) => state.user.currentUser;
const notificationsSelector = (state) => state.notificationsData.notifications;
const notificationSelector = (state) => state.notificationsData.notification;
const followRelationsSelector = (state) =>
  state.followRelationsData.followRelations;
const followRequestsSelector = (state) =>
  state.followRequestsData.followRequests;
const usersSelector = (state) => state.usersData.users;

const mapState = createSelector(
  [
    postsSelector,
    postSelector,
    currentUserSelector,
    notificationsSelector,
    notificationSelector,
    followRelationsSelector,
    followRequestsSelector,
    usersSelector,
  ],
  (
    posts,
    post,
    currentUser,
    notifications,
    notification,
    followRelations,
    followRequests,
    users
  ) => ({
    posts,
    post,
    currentUser,
    notifications,
    notification,
    followRelations,
    followRequests,
    users,
  })
);

export function ButtonPrimary({ title, ...otherProps }) {
  return (
    <button className="btnPrimary" {...otherProps}>
      <span>{title}</span>
      <span className="loaderBtn" />
    </button>
  );
}
export function ButtonSecondary({ title, ...otherProps }) {
  return (
    <button className="btnSecondary" {...otherProps}>
      {title}
    </button>
  );
}
export function ButtonTertiary({ title, ...otherProps }) {
  return (
    <button className="btnTertiary" {...otherProps}>
      {title}
    </button>
  );
}
export function ButtonBack({ title, toHomepage, ...otherProps }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/explore");
    }
  };

  const handleGoHome = () => {
    if (window.history.length > 1) {
      navigate("/");
    }
  };

  return (
    <button
      type="button"
      name="Go back button"
      aria-label="Go back button"
      className="closeModalBtn"
      {...otherProps}
      onClick={!toHomepage ? handleGoBack : handleGoHome}
    >
      <HiOutlineChevronLeft />
    </button>
  );
}
export function ButtonBackRecipe({ title, ...otherProps }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/explore");
    }
  };

  return (
    <button
      aria-label="closeModalBtn"
      className="closeModalBtn"
      {...otherProps}
      onClick={handleGoBack}
    >
      <HiOutlineChevronLeft />
    </button>
  );
}

export function ButtonScrollToTop() {
  const [visible, setVisible] = useState(false);

  function handleScroll() {
    setVisible(window.pageYOffset > 500);
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className="scrollToTopBtn"
      style={{
        height: visible ? "55px" : "0",
        transform: visible ? "scale(1)" : "scale(0)",
        marginBottom: visible ? "3px" : "0",
      }}
      onClick={scrollToTop}
      aria-label="scrollToTopBtn"
    >
      <HiOutlineChevronUp />
    </button>
  );
}

export const SavePostButton = ({
  postID,
  inModal,
  showSavedToastAdded,
  showSavedToastRemoved,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useSelector(mapState);
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.savedPosts &&
      currentUser.savedPosts.includes(postID)
    ) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }, [currentUser, currentUser.savedPosts, postID]);

  const handleSavePost = async (event) => {
    event.preventDefault();
    dispatch(savePostStart(postID));
    dispatch(increaseSavesStart(postID));
    showSavedToastAdded(true);
    setIsSaved(true);
  };

  const handleRemoveSavedPost = async (event) => {
    event.preventDefault();
    dispatch(removePostStart(postID));
    dispatch(decreaseSavesStart(postID));
    showSavedToastRemoved(true);
    setIsSaved(false);
  };

  return (
    <>
      {isSaved ? (
        <button
          aria-label="Remove saved post button"
          name="Remove saved post button"
          className={
            !inModal && inModal !== true ? "buttonSave saved" : "flexShareBtn"
          }
          onClick={handleRemoveSavedPost}
        >
          {!inModal && inModal !== true ? (
            <span>
              <HiBookmark />
              {t("Saved")}
            </span>
          ) : (
            <>
              <span>
                <HiBookmark />
              </span>
              <small>{t("Saved")}</small>
            </>
          )}
        </button>
      ) : (
        <button
          aria-label="Save post button"
          name="Save post button"
          className={
            !inModal && inModal !== true ? "buttonSave" : "flexShareBtn"
          }
          onClick={handleSavePost}
        >
          {!inModal && inModal !== true ? (
            <span>
              <HiOutlineBookmark />
              {t("Save")}
            </span>
          ) : (
            <>
              <span>
                <HiOutlineBookmark />
              </span>
              <small>{t("Save")}</small>
            </>
          )}
        </button>
      )}
    </>
  );
};

export const AddToMenuButton = ({ postID, showAddedToMenuToast }) => {
  const dispatch = useDispatch();
  const { currentUser, posts } = useSelector(mapState);
  const { data: postsData } = posts;
  const { t } = useTranslation();
  const [date, setDate] = useState("");
  const [mealType, setMealType] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [isViewed, setIsViewed] = useState(0);
  const [menu, setMenu] = useState({
    breakfast: [],
    lunch: [],
    dinner: [],
    snack: [],
    baby: [],
    pet: [],
  });

  // FETCH DATA
  useEffect(() => {
    dispatch(fetchPostsStart());
  }, [dispatch]);

  // MODAL
  const [hideModalNotify, setHideModalNotify] = useState(true);
  const toggleModalNotify = () => setHideModalNotify(!hideModalNotify);
  const configModal = {
    hideModalNotify,
    toggleModalNotify,
  };

  // Show nutrition
  const totalNutritionForDate =
    postsData &&
    postsData.find(
      (menu) => menu.date === date && menu.userID === currentUser.id
    )?.totalNutrition;

  //CALCULATE TOTAL NUTRITION
  const [totalNutrition, setTotalNutrition] = useState("");

  function calculateTotalNutrition(selectedPostIDs) {
    const totalNutrition = {
      protein: 0,
      calories: 0,
      carbohydrates: 0,
      salt: 0,
      sugars: 0,
      fiber: 0,
      fat: 0,
    };

    selectedPostIDs.forEach((postID) => {
      const post = postsData && postsData.find((p) => p.documentID === postID);
      if (post && post.totalNutrition) {
        Object.keys(totalNutrition).forEach((key) => {
          totalNutrition[key] += post.totalNutrition[key] || 0;
        });
      }
    });

    return totalNutrition;
  }

  useEffect(() => {
    const selectedPostIDs = Object.values(menu).flat();
    const newTotalNutrition = calculateTotalNutrition(selectedPostIDs);
    setTotalNutrition(newTotalNutrition);
  }, [menu, postsData]);

  const combinedTotalNutrition = {
    calories:
      (totalNutritionForDate?.calories || 0) + (totalNutrition.calories || 0),
    protein:
      (totalNutritionForDate?.protein || 0) + (totalNutrition.protein || 0),
    fiber: (totalNutritionForDate?.fiber || 0) + (totalNutrition.fiber || 0),
    fat: (totalNutritionForDate?.fat || 0) + (totalNutrition.fat || 0),
    sugars: (totalNutritionForDate?.sugars || 0) + (totalNutrition.sugars || 0),
    carbohydrates:
      (totalNutritionForDate?.carbohydrates || 0) +
      (totalNutrition.carbohydrates || 0),
  };

  const handleAddToMenuAndSubmit = (event) => {
    event.preventDefault();

    if (mealType) {
      const updatedMenu = {
        ...menu,
        [mealType.toLowerCase()]: [...menu[mealType.toLowerCase()], postID],
      };

      submitMenu(updatedMenu);
    } else {
      submitMenu(menu);
    }
  };

  // const submitMenu = (updatedMenu) => {
  //   if (Array.isArray(postsData)) {
  //     const existingMenu = postsData.find(
  //       (menu) => menu.date === date && menu.userID === currentUser.id
  //     );

  //     if (existingMenu) {
  //       const mergedMenu = {
  //         breakfast: [
  //           ...new Set([...existingMenu.breakfast, ...updatedMenu.breakfast]),
  //         ],
  //         lunch: [...new Set([...existingMenu.lunch, ...updatedMenu.lunch])],
  //         dinner: [...new Set([...existingMenu.dinner, ...updatedMenu.dinner])],
  //         snack: [...new Set([...existingMenu.snack, ...updatedMenu.snack])],
  //         baby: [...new Set([...existingMenu.baby, ...updatedMenu.baby])],
  //         pet: [...new Set([...existingMenu.pet, ...updatedMenu.pet])],
  //       };

  //       dispatch(
  //         updatePostMenuStart({
  //           documentID: existingMenu.documentID,
  //           ...mergedMenu,
  //           date,
  //           privacy,
  //           totalNutrition: combinedTotalNutrition,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         addPostStart({
  //           ...updatedMenu,
  //           date,
  //           isViewed,
  //           privacy,
  //           totalNutrition,
  //           documentType: "menu",
  //         })
  //       );
  //     }
  //     toggleModalNotify();
  //     showAddedToMenuToast();
  //   }
  // };

  const submitMenu = (updatedMenu) => {
    if (Array.isArray(postsData)) {
      const existingMenu = postsData.find(
        (menu) => menu.date === date && menu.userID === currentUser.id
      );

      let mergedTotalNutrition = { ...totalNutrition };

      if (existingMenu) {
        const mergedMenu = {
          breakfast: [
            ...new Set([...existingMenu.breakfast, ...updatedMenu.breakfast]),
          ],
          lunch: [...new Set([...existingMenu.lunch, ...updatedMenu.lunch])],
          dinner: [...new Set([...existingMenu.dinner, ...updatedMenu.dinner])],
          snack: [...new Set([...existingMenu.snack, ...updatedMenu.snack])],
          baby: [...new Set([...existingMenu.baby, ...updatedMenu.baby])],
          pet: [...new Set([...existingMenu.pet, ...updatedMenu.pet])],
        };

        const combinedPostIDs = [
          ...new Set([
            ...Object.values(mergedMenu).flat(),
            ...Object.values(updatedMenu).flat(),
          ]),
        ];

        mergedTotalNutrition = calculateTotalNutrition(combinedPostIDs);

        dispatch(
          updatePostMenuStart({
            documentID: existingMenu.documentID,
            ...mergedMenu,
            date,
            privacy,
            totalNutrition: mergedTotalNutrition,
          })
        );
      } else {
        mergedTotalNutrition = calculateTotalNutrition(
          Object.values(updatedMenu).flat()
        );

        dispatch(
          addPostStart({
            ...updatedMenu,
            date,
            isViewed,
            privacy,
            totalNutrition: mergedTotalNutrition,
            documentType: "menu",
          })
        );
      }

      toggleModalNotify();
      showAddedToMenuToast();
    }
  };

  return (
    <>
      <button
        aria-label="cardAddToMenuBtn"
        className="buttonShare"
        onClick={() => toggleModalNotify()}
      >
        <span>
          <TbCalendarDown />
          {t("Add")}
        </span>
      </button>
      <ModalNotify {...configModal}>
        <div className="modalCenter">
          <h3>{t("Adding recipe to menu planner")}</h3>
          <form onSubmit={handleAddToMenuAndSubmit}>
            <InputDate
              label={t("Choose date")}
              type="date"
              value={date}
              handleChange={(e) => setDate(e.target.value)}
              required
            />
            <InputSelect
              label={t("Choose type")}
              value={mealType || ""}
              handleChange={(e) => setMealType(e.target.value)}
              required
              options={[
                { value: "", name: t("Select type") },
                { value: "Breakfast", name: t("Breakfast") },
                { value: "Lunch", name: t("Lunch") },
                { value: "Dinner", name: t("Dinner") },
                { value: "Snack", name: t("Snack") },
                { value: "Baby", name: t("Baby") },
                { value: "Pet", name: t("Pet") },
              ]}
            />
            <div className="flexButton">
              <button onClick={toggleModalNotify}>{t("Cancel")}</button>
              <button type="submit" disabled={!date || !mealType}>
                {t("Add")}
              </button>
            </div>
          </form>
        </div>
      </ModalNotify>
    </>
  );
};

export function BlockButton({ targetID, targetUser }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentUser, followRelations, followRequests } =
    useSelector(mapState);
  const { data: followRequestsData } = followRequests;
  const { data: followRelationsData } = followRelations;

  //FETCH DATA
  useEffect(() => {
    dispatch(fetchUsersStart());
    dispatch(fetchFollowRelationsStart());
    dispatch(fetchFollowRequestsStart());
  }, [dispatch]);

  const [isBlocker, setIsBlocker] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  // const [setIsBlocked] = useState(false);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.blocked &&
      currentUser.blocked.includes(targetID)
    ) {
      setIsBlocker(true);
    } else {
      setIsBlocker(false);
    }

    if (
      targetUser &&
      currentUser &&
      targetUser.blocked &&
      targetUser.blocked.includes(currentUser.id)
    ) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
    }
    // }, [currentUser, targetID, targetUser, setIsBlocked]);
  }, [currentUser, targetID]);

  const handleBlock = () => {
    if (currentUser && targetID) {
      dispatch(updateBlockListStart(currentUser.id, targetID));

      const relation1 =
        followRelationsData &&
        followRelationsData.find(
          (relation) =>
            relation.followerID === currentUser.id &&
            relation.followingID === targetID
        );

      const relation2 =
        followRelationsData &&
        followRelationsData.find(
          (relation) =>
            relation.followingID === currentUser.id &&
            relation.followerID === targetID
        );
      const request1 =
        followRequestsData &&
        followRequestsData.find(
          (request) =>
            request.followerID === currentUser.id &&
            request.followingID === targetID
        );

      const request2 =
        followRequestsData &&
        followRequestsData.find(
          (request) =>
            request.followingID === currentUser.id &&
            request.followerID === targetID
        );
      if (relation1) {
        dispatch(deleteFollowRelationStart(relation1.documentID));
      }
      if (relation2) {
        dispatch(deleteFollowRelationStart(relation2.documentID));
      }
      if (request1) {
        dispatch(deleteFollowRequestStart(request1.documentID));
      }
      if (request2) {
        dispatch(deleteFollowRequestStart(request2.documentID));
      }

      setIsBlocked(true);
    }
  };

  const handleUnblock = () => {
    if (currentUser && targetID) {
      dispatch(updateBlockListStart(currentUser.id, targetID));
      setIsBlocked(false);
    }
  };

  return (
    <>
      {!isBlocker ? (
        <button onClick={handleBlock} className="userActionBtn unfollowBtn">
          {t("Block")}
        </button>
      ) : (
        <button onClick={handleUnblock} className="userActionBtn unblockBtn">
          {t("Unblock")}
        </button>
      )}
    </>
  );
}

export const LongPressWrapper = ({
  children,
  onLongPress,
  longPressTime = 500,
}) => {
  const [pressing, setPressing] = useState(false);
  const timeoutIdRef = useRef();

  useEffect(() => {
    if (pressing) {
      timeoutIdRef.current = setTimeout(() => {
        onLongPress?.();
      }, longPressTime);
    } else {
      clearTimeout(timeoutIdRef.current);
    }

    // Cleanup on unmount
    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [pressing, onLongPress, longPressTime]);

  const startPress = () => {
    setPressing(true);
  };

  const stopPress = () => {
    setPressing(false);
  };

  return (
    <div
      onMouseDown={startPress}
      onMouseUp={stopPress}
      onMouseLeave={stopPress}
      onTouchStart={startPress}
      onTouchEnd={stopPress}
    >
      {children}
    </div>
  );
};

export const FollowButton = ({ currentUser, profileUser, targetID }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { followRelations, followRequests } = useSelector(mapState);
  const { data: followRelationsData } = followRelations;
  const { data: followRequestsData } = followRequests;
  const [followRelationID, setFollowRelationID] = useState(null);

  // const [buttonState, setButtonState] = useState("Follow");

  //FETCH DATA
  useEffect(() => {
    dispatch(fetchUsersStart());
    dispatch(fetchFollowRelationsStart());
    dispatch(fetchFollowRequestsStart());
  }, [dispatch]);

  //MODAL
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const [hideModalBottom2, setHideModalBottom2] = useState(true);
  const toggleModalBottom2 = () => setHideModalBottom2(!hideModalBottom2);

  const configModal = {
    hideModalBottom,
    toggleModalBottom,
    hideModalBottom2,
    toggleModalBottom2,
  };

  //SUBSCRIBE
  const isCreator =
    profileUser.userRoles && profileUser.userRoles.includes("creator");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showToastSubscribed, setShowToastSubscribed] = useState(false);

  useEffect(() => {
    let timerId;
    if (showToastSubscribed) {
      timerId = setTimeout(() => {
        setShowToastSubscribed(false);
      }, 3000);
    }
    return () => clearTimeout(timerId);
  }, [showToastSubscribed]);

  const handleSubscribe = (e) => {
    e.preventDefault();
    setIsSubscribed(true);
    dispatch(
      updateFollowRelationStart({
        documentID: followRelationID,
        isSubscribed: true,
      })
    );
    toggleModalBottom();
    setShowToastSubscribed(true);
  };
  let subscribeButtonLabel = isSubscribed ? "Subscribed" : "Subscribe";

  //CHECK RELATION

  useEffect(() => {
    let isCurrentlySubscribed = false;
    let relationId = null;

    if (currentUser && targetID && followRelationsData) {
      followRelationsData.forEach((rel) => {
        if (rel.followerID === currentUser.id && rel.followingID === targetID) {
          relationId = rel.documentID;
          isCurrentlySubscribed = rel.isSubscribed;
        }
      });
    }

    setFollowRelationID(relationId);
    setIsSubscribed(isCurrentlySubscribed);
  }, [currentUser, targetID, followRelationsData]);

  useEffect(() => {
    if (currentUser && targetID) {
      followRelationsData &&
        followRelationsData.forEach((rel) => {
          if (
            rel.followerID === currentUser.id &&
            rel.followingID === targetID
          ) {
            // setButtonState("following");
            setFollowRelationID(rel.documentID);
          } else if (
            followRequestsData &&
            followRequestsData.some(
              (req) =>
                req.followerID === currentUser.id &&
                req.followingID === targetID
            )
          ) {
            // setButtonState("Pending");
          } else {
            // setButtonState("Follow");
          }
        });
    }
  }, [currentUser, targetID, followRelationsData, followRequestsData]);

  const isFollowing =
    currentUser &&
    followRelationsData &&
    followRelationsData.some(
      (rel) => rel.followerID === currentUser.id && rel.followingID === targetID
    );

  const isRequestPending =
    followRequestsData &&
    followRequestsData.some(
      (req) => req.followerID === currentUser.id && req.followingID === targetID
    );

  const handleFollow = () => {
    if (!isFollowing && !isRequestPending) {
      if (profileUser.privacy === true) {
        dispatch(
          addFollowRequestStart({
            followerID: currentUser.id,
            followingID: targetID,
            isViewed: false,
          })
        );
      } else {
        dispatch(
          addFollowRelationStart({
            followerID: currentUser.id,
            followingID: targetID,
            isSubscribed: false,
          })
        );
        dispatch(
          addNotificationStart({
            receiverID: targetID,
            senderID: currentUser.id,
            text: "follows you",
            isViewed: false,
          })
        );
      }
    } else if (isFollowing) {
      dispatch(deleteFollowRelationStart(followRelationID));
    }
  };

  let buttonLabel = isFollowing
    ? "following"
    : isRequestPending
    ? "Pending"
    : "Follow";
  let buttonDisabled = isRequestPending;

  const [isBlocker, setIsBlocker] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.blocked &&
      currentUser.blocked.includes(targetID)
    ) {
      setIsBlocker(true);
    } else {
      setIsBlocker(false);
    }

    if (
      profileUser &&
      currentUser &&
      profileUser.blocked &&
      profileUser.blocked.includes(currentUser.id)
    ) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
    }
  }, [currentUser, targetID, profileUser]);

  let buttonContent;

  if (!isBlocker) {
    if (isFollowing) {
      buttonContent = isCreator ? (
        <>
          {isSubscribed ? (
            <>
              <button
                className="userActionBtn subscribedBtn"
                onClick={() => toggleModalBottom2()}
                // disabled={buttonDisabled}
              >
                {t(subscribeButtonLabel)}
              </button>
              <ModalBottom2 {...configModal}>
                <h3>Subscribed</h3>
                <p>
                  {t("You already support")} {profileUser.displayName}{" "}
                  {t("for exclusive content")}.
                </p>
                <ButtonSecondary
                  title={t("Cancel subscription")}
                  onClick={() => toggleModalBottom2()}
                />
                <br />
                <br />
              </ModalBottom2>
            </>
          ) : (
            <>
              <button
                className="userActionBtn subscribeBtn"
                onClick={() => toggleModalBottom()}
                // disabled={buttonDisabled}
              >
                {t(subscribeButtonLabel)}
              </button>
              <ModalBottom {...configModal}>
                <br />
                <div className="subscriptionHeader">
                  <LiaCrownSolid size={40} color="#FF6900" />
                  <img src={profileUser.photoURL} alt={profileUser.username} />
                </div>
                <h3>
                  {profileUser.username} {t("subscription")}
                  <br />
                  <small>RON 19.99 monthly - {t("Cancel anytime")}</small>
                </h3>
                <h4 className="flexHeader">You'll get access to:</h4>
                <p className="textFlexLeft">
                  <LiaCrownSolid />{" "}
                  <small>
                    <strong>Subscriber badge</strong>
                    <br />
                    {t(
                      "Get a special badge next to your username whenever you direct message or comment on"
                    )}{" "}
                    {profileUser.username}'s' {t("recipe, menus or articles")}.
                  </small>
                </p>
                <p className="textFlexLeft">
                  <HiOutlineKey />{" "}
                  <small>
                    <strong>Exclusive content</strong>
                    <br />
                    {t("Enjoy")} {profileUser.username}'s{" "}
                    {t(
                      "recipes, menus and articles that are only visible for subscribers"
                    )}
                    .
                  </small>
                </p>
                <br />
                <button
                  className="userActionBtn subscribeBtn"
                  onClick={handleSubscribe}
                >
                  {t("Subscribe")}
                </button>
                <br />
                <br />
                <small className="smallText">
                  By subscribing, you agree to the{" "}
                  <Link to="/subscription-terms">Subscription terms</Link>.
                </small>
                <br />
                <br />
              </ModalBottom>
            </>
          )}
        </>
      ) : (
        <button
          className="userActionBtn unfollowBtn"
          onClick={handleFollow}
          disabled={buttonDisabled}
        >
          {t("following")}
        </button>
      );
    } else {
      buttonContent = (
        <button
          className={`userActionBtn ${
            (buttonLabel === "Pending" && "pendingBtn") ||
            (buttonLabel === "Follow" && "followBtn")
          }`}
          onClick={handleFollow}
          disabled={buttonDisabled}
        >
          {t(`${buttonLabel}`)}
        </button>
      );
    }
  } else {
    // BLOCK BUTTON
    buttonContent = (
      <BlockButton targetUser={profileUser} targetID={targetID} />
    );
  }

  if (!currentUser || !targetID || isBlocked || currentUser.id === targetID) {
    return null;
  }

  if (!currentUser && !targetID) return null;
  if (currentUser.id === targetID) return null;
  if (isBlocked) return null;
  return (
    <>
      {buttonContent}
      {showToastSubscribed ? (
        <ToastNotification content={t("Subscribed succesfully")} />
      ) : null}
    </>
  );
};

export const ButtonLoadMore = ({ onLoadMoreEvt = () => {} }) => {
  return <button onClick={() => onLoadMoreEvt()}>Load More</button>;
};
