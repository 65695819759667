import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//DATABASE
import { dataIngredients } from "../data/dataIngredients";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersStart } from "../redux/users/users.actions";
import { fetchPostsStart } from "../redux/posts/posts.actions";
import { createSelector } from "reselect";
//COMPONENETS
import { Card } from "../components/genericComponents/Cards";
import SearchFilter from "../components/SearchFilter";

const currentUserSelector = (state) => state.user.currentUser;
const usersSelector = (state) => state.usersData.users;
const postsSelector = (state) => state.postsData.posts;

const mapState = createSelector(
  [currentUserSelector, usersSelector, postsSelector],
  (currentUser, users, posts) => ({
    currentUser,
    users,
    posts,
  })
);

export function PostsResults({
  mainType,
  mainTypeNull,
  hasFilter,
  hasScanner,
  searchType,
  myDocuments,
  savedDocuments,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentUser, posts } = useSelector(mapState);

  const { data: postsData = [] } = posts;

  const allIngredients = dataIngredients;
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  useEffect(() => {
    dispatch(fetchUsersStart());
    dispatch(fetchPostsStart());
  }, [dispatch]);

  //SEARCH FILTER
  const handleSearch = (searchFilter) => {
    setSearchFilter(searchFilter);
  };

  //FILTER BY INGREDIENTS
  const handleIngredientChange = (event) => {
    const { value, checked } = event.target;
    const ingredient = allIngredients.find((ingr) => ingr.id === value);
    if (!ingredient) return;

    setSelectedIngredients(
      checked
        ? [...selectedIngredients, ingredient.name]
        : selectedIngredients &&
            selectedIngredients.filter((name) => name !== ingredient.name)
    );
  };

  const getFilteredData = () => {
    let filteredData = postsData;

    if (savedDocuments) {
      filteredData = filteredData.reverse();
    }

    if (searchFilter) {
      filteredData = filteredData.filter((post) =>
        post.title.toLowerCase().includes(searchFilter.toLowerCase())
      );
    }

    return (
      filteredData &&
      filteredData.filter((post) => {
        return (
          (mainTypeNull || post.documentType === mainType) &&
          (!myDocuments || post.userID === currentUser.id) &&
          (!savedDocuments ||
            (currentUser.savedPosts &&
              currentUser.savedPosts.includes(post.documentID))) &&
          (selectedIngredients.length === 0 ||
            (post.selectedIngredients &&
              selectedIngredients.every((selected) =>
                post.selectedIngredients.some((ingredient) =>
                  ingredient.name.toLowerCase().includes(selected.toLowerCase())
                )
              )))
        );
      })
    );
  };

  const filteredData = getFilteredData();
  let message = "";
  let image = "";
  switch (mainType) {
    case "recipe":
      image = "/assets/icons/document.webp";
      message = t("Recipes not found");
      break;
    case "menu":
      image = "/assets/icons/document.webp";
      message = t("Menus not found");
      break;
    case "blog":
      image = "/assets/icons/document.webp";
      message = t("Articles not found");
      break;
    default:
      image = "/assets/icons/document.webp";
      message = t("Items not found");
  }

  if (!postsData || postsData.length < 1) {
    return (
      <div>
        <SearchFilter
          allIngredients={allIngredients}
          handleIngredientChange={handleIngredientChange}
          onSearch={handleSearch}
        />
        <img
          src="./assets/icons/recipesNotFound.webp"
          alt="Listeat"
          className="headerImage small"
        />
        <h3>{message}</h3>
        <p>{t("Try to remove some filters to find something close")}.</p>
        <br />
        <br />
        <br />
      </div>
    );
  }

  return (
    <div>
      <SearchFilter
        allIngredients={allIngredients}
        handleIngredientChange={handleIngredientChange}
        onSearch={handleSearch}
        hasFilter={hasFilter}
        hasScanner={hasScanner}
        searchType={searchType}
      />
      {filteredData && filteredData.length > 0 ? (
        <table>
          <tbody id="recipeCards">
            {filteredData &&
              filteredData.map((post, index) => {
                if (post.privacy === true && post.userID !== currentUser.id)
                  return null;
                return (
                  <Card key={index} {...post} showMyRecipeButtons={true} />
                );
              })}
          </tbody>
        </table>
      ) : (
        <>
          <img
            src="./assets/icons/recipesNotFound.webp"
            alt="Listeat"
            className="headerImage small"
          />
          <h3>{message}</h3>
          <p>{t("Try to remove some filters to find something close")}.</p>
          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
}
